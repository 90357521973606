import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { apiGetGuestLists, apiPairGuestLists, apiUploadGuestLists } from 'api/guestLists';
import { EventDataField, GuestList, UploadGuestListResponse } from 'types';
import { apiDownloadGuestListsSample, apiGetEventDataFields } from 'api/events';
import { batch } from 'react-redux';
import { setHeaderTitle } from 'redux/interfaceManger';
import { ProgressBar } from 'react-bootstrap';

type Mapping = {
	header: number
	field: string
	newFieldName?: string // name of new field when create new data field
}

const UploadGuest = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const [searchParams, setSearchParams] = useSearchParams();
	const [lists, setLists] = useState<GuestList[]>([]);
	const [eventDataFields, setEventDataFields] = useState<EventDataField[]>([]);
	const [selectedId, setSelectedId] = useState<string | number>('');

	const [fileErrorMsg, setFileErrorMsg] = useState<string>('')

	const [step, setStep] = useState<number>(1);
	const [uploadListResponse, setUploadListResponse] = useState<UploadGuestListResponse | null>(null);
	const [mapping, setMapping] = useState<Mapping[]>([]);
	const [successPairMsg, setSuccessPairMsg] = useState<string>('')
	const [showFakeUploading, setShowFakeUploading] = useState<boolean>(false)

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Upload Guest Lists'))
		})
	}, [])

	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const response = await apiGetGuestLists(activeEvent.id)

				if (response.success) {
					setLists(response.data.lists)

					const listVal = searchParams.get('list');
					
					if (listVal) {
						setSelectedId(listVal)
					} else {
						if (response.data.lists.length && response.data.lists[0].id) {
							setSelectedId(response.data.lists[0].id);
						}
					}
				}


				const fieldResponse = await apiGetEventDataFields(activeEvent.id);
				if (fieldResponse.success) {
					setEventDataFields(fieldResponse.data);
				}
			}
		})()
	}, [activeEvent])


	useEffect(() => {
		if (uploadListResponse) {
			setTimeout(() => {
				if (uploadListResponse.headers.length) {
					const bulkMapping: Mapping[] = [];
					uploadListResponse.headers.forEach((header, i) => {
						eventDataFields.forEach(field => {
							if (header.header.trim().toLowerCase() === field.display_name.trim().toLowerCase()) {
								bulkMapping.push({ header: i, field: field.field })
							}
						})
					})

					if (bulkMapping.length) {
						setMapping([...bulkMapping]);
					}
				}
			}, 500)
		}
	}, [uploadListResponse])

	const downloadSample = async () => {
		if (activeEvent) {
			const response = await apiDownloadGuestListsSample(activeEvent.id);

			if (response.success) {
				window.open(response.data, '_blank');
			}
		}
	}


	const handleUploadFIle = async (event: ChangeEvent<HTMLInputElement>) => {
		if (activeEvent) {
			setFileErrorMsg('');
			if (event.target.files && event.target.files.length) {
				const file = event.target.files[0];
	
				const response = await apiUploadGuestLists(activeEvent.id, selectedId, file);
	
				if (response.success) {
					setUploadListResponse(response.data);
					setStep(2);
				} else {
					setFileErrorMsg(response.message);
				}
			}
		}
	}

	const handleMapping = (header: number, field: string): void => {
		if (field === '') {
			setMapping([...mapping.filter(m => m.header !== header)]);
			return;
		}

		const check = mapping.find(m => m.header === header);

		if (check) {
			setMapping([...mapping.map(m => {
				if (m.header === header) {
					return { header, field }
				}

				return m;
			})]);
		} else {
			setMapping([...mapping, { header, field }]);
		}
	}

	const handleChangeCreateField = (header: number, fieldName: string): void => {
		const check = mapping.find(m => m.header === header);

		if (check) {
			setMapping([...mapping.map(m => {
				if (m.header === header) {
					m.newFieldName = fieldName;
				}

				return m;
			})]);
		}
	}

	const handleProceed = async () => {
		if (activeEvent && selectedId && uploadListResponse) {

			if (mapping.length > 0) {
				let filterMapping = mapping.filter(m => m.field !== '' && m.field !== 'IGNORE_COLUMN');
				let validate = filterMapping.find(m => m.field === 'CREATE_FIELD' && (!m.newFieldName || m.newFieldName === ''))
				if (validate) {
					alert('You must create a new data field name')
					return;
				}
				const response = await apiPairGuestLists(activeEvent.id, selectedId, uploadListResponse.file_name, filterMapping)
	
				if (response.success) {
					setSuccessPairMsg(response.message)
					setStep(3);
					setShowFakeUploading(true);
					setTimeout(() => {
						setShowFakeUploading(false);
					}, 2000)
				} else {
					alert(response.message)
				}
			} else {
				alert('You must pair at least one guest field with your uploaded list')
			}
		}
	}

	const handleBack = () => {
		setUploadListResponse(null);
		setStep(1);
		setMapping([]);
	}

	const handleUploadAnother = () => {
		setUploadListResponse(null);
		setStep(1);
		setMapping([]);
	}

	const redirectToGuestList = () => {
		navigate('/guest-list')
	}

	if (!activeEvent) {
		return <Navigate to="/" />
	}

	return (
		<>
			<div className="uploadGuest">
				<div className="stepsBtn px-3 mb-2">
					<Row>
						<Col lg="4" className='p-1'>
							<Button variant="" className={`btn-uploadGuest ${(step === 1) ? 'active' : ''}`}>
								<span className="label">Select Excel</span>
								<i className="ico">
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.85 8L6.95 10.1L11.15 5.9M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke={(step === 1) ? '#333333' : '#CFC8CF'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</i>
							</Button>
						</Col>

						<Col lg="4" className='p-1'>
							<Button variant="" className={`btn-uploadGuest ${(step === 2) ? 'active' : ''}`}>
								<span className="label">Pair Guest Lists</span>
								<i className="ico">
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.85 8L6.95 10.1L11.15 5.9M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke={(step === 2) ? '#333333' : '#CFC8CF'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</i>
							</Button>
						</Col>

						<Col lg="4" className='p-1'>
							<Button variant="" className={`btn-uploadGuest ${(step === 3) ? 'active' : ''}`}>
								<span className="label">Complete Upload</span>
								<i className="ico">
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.85 8L6.95 10.1L11.15 5.9M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke={(step === 3) ? '#333333' : '#CFC8CF'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</i>
							</Button>
						</Col>
					</Row>
				</div>

				<div className={`selectFile `}>
					<div className={`topBar `} >
						{step === 1 && (
						<div className={`selectList`}>
							<Row>
								<Col lg="4">
									<div className="selectListDD">
										<span className="label">
											Select List
										</span>

										<Form.Select size="lg" value={selectedId} onChange={e => setSelectedId(e.target.value)}>
											{lists.map((list, index) => {
												return <option key={index} value={(list.id) ? list.id : ''}>{list.list_name}</option>
											})}
										</Form.Select>
									</div>
								</Col>

								<Col lg="8">
									<div className="downloadTemplate">
										<Button variant="" type='button' className="btn-theme-primary" onClick={downloadSample}>Download Excel Template</Button>
									</div>
								</Col>
							</Row>
						</div>
						)}

						{step === 2 && (
						<div className={`pairHeader`}>
							<h3>Pair Header Columns with Guest Fields</h3>
						</div>
						)}
					</div>

					{step === 1 && (
					<div className={`dragNdrop`}>
						<Form.Control type="file" className="uploadFile" accept='.xls,.xlsx,.csv' onChange={handleUploadFIle} />

						<div className="browseTxt">
							<Row>
								<Col lg="3"></Col>

								<Col lg="6">
									<div className="dndLabels">
										<figure>
											<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M32 39.3244C37.978 35.8663 42 29.4028 42 22C42 10.9543 33.0457 2 22 2C10.9543 2 2 10.9543 2 22C2 29.4028 6.02199 35.8663 12 39.3244M14 22L22 14M22 14L30 22M22 14V42" stroke="#CFC8CF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
											</svg>
										</figure>

										<h5>Drag and Drop File</h5>

										<h6>or</h6>

										<Button variant="" className="btn-theme-primary">Browse</Button>

										<h6>
											Supported Formats are;<br />
											.xls, .xlsx, .csv
										</h6>

										{fileErrorMsg && (<div className="alert alert-danger">{fileErrorMsg}</div>)}

										<div className="instructions">
											<div className="leftBgTop">
												<svg width="120" height="126" viewBox="0 0 120 126" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path opacity="0.25" d="M60.8374 75.7453L45.9997 52M31.162 28.2546L31.2213 28.2175M105.363 14.9056C125.85 47.6911 115.88 90.8767 83.0941 111.363C50.3086 131.85 7.12299 121.88 -13.3637 89.0943C-33.8503 56.3088 -23.8801 13.1233 8.90536 -7.36339C41.6908 -27.85 84.8764 -17.8799 105.363 14.9056Z" stroke="#CFC8CF" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
												</svg>
											</div>

											<p>Instructions:</p>

											<ul>
												<li>Each row in your Excel sheet represents one guest record.</li>
												<li>
												The header of each column in the first row should be the name of a guest data field, such as First name | Last name | Company | etc.
												</li>
												<li>
												Ensure the Excel sheet is clean without filters, hidden rows or columns, or formulas. Empty rows will be ignored.
												</li>
											</ul>
										</div>
									</div>
								</Col>

								<Col lg="3"></Col>
							</Row>
						</div>
					</div>
					)}

					{step === 2 && (
					<div className={`guestFields`}>
						<div className="actionBtns">
							<div className="btnDv">
								<Button className='btn btn-theme-secondary' onClick={handleBack}>
									<span className="label">Back</span>
								</Button>
								<Button className='btn btn-theme-primary' onClick={handleProceed}>
									<span className="label">Proceed</span>
									<i className="ico">
										<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</i>
								</Button>
							</div>
						</div>
						

						<div className=''>
							<div className="customCol" style={{marginBottom: '5px'}}>
								<div className="detectCol">
									<h3 className='title'>Detected Excel Column</h3>
								</div>

								<div className="arrow" style={{width: 62}}></div>

								<div className="guestFieldCol" style={{minHeight: 'auto'}}>
									<h3 className='title'>Guest Field</h3>
								</div>

								<div className="pairingCol">
									<h3 className='title'>Pairing Status</h3>
								</div>
							</div>
							{uploadListResponse && uploadListResponse.headers.map((header, i) => {
								let currentMapping = mapping.find(m => m.header === i);
								const isPaired = (currentMapping && currentMapping.field !== 'IGNORE_COLUMN');
								const isIgnore = (currentMapping && currentMapping.field === 'IGNORE_COLUMN');

								return (
									<div className="customCol" key={i}>
										<div className="detectCol">
											<Table size='sm'>
												<thead>
													<tr>
														<th colSpan={2} align="center">{header.header}</th>
													</tr>
												</thead>

												<tbody>
													{header.data.map((val, j) => {
														return (
															<tr key={`${i}-${j}`}>
																<td style={{width: '20%'}}>{j + 1}</td>
																<td>{val}</td>
															</tr>
														)
													})}
												</tbody>
											</Table>
										</div>

										<div className="arrow">
											<figure>
												<svg width="62" height="24" viewBox="0 0 62 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M2 10C0.89543 10 0 10.8954 0 12C0 13.1046 0.89543 14 2 14V10ZM62 12L42 0.452994V23.547L62 12ZM2 14H44V10H2V14Z" fill="#CFC8CF" />
												</svg>
											</figure>
										</div>

										<div className={`guestFieldCol ${(isPaired) ? 'isPaired' : ''}`}>
											<div className="colBox">
												<Form.Select aria-label="Default select example" value={(currentMapping) ? currentMapping?.field : ''} onChange={e => handleMapping(i, e.target.value)}>
													<option value="">-select-</option>
													<option value="IGNORE_COLUMN">Ignore Column</option>
													<option value="CREATE_FIELD">Create New Data Field</option>
													<optgroup label='Data Fields'>
													{eventDataFields.map((field, j) => {
														let mappingFields: string[] = mapping.map(m => m.field);
														let isAlreadySelected = (mappingFields.includes(field.field))

														// enable option if field is currently selected
														if (currentMapping && currentMapping.field === field.field) {
															isAlreadySelected = false;
														}
														return (
															<option key={j} value={field.field} disabled={isAlreadySelected}>{field.display_name}</option>
														)
													})}
													</optgroup>
												</Form.Select>

												{(currentMapping && currentMapping.field === 'CREATE_FIELD') ? (
													<Form.Control 
														type="text"
														placeholder='Field Name'
														className='mt-2'
														onChange={e => handleChangeCreateField(i, e.target.value)}
													/>
												) : null}
											</div>
										</div>

										<div className={`pairingCol ${(isPaired) ? 'isPaired' : ''}`}>
											<div className="colBox">
												<div className="paired">
													{(isIgnore) ? (
														<>
														<figure>
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" 
																stroke="#dc3545" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
															</svg>
														</figure>

														<span><strong>Ignored</strong></span>
														</>
													) : (
														<>
														<figure className={(isPaired) ? 'active' : ''}>
															<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" 
																stroke="#cccccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
															</svg>
														</figure>

														<span>{(isPaired) ? <strong>Paired</strong> : 'Not paired'}</span>
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								)
							})}

						</div>

						<div className="actionBtns">
							<div className="btnDv">
								<Button className='btn btn-theme-secondary' onClick={handleBack}>
									<span className="label">Back</span>
								</Button>
								<Button className='btn btn-theme-primary' onClick={handleProceed}>
									<span className="label">Proceed</span>
									<i className="ico">
										<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</i>
								</Button>
							</div>
						</div>
					</div>
					)}

					{step === 3 && (
					<div className={`succesfully`}>
						<Row className="position-relative w-100">
							<Col lg="3"></Col>

							{(showFakeUploading) ? (
								<Col lg="6">
									<h4 className='text-center mb-3'>Uploading...</h4>
									<ProgressBar 
										max={100}
										min={0}
										now={100}
										variant='dark'
										animated={true}
									/>
								</Col>
							) : (
							<Col lg="6">
								<div className="completed">
									<figure>
										<svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M24 42L36 54L60 30M82 42C82 64.0914 64.0914 82 42 82C19.9086 82 2 64.0914 2 42C2 19.9086 19.9086 2 42 2C64.0914 2 82 19.9086 82 42Z" stroke="#38D311" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</figure>

									<h3>Upload Successfully Completed</h3>

									<h6>{successPairMsg}</h6>

									
									<Button variant="" className='btn-theme-primary' onClick={redirectToGuestList}>View Guest Lists</Button>

									<Button variant="" className='btn-theme-text' onClick={handleUploadAnother}>Upload another list of guests</Button>
								</div>
							</Col>
							)}

							<Col lg="3"></Col>
						</Row>
					</div>
					)}
				</div>
			</div>
		</>
	);
};

export default UploadGuest;