import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Event } from 'types';
import { apiCreateEvent } from 'api/events';
import dayjs from 'dayjs';
import { batch } from 'react-redux';
import { TimeZones } from 'constant';
import { InputGroup } from 'react-bootstrap';

type EventCreateProps = {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	onEventCreated: (event: Event) => void;
	setActivatingEvent: (event: Event) => void;
}

const EventCreate = ({ isOpen, setIsOpen, onEventCreated, setActivatingEvent }: EventCreateProps) => {
	const [step, setStep] = useState(1);
	const [guestLists, setGuestLists] = useState<string[]>([]);
	const [eventCreated, setEventCreated] = useState<boolean>(false);
	const [eventData, setEventData] = useState<any>({
		name: '',
		start_data: '',
		time: '',
		timezone: (Intl) ? Intl.DateTimeFormat().resolvedOptions().timeZone : '',
		location: '',
	});
	const [createdEventData, setCreatedEventData] = useState<Event | null>(null);

	useEffect(() => {
		batch(() => {
			setStep(1);
			setGuestLists(['']);
		})
	}, [])

	const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const files = e.target.files
		console.log("files:", files)
		if (files && files.length > 0) {
			setEventData({...eventData, logo: files[0]});
		}
	}

	const handleDecrement = (index: number) => {
		if (guestLists.length <= 1) {
			return;
		}
		setGuestLists([...guestLists.filter((g, i) => i !== index)])
	}
	const handleIncrement = () => {
		setGuestLists([...guestLists, '']);
	}

	const handleCreateEvent = async () => {
		console.log({
			eventData,
			guest_lists: guestLists.filter(g => g !== ''),
		})

		if (eventData.name === '') {
			alert('Event name is required');
			return;
		}
		if (eventData.start_data === '') {
			alert('Event start date is required');
			return;
		}
		if (eventData.time === '') {
			alert('Event time is required');
			return;
		}
		if (eventData.timezone === '') {
			alert('Event timezone is required');
			return;
		}

		let guest_lists: any = {};
		guestLists.forEach((g, i) => {
			if (g !== '') {
				guest_lists[`guest_lists[${i}]`] = g;
			}
		})

		const response = await apiCreateEvent({
			...eventData,
			...guest_lists,
		})

		if (response.success) {
			setEventCreated(true)
			setCreatedEventData(response.data)
			onEventCreated(response.data)
		} else {
			alert(response.message)
		}
	}

	return (
		<>
			<Modal show={isOpen} className="createNewAccountModal" onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>

				{eventCreated ? (
					<>
						<Modal.Header closeButton>
							<Modal.Title>
								<span>Event Created</span>
							</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<div className="text-center">
								<h3 className='mt-3'>Event Created Successfully</h3>

								<p className='mt-4'>You are now in the testing stage and can use<br />Check-in Pax for up to 20 guests.</p>

								<div className='mt-3'>
									<div 
										className={`status-badge testing `}
										style={{ margin: '0 auto' }}
									>
										<span className="dot"></span>
										<span className="label">
											Testing
										</span>
									</div>
								</div>

								<p className='mt-3'>Activate the event whenever you are ready.</p>
							</div>
							
							<div className='mt-4 d-flex' style={{ gap: '15px' }}>
								<Button variant="outline-dark" onClick={() => {
									setIsOpen(false)
									setTimeout(() => {
										if (createdEventData) {
											setActivatingEvent(createdEventData)
										}
									}, 800)
								}} style={{ width: 'auto', flexGrow: 0.2, padding: '0.8rem' }}>
									Activate Now
								</Button>

								<Button variant="dark" onClick={() => setIsOpen(false)} style={{ width: 'auto', flexGrow: 0.8, padding: '0.8rem' }}>
									<div className='d-flex align-items-center justify-content-between'>
										<span>Continue</span>
										<span className="arrow">
											<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</div>
								</Button>
							</div>
						</Modal.Body>
					</>
				) : (
					<>
						<Modal.Header closeButton>
							<Modal.Title>
								<span>Create Event (Step {step} / 2)</span>
							</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							{step === 1 ? (
								<>
									<div className={`step-form1`}>
										<Form.Group className="mb-3" controlId="eventCreateModal">
											<Form.Label>Event name</Form.Label>
											<Form.Control type="text" placeholder="Opening Ceremony" value={eventData?.name} onChange={e => setEventData({...eventData, name: e.target.value})} />
										</Form.Group>

										<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
											<Form.Label>Upload Event Logo</Form.Label>
											<div className="uploadEventFile mb-3">
												<Form.Control type="file" placeholder="name@example.com" onChange={handleFileSelected} />
												<span className="placeholderLabel">{(eventData.logo && eventData.logo !== null) ? eventData.logo.name : <span style={{opacity: 0.3}}>Browse Picture</span>}</span>
												<span className="choose">Browse</span>
											</div>
											<p className="note">Picture should be under 5 MBs. Recommended size: 390 x 390 px.</p>
										</Form.Group>

										<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
											<Form.Label>Event Start Date, Time & Time Zone</Form.Label>
											<InputGroup>
												<Form.Control type="date" min={(dayjs().format('YYYY-MM-DD'))} value={eventData?.start_data} onChange={e => setEventData({...eventData, start_data: e.target.value})} />
												
												<Form.Control type="time" value={eventData?.time} onChange={e => setEventData({...eventData, time: e.target.value})} />
											
												<Form.Select aria-label="Default select example" value={eventData?.timezone} onChange={e => setEventData({...eventData, timezone: e.target.value})}>
													{TimeZones.map(timezone => {
														return (
															<option key={timezone} value={timezone}>{timezone}</option>
														)
													})}
												</Form.Select>
											</InputGroup>
										</Form.Group>

										<Form.Group className="mb-3" controlId="eventCreateModal">
											<Form.Label>Location</Form.Label>
											<Form.Control type="text" placeholder="Enter the event venue" value={eventData?.location} onChange={e => setEventData({...eventData, location: e.target.value})} />
										</Form.Group>
									</div>

									<div className={`actionBtns`}>
										<Button variant="secondary" className={`btn-cancel `} onClick={() => setIsOpen(false)}>
											<span className={`step1`}>Cancel</span>
										</Button>

										<Button variant="primary" className={`btn-steps `} onClick={() => setStep(2)}>
											<span className={`step1 d-flex align-items-center justify-content-between`}>
												<span className="label">Continue</span>
												<span className="arrow">
													<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
													</svg>
												</span>
											</span>
										</Button>
									</div>
								</>
							) : (
								<>
									<div className={`step-form2 mb-5`}>
										<Form.Group className="mb-3" controlId="eventCreateModal">
											<Form.Label>Guest Lists</Form.Label>

											{guestLists.map((guestList, index) => {
												return (
													<div className="inBox vip" key={index}>
														<Form.Control type="text" placeholder="e.g. VIP" value={guestList} autoFocus={true} onChange={e => {
															setGuestLists([...guestLists.map((g, i) => {
																if (i === index) {
																	return e.target.value
																}
																return g;
															})])
														}} />
														<div className="btnGroup">
															<Button className="" onClick={() => handleDecrement(index)} disabled={index >= (guestLists.length - 1)}>
																<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M0.757324 1H9.24261" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
																</svg>
															</Button>

															<Button className="" onClick={handleIncrement} disabled={index < (guestLists.length - 1)}>
																<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M4.99997 0.757359V9.24264M0.757324 5H9.24261" stroke="#8C838C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
																</svg>
															</Button>
														</div>
													</div>
												)
											})}
										</Form.Group>
									</div>
									<div className={`actionBtns mb-3`}>
										<Button variant="secondary" className={`btn-cancel `} onClick={() => setStep(1)}>
											<span className={`step2`}>Back</span>
										</Button>

										<Button variant="primary" className={`btn-steps`} onClick={handleCreateEvent}>
											<span className={`step2 d-flex align-items-center justify-content-between`}>
												<span className="label">Create Event</span>
												<span className="arrow">
													<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
													</svg>
												</span>
											</span>
										</Button>
									</div>

									<p className={`note`}>
										You can add a maximum of 20 Guest Lists at a time.
									</p>
								</>
							)}








						</Modal.Body>
					</>
				)}


			</Modal>
		</>
	)
}


export default EventCreate;