import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { DomainEmails, EmailTemplate, EmailTemplateType } from 'types';
import { ButtonGroup, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { EmailTemplateTypes } from 'constant';
import { batch } from 'react-redux';
import { apiCreateTemplate, apiGetEmailSenders } from 'api/emailTemplate';
import { useNavigate } from 'react-router-dom';


type TemplateCreatePopupProps = {
	eventId: number | string;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	onCreated: (template: EmailTemplate) => void;
}

const TemplateCreatePopup = ({ eventId, isOpen, setIsOpen, onCreated }: TemplateCreatePopupProps) => {
	const navigate = useNavigate();
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [type, setType] = useState<EmailTemplateType>(EmailTemplateTypes.HTML_UPLOAD);
	const [name, setName] = useState<string>('')
	const [senderName, setSenderName] = useState<string>('')
	const [senderEmail, setSenderEmail] = useState<string>('')
	const [senderReplyEmail, setSenderReplyEmail] = useState<string>('')
	const [subject, setSubject] = useState<string>('')
	const [senderEmails, setSenderEmails] = useState<DomainEmails[]>([]);

	useEffect(() => {
		(async () => {
			if (eventId) {
				const response = await apiGetEmailSenders(eventId)
				
				if (response.success) {
					setSenderEmails(response.data)
					if (response.data.length) {
						setSenderEmail(response.data[0].email)
					}
				}
			}
		})();
	}, [])

	useEffect(() => {
		if (senderEmail && senderEmail === 'NEW_DOMAIN') {
			navigate('/domains')
		}
	}, [senderEmail])

	const handleSubmit = async () => {
		const data: EmailTemplate = {
			name,
			type,
			sender_name: senderName,
			sender_email: senderEmail,
			sender_reply_emails: senderReplyEmail,
			subject: subject,
		}
		console.log({data})
		batch(() => {
			setErrMsg('');
			setIsLoading(true);
		})

		const response = await apiCreateTemplate(eventId, data);
		setIsLoading(false);
		if (response.success) {
			onCreated(response.data)
		} else {
			setErrMsg(response.message)
		}
	}

	return (
		<>
			<Modal show={isOpen} className="createNewEmailTemplateModal" size='lg' onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>Create New Template</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{errMsg && <div className="alert alert-danger">{errMsg}</div>}
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Email template name (internal)</Form.Label>
								<Form.Control type="text" placeholder="" value={name} onChange={e => setName(e.target.value)} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>&nbsp;</Form.Label>
								<div>
									<ToggleButtonGroup 
										type="radio" 
										name="template-types" 
										defaultValue={EmailTemplateTypes.HTML_UPLOAD} 
										value={type} 
										onChange={(e) => setType(e)} style={{ display: 'flex' }}
									>
										<ToggleButton id="tbg-radio-1" variant='outline-dark' 
											value={EmailTemplateTypes.HTML_UPLOAD} 
											style={{ height: 50, lineHeight: '35px' }}
										>
											Upload HTML
										</ToggleButton>
										<ToggleButton id="tbg-radio-2" variant='outline-dark' 
											value={EmailTemplateTypes.BUILDER} 
											style={{ height: 50, lineHeight: '35px' }}
											disabled
										>
											Email Builder
										</ToggleButton>
									</ToggleButtonGroup>
								</div>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Sender name</Form.Label>
								<Form.Control type="text" placeholder="" value={senderName} onChange={e => setSenderName(e.target.value)} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Sender email address</Form.Label>
								<Form.Select aria-label="Default select example" value={senderEmail} onChange={e => setSenderEmail(e.target.value)}>
									{senderEmails.map(e => {
										return (
											<option key={e.email} value={e.email}>{e.email} ({(e.is_default === 1) ? 'Default' : 'Your Domain'})</option>
										)
									})}
									<option value={'NEW_DOMAIN'}>ADD NEW EMAIL ADDRESS / DOMAIN</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col></Col>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Reply-to email address</Form.Label>
								<Form.Control type="email" placeholder="" value={senderReplyEmail} onChange={e => setSenderReplyEmail(e.target.value)} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Email subject line</Form.Label>
								<Form.Control type="text" placeholder="" value={subject} onChange={e => setSubject(e.target.value)} />
							</Form.Group>
						</Col>
					</Row>


				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setIsOpen(false)} style={{ width: 'auto', flexGrow: 1, padding: '1rem' }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSubmit} style={{ width: 'auto', flexGrow: 1.8, padding: '1rem' }} disabled={isLoading}>
						<div className='d-flex align-items-center justify-content-between'>
							<span>Create Email Template</span>
							<span className="arrow">
								<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default TemplateCreatePopup;