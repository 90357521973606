import { ResponseObject, Event, EventDataField, EventStatusType, Package } from "types";
import apiService from "services/ApiService";
import cacheService from "services/CacheService";



export const apiGetPackages = async (): Promise<ResponseObject<Package[]>> => {
	return await apiService.get(`/packages`);
}

export const apiGetStripeConfig = async (): Promise<ResponseObject<string>> => {
	return await apiService.get(`/stripe-config`);
}

export const apiChargeEventPayment = async (
	eventId: string | number,
	packageId: number,
	paymentMethodId: string,
	currency: string,
): Promise<ResponseObject<null>> => {
	return await apiService.post(`/charge-event-payment/${eventId}`, {
		package_id: packageId,
		payment_method_id: paymentMethodId,
		currency: currency,
	});
}