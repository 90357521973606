import React from 'react';
import { Routes, Route } from 'react-router-dom';

import UserLayout from 'layout/UserLayout';

import Dashboard from 'views/User/Dashboard';
import GuestLists from 'views/User/GuestLists';
import Guests from 'views/User/AllGuest';
import NotFound from 'views/NotFound';
import AcceptInvitationError from 'views/AcceptInvitationError';

const UserRoutes = (): JSX.Element => {

	return (
		<Routes>
			<Route path="/" element={<UserLayout />}>
				<Route element={<Dashboard />} path="/" />
				<Route element={<Dashboard />} path="/dashboard" />
				<Route element={<GuestLists />} path="/guest/:eventId" />
				<Route element={<Guests />} path="/guests/:eventId" />
				<Route element={<Guests />} path="/guests/:eventId/:listId" />
				<Route element={<AcceptInvitationError />} path="/accept-invitation/:token" />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	)
}

export default React.memo(UserRoutes);