import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { toggleBulkBadgePrintPopup, toggleSelectDevicePopup } from 'redux/interfaceManger';
import { setDeviceName } from 'redux/authSlice';
import { batch } from 'react-redux';
import { apiBulkBadgePrint } from 'api/guests';
import { useParams } from 'react-router-dom';
import { apiGetGuestLists } from 'api/guestLists';
import { ApiParamsBulkBadgePrint, GuestList } from 'types';


const BulkBadgePrintPopup = () => {
	const dispatch = useAppDispatch();
	let { eventId } = useParams();
	console.log('BulkBadgePrintPopup:eventId', eventId)
	const bulkBadgePrintPopup = useAppSelector(state => state.interfaceManger.bulkBadgePrintPopup);
	const [isBulkPrinting, setIsBulkPrinting] = useState<boolean>(false);
	const [lists, setLists] = useState<GuestList[]>([]);
	const [listId, setListId] = useState<number>(0);
	
	useEffect(() => {
		(async () => {
			if (eventId) {
				const response = await apiGetGuestLists(parseInt(eventId))
	
				if (response.success) {
					setLists(response.data.lists)
					if (response.data.lists.length > 0) {
						setListId(response.data.lists[0].id ?? 0)
					}
				}
			}
		})();
	}, [eventId])

	const onHide = () => {
		if (!isBulkPrinting) {
			dispatch(toggleBulkBadgePrintPopup(false));
		}
	}

	const handleBulkBadgePrint = async () => {
		
		if (eventId && listId > 0) {
			const data: ApiParamsBulkBadgePrint = {
				allGuests: 1,
				guestIds: null,
				listId: listId,
			}
	
			setIsBulkPrinting(true)
			const response = await apiBulkBadgePrint(eventId, data)
		
			setIsBulkPrinting(false)
			if (response.success) {
				setTimeout(() => {
					window.open(response.data)
				}, 600)
			} else {
				alert(response.message)
			}
		}
	}

	
	return (
		<>
			<Modal show={bulkBadgePrintPopup} className="printModal" onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Bulk Badge Print</Modal.Title>
				</Modal.Header>
				<Modal.Body className='bg-white'>
					<Form.Group className='mb-3' controlId="formHorizontalEmail">
						<Form.Label sm={4}>Guest List</Form.Label>
						<Form.Select
							value={listId}
							onChange={e => setListId(parseInt(e.target.value))}
						>
							{lists.map((list, i) => {
								return (
									<option key={i} value={list.id?.toString()}>{list.list_name}</option>
								)
							})}
						</Form.Select>
					</Form.Group>

					<div className='text-end'>
						{isBulkPrinting ? 
							<Button type='button' variant='dark' disabled>Generating...</Button> : 
							<Button type='button' variant='dark' onClick={handleBulkBadgePrint}>Generate Badges</Button>}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default BulkBadgePrintPopup;