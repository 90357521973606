import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toast } from 'react-toastify';
import { batch } from 'react-redux';
import { unsetActiveEvent } from 'redux/eventSlice';
import { setHeaderTitle } from 'redux/interfaceManger';
import { Domain, DomainDnsRecords, Event, Team, TeamMember, TeamUpdateUserData } from 'types';
import { apiDeleteTeamMember, apiGetTeam, apiResendInviteTeam, apiUpdateTeamMember } from 'api/team';
import { apiGetEvent } from 'api/events';
import { EventStatus, TeamAccess, TeamRole } from 'constant';
import Select from 'react-select'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, InputGroup, Table } from 'react-bootstrap';
import { apiAuthenticateDomain, apiGetDomains } from 'api/domain';

const DomainConfiguration = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { state }: { state: Domain } = useLocation()
	const [errMsg, setErrMsg] = useState<string>('');
	const [successMsg, setSuccessMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [domain, setDomain] = useState<Domain>(state);
	
	useEffect(() => {
		batch(() => {
			dispatch(unsetActiveEvent())
			dispatch(setHeaderTitle('Domains'))
		})
	}, [])

	useEffect(() => {
		if (state && state.dns_records === null) {
			navigate('/domains')
		}
	}, [state])

	const copyText = (text: string) => {
		navigator.clipboard.writeText(text)
	}

	const handleAuthenticate = async () => {
		if (!domain) {
			return
		}
		batch(() => {
			setIsLoading(true)
			setErrMsg('')
			setSuccessMsg('')
		})

		const response = await apiAuthenticateDomain(domain.id)
		setIsLoading(false)
		if (response.success) {
			setSuccessMsg(response.message)
			setDomain(response.data)
		} else {
			setErrMsg(response.message)
		}
	}

	return (
		<>
		<div className="cp_tabs_contentbx">
			<Link to={`/domains`} className='btn btn-white mb-3'><span className='ms-3 me-3'>Back</span></Link>

			<div className="">
				<h4 style={{
					borderBottom: '1px solid #ccc',
					paddingBottom: '10px'
				}}>Authenticate | <strong>{domain.domain}</strong></h4>
			</div>

			<Alert variant='info' className='mt-3'>Now, go to your <strong>email domain provider</strong> account and add your DNS record(s). Read our detailed instruction on <a href="https://help.brevo.com/hc/en-us/articles/12163873383186-Authenticate-your-domain-with-Brevo-Brevo-code-DKIM-record-DMARC-record#tab-content-91" rel="noreferrer" target='_blank' className='text-dark small'>how to add your DNS record.</a></Alert>


			<div><strong>DNS records for domain authentication</strong></div>
			{errMsg && <div className="alert alert-danger">{errMsg}</div>}
			{successMsg && <div className="alert alert-success">{successMsg}</div>}

			<Table borderless striped id='domain-config-table'>
				
					{(domain.dns_records && Object.keys(domain.dns_records).map((key, i) => {
						if (!domain.dns_records) {
							return (<></>)
						}

						const dns_record = domain.dns_records[key as keyof DomainDnsRecords];

						return (
							<>
							<thead>
							<tr key={`${key}_hd`}>
								<th>
									{(dns_record.status === true) ? (
										<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path 
												fill-rule="evenodd" 
												clip-rule="evenodd" 
												d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" 
												fill="#198754"
											/>
										</svg>
									) : (
										<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path 
												fill-rule="evenodd" 
												clip-rule="evenodd" 
												d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z" 
												fill="#dc3545"
											/>
										</svg>
									)}

									
								</th>
								<th>{(key === 'dkimRecord') ? 'DKIM Record' : (key === 'brevoCode') ? 'Brevo Code' : key.toUpperCase()}</th>
								<th colSpan={3}>
									{(dns_record.hostName === '@') ? (
										<div className='text-muted' style={{fontWeight: 'normal'}}>Some email domain providers do not support '@'. In that case, try entering "{domain.domain}" or leave the field blank while adding DNS record.</div>
									) : null}
								</th>
							</tr>
							</thead>
							<tbody>
							<tr key={`${key}_type`}>
								<td></td>
								<td>Type</td>
								<td style={{width: '40%'}} >
									{dns_record.type}
								</td>
								<td></td>
								<td></td>
							</tr>
							<tr key={`${key}_name`}>
								<td></td>
								<td>Name</td>
								<td style={{width: '40%'}} className='small'>
									<div style={{lineBreak: 'anywhere'}}>
										<code>
										{dns_record.hostName}
										</code>
									</div>
								</td>
								<td>
									<Button variant='link' size='sm'
										className='text-dark' 
										onClick={() => copyText(dns_record.hostName)}
									>Copy</Button>
								</td>
								<td></td>
							</tr>
							<tr key={`${key}_value`}>
								<td></td>
								<td>Value</td>
								<td style={{width: '40%'}} className='small'>
									<div style={{lineBreak: 'anywhere'}}>
										<code>
										{dns_record.value}
										</code>
									</div>
								</td>
								<td>
									<Button variant='link' size='sm'
										className='text-dark' 
										onClick={() => copyText(dns_record.value)}
									>Copy</Button>
								</td>
								<td>
									{(dns_record.status === true) ? (
										<div>
											<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path 
													fill-rule="evenodd" 
													clip-rule="evenodd" 
													d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" 
													fill="#198754"
												/>
											</svg>
											<span>Value matched</span>
										</div>
									) : (
										<div>
											<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path 
													fill-rule="evenodd" 
													clip-rule="evenodd" 
													d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z" 
													fill="#dc3545"
												/>
											</svg>
											<span>Value mismatched</span>
											</div>
									)}
								</td>
							</tr>
							</tbody>
							</>
						)
					}))}
			</Table>

			{(domain.authenticated !== 1 || domain.verified !== 1) ? (
				<div className='mt-4'>
					<Button variant='dark' size='lg' className='ps-5 pe-5' onClick={handleAuthenticate} disabled={isLoading}>Authenticate</Button>
					{` `}
					<Link to={`/domains`} className='btn btn-white btn-lg'>I'll do it later</Link>
				</div>
			) : null}
		</div>

		</>
	)
}


export default DomainConfiguration;