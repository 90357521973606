import React, { useEffect, useState } from 'react';
import { apiChargeEventPayment, apiGetStripeConfig } from 'api/payment';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Event, Package } from "types";
import { batch, useDispatch } from 'react-redux';

import {Stripe, loadStripe} from '@stripe/stripe-js';
import {
	Elements,
} from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm';


type CardFormProps = {
	event: Event
	selectedPackage: Package
	currency: string
	packageAmount: string
	onCancel: () => void
	onSuccess: (paymentResponseData: any) => void
}

const CardForm = ({
	event,
	selectedPackage,
	currency,
	packageAmount,
	onCancel, 
	onSuccess,
}: CardFormProps): JSX.Element => {
	const dispatch = useDispatch();
	const [successMsg, setSuccessMsg] = useState<string>('');
	const [errMsg, setErrMsg] = useState<string>('')
	const [ stripePromise, setStripePromise ] = useState<Promise<Stripe | null>>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async () => {
			const response = await apiGetStripeConfig();
			if (response.success) {
				setStripePromise(loadStripe(response.data))
			}
		})()
	  }, []);


	const submit = async (paymentMethod: any) => {
		console.log(paymentMethod)
		
		batch(() => {
			setErrMsg('');
			setIsLoading(true);
		})
		const response = await apiChargeEventPayment(event.id, selectedPackage.id, paymentMethod.id, currency);
		
		setIsLoading(false);
		if (response.success) {
			onSuccess(response.data);
		} else {
			setErrMsg(response.message);
		}

	}


	if (isLoading) {
		return (
			<>
			<div className="pt-5 text-center pb-5">
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
				<h4>Processing...</h4>
			</div>
			</>
		)
	}

	return (
		<>
		<Row className='mt-4 mb-4'>
			<Col md={2}></Col>
			<Col md={8}>
				{errMsg && <div className="alert alert-danger">{errMsg}</div>}

				{!stripePromise ? (
					<div className="pt-5 text-center">
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				) : null}

				{stripePromise && (
					<Elements stripe={stripePromise} options={{ 
						mode: 'setup',
						// currency: 'usd',
						currency: currency.toLowerCase(),
						setupFutureUsage: 'off_session',
						captureMethod: 'manual',
						paymentMethodCreation: 'manual',
						paymentMethodTypes: ['card'],
						appearance: {
							theme: 'flat'
						}
					}}>
						<CheckoutForm onSubmit={submit} onCancel={onCancel} selectedPackage={selectedPackage} packageAmount={packageAmount} currency={currency} setErrMsg={setErrMsg} />
					</Elements>
				)}
			</Col>
		</Row>

		</>
	)
}

export default CardForm;