import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { ButtonGroup, Form, InputGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { batch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { apiGetGuestsByEvent, apiGuestBadgePrint } from 'api/guests';

type BadgePreviewPopupProps = {
	eventId: number | string;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}


const BadgePreviewPopup = ({ eventId, isOpen, setIsOpen }: BadgePreviewPopupProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [previewGuestId, setPreviewGuestId] = useState<string | number | null>(null);


	const searchGuests = async (inputValue: string) => {
		return new Promise<any[]>(async (resolve) => {
			if (eventId) {
				const response = await apiGetGuestsByEvent(eventId, {
					page: 1,
					limit: 10,
					orderBy: 'id',
					orderType: 'DESC',
					q: inputValue
				})

				if (response.success) {
					resolve([...response.data.items.map(guest => {
						let name = (guest.guest_name) ? guest.guest_name : `${guest.first_name || ''} ${guest.last_name || ''}`;
						return {
							value: guest.id,
							label: `${name} (${(guest.email) ? guest.email : 'N/A'})`
						}
					})])
				}
			}
			else {
				resolve([])
			}
		});
	}

	const handleSubmit = async () => {

		if (eventId && previewGuestId) {
			const response = await apiGuestBadgePrint(eventId, previewGuestId)

			if (response.success) {
				setTimeout(() => {
					console.log('aaa', response.data)
					window.open(response.data)
				})
			} else {
				alert(response.message)
			}
		}
	}

	return (
		<>
			<Modal show={isOpen} className="createNewEmailTemplateModal" onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>Generate Preview</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>

					<Form.Group className="" controlId="selectGuest">
						<Form.Label>Select Guest:</Form.Label>
						<InputGroup className="">
							<div style={{ flex: 1 }}>
								{(previewGuestId === null) ? (
									<AsyncSelect
										cacheOptions
										defaultOptions
										value={null}
										loadOptions={searchGuests}
										onChange={e => {
											setPreviewGuestId(e.value)
										}}
									/>
								) : (
									<AsyncSelect
										cacheOptions
										defaultOptions
										loadOptions={searchGuests}
										onChange={e => {
											setPreviewGuestId(e.value)
										}}
									/>
								)}
							</div>

							{(previewGuestId !== null) ? (
								<Button variant="outline-secondary" onClick={() => setPreviewGuestId(null)}>
									Cancel
								</Button>
							) : null}
						</InputGroup>
					</Form.Group>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setIsOpen(false)} style={{ width: 'auto', flexGrow: 1, padding: '1rem' }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSubmit} style={{ width: 'auto', flexGrow: 1.8, padding: '1rem' }} disabled={isLoading}>
						<div className='d-flex align-items-center justify-content-between'>
							<span>Print Preview</span>
							<span className="arrow">
								<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default BadgePreviewPopup;