import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Navigate } from 'react-router-dom';
import Table from 'react-bootstrap/esm/Table';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { batch } from 'react-redux';
import { setHeaderTitle } from 'redux/interfaceManger';
import { DataFieldType, EventDataField } from 'types';
import { apiDeleteEventDataFields, apiGetAllDataFields, apiGetEventDataFields, apiUpdateEventDataFields } from 'api/events';
import { DataFieldTypes, SystemDataFields } from 'constant';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

type ExtendedProps = {
	isEditable: boolean
	isDragging: boolean
	isChange: boolean
}
type EventDataFieldExtended = EventDataField & ExtendedProps

type ConfirmDeleteType = {
	field: EventDataFieldExtended
	index: number
}


let is_sorting: boolean = false;
let sort_clientY: null | number = null;
let sort_field: null | string = null;

const DataFields = () => {
	const dispatch = useAppDispatch();
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const [eventDataFields, setEventDataFields] = useState<EventDataFieldExtended[]>([]);
	const [confirmGuestDelete, setConfirmGuestDelete] = useState<ConfirmDeleteType | null>(null);

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Data Fields'))
		})
	}, [])

	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const response = await apiGetEventDataFields(activeEvent.id);
				if (response.success) {
					batch(() => {
						setEventDataFields([...response.data.map(f => {
							return {
								...f,
								isEditable: true,
								isDragging: false,
								isChange: false,
							}
						})]);
					})
				}
			}
		})()
	}, [activeEvent])

	if (!activeEvent) {
		return <Navigate to="/" />
	}

	const capitalizeEachWord = (word: string): string => {
		return word.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
	}

	const handleDataFieldChange = (index: number, key: string, value: string | number | boolean): void => {

		setEventDataFields(eventDataFields.map((field, i) => {
			if (i === index) {
				return {
					...field,
					[key]: value,
					isChange: true,
				};
			}

			return field;
		}))
	}

	const update = async () => {
		console.log(eventDataFields);

		const response = await apiUpdateEventDataFields(activeEvent.id, eventDataFields);
		if (response.success) {
			setEventDataFields(eventDataFields.map((field, i) => {
				return {
					...field,
					isEditable: true,
					isDragging: false,
					isChange: false,
				};
			}))
			toast.success(response.message)
		} else {
			toast.error(response.message)
		}
	}

	const deleteField = async (field: EventDataFieldExtended, index: number) => {

		// if deleting field which is not saved yet
		if (field.id === 0) {
			console.log('delete index', index)
			batch(() => {
				setEventDataFields([...eventDataFields.filter((f, i) => i !== index)])
				setConfirmGuestDelete(null)
			})
			return;
		}

		const response = await apiDeleteEventDataFields(activeEvent.id, field.id);

		if (response.success) {
			batch(() => {
				setEventDataFields([...response.data.map(f => {
					return {
						...f,
						isEditable: true,
						isDragging: false,
						isChange: false,
					}
				})]);
				setConfirmGuestDelete(null)
			})
			toast.success(response.message)
		} else {
			toast.error(response.message)
		}
	}

	const addField = async () => {
		const response = await apiGetAllDataFields(activeEvent.id);

		if (response.success) {

			if (response.data && response.data.length > 0) {
				let addedFields = eventDataFields.map(field => field.field);
				let availableFields: string[] = response.data.filter(f => !addedFields.includes(f));

				if (availableFields.length) {
					const fieldName = availableFields[0];
					batch(() => {
						setEventDataFields([
							...eventDataFields,
							{
								id: 0,
								event_id: activeEvent.id,
								field: fieldName,
								display_name: '',
								type: DataFieldTypes[0],
								options: null,
								order: eventDataFields.length,
								is_visible: 1,
								show_in_app: 1,
								isEditable: true,
								isDragging: false,
								isChange: true,
							}
						]);
					})
				}
			}
		}
	}

	const dragHandleDown = (field: EventDataFieldExtended) => {
		setEventDataFields([...eventDataFields.map(f => {
			if (f.id === field.id) {
				f.isDragging = true
			}
			return f;
		})])
	}

	const onDragStart = (event: any) => {
		// event.preventDefault();
		console.log('onDragStart', event);

		is_sorting = true;
		// event.target.className = 'dragging';
		sort_field = event.target.getAttribute('data-value');
		event.dataTransfer.setData("field", event.target.getAttribute('data-value'));
	}

	const onDragOver = (event: any) => {
		event.preventDefault();
		// console.log('onDragOver', event);

		if (is_sorting && sort_field) {
			if (sort_clientY === null) {
				sort_clientY = event.clientY; // sort start point
			} else {
				let res = sort_clientY - event.clientY;
				// console.log('drag over', event.clientY, res)

				const itemHeight = 85;

				if (res > itemHeight) {
					// move up
					sort_clientY = null;
					console.log('move up')
					setEventDataFields([...onMoveIndex(eventDataFields, sort_field, -1)])
				}
				else if (res < -itemHeight) {
					// move down
					sort_clientY = null;
					console.log('move down')
					setEventDataFields([...onMoveIndex(eventDataFields, sort_field, 1)])
				}
			}
		}
	}

	const onMoveIndex = (array: EventDataFieldExtended[], selectedValue: string, direction: number) => {
		const index = array.findIndex(item => item.field === selectedValue);
		if (index !== -1) {
			const newIndex = index + direction;
			
			if (newIndex >= 0 && newIndex < array.length) {
				const splicedItem = array.splice(index, 1)[0];
				array.splice(newIndex, 0, splicedItem);
				return array;
			}
		}
		// Return the array unchanged if the selected value is not found or moving is not possible
		return array;
	}

	const onDrop = (event: any) => {
		event.preventDefault();
		console.log('onDrop', event);
		is_sorting = false;
		sort_clientY = null;
		sort_field = null;
		
		setEventDataFields([...eventDataFields.map((f, i) => {
			f.isDragging = false;
			f.isChange = true;
			f.order = i;
			return f;
		})])
	}


	return (
		<>
			<div className="subHeader">
				<Row>
					<Col lg="5">
						<h4>
							Edit Field Names or create your own custom fields and <br />
							change the order of fields in the Check-in App
						</h4>
					</Col>

					<Col lg="2"></Col>

					<Col lg="5">
						<figure>
							<img src={require('assets/images/data-fields-top-img.png')} alt="" />
						</figure>
					</Col>
				</Row>
			</div>

			<div className="cp_table dataFields mb-5" style={{ height: 'calc(100% - 170px)' }}>
				<Form onSubmit={e => e.preventDefault()}>
					<Table>
						<thead>
							<tr>
								<th style={{ width: '50px' }}></th>

								<th style={{ width: '350px' }}>
									<div className="headtitle">
										Field Name
									</div>
								</th>

								<th style={{ width: 'auto' }}>
									<div className="headtitle">
										Display Name
									</div>
								</th>

								<th style={{ width: '260px' }}>
									<div className="headtitle">
										Type
									</div>
								</th>

								<th style={{ width: '150px' }}>
									<div className="headtitle" style={{ justifyContent: 'center' }}>
										Show in App
									</div>
								</th>

								<th style={{ width: '150px' }} className='text-end'>
									<Button type="button" 
										variant='dark' 
										onClick={update} 
										disabled={(eventDataFields.find(field => field.isChange === true)) ? false : true}
									>Save</Button>
								</th>
							</tr>
						</thead>

						<tbody onDrop={onDrop} onDragOver={onDragOver}>

							{eventDataFields.map((field, i) => {
								return (
									<tr key={i} draggable={field.isDragging} onDragStart={onDragStart} data-value={field.field} className={(field.isDragging) ? 'dragging' : ''}>
										<td style={{ width: '50px' }}>
											<div className="tbodyText">
												{(field.isEditable) ? (
													<Button type='button' variant='ghost' className="ico" style={{border: 0}} onMouseDown={(e) => dragHandleDown(field)}>
														<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#FBF8F8" stroke="#8C838C" />
															<rect x="17" y="17.5" width="12" height="1" rx="0.5" fill="#2C292C" />
															<rect x="17" y="21.5" width="12" height="1" rx="0.5" fill="#2C292C" />
															<rect x="17" y="25.5" width="12" height="1" rx="0.5" fill="#2C292C" />
														</svg>
													</Button>
												) : (
													<Button type='button' variant='ghost' className="ico" style={{border: 0}} disabled onMouseDown={(e) => dragHandleDown(field)}>
														<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#FBF8F8" stroke="none" />
															<rect x="17" y="17.5" width="12" height="1" rx="0.5" fill="#CFC8CF" />
															<rect x="17" y="21.5" width="12" height="1" rx="0.5" fill="#CFC8CF" />
															<rect x="17" y="25.5" width="12" height="1" rx="0.5" fill="#CFC8CF" />
														</svg>
													</Button>
												)}
											</div>
										</td>

										<td style={{ width: '350px' }}>
											<div className="tbodyText">
												<Form.Control size="lg" type="text" value={capitalizeEachWord((field.field.indexOf('other_field') === 0) ? field.field.replace('other', 'custom') : field.field)} disabled />
											</div>
										</td>

										<td style={{ width: 'auto' }}>
											<div className="tbodyText">
												<div className="inputWithBtn">
													<Form.Control size="lg" type="text" disabled={!field.isEditable} value={field.display_name} onChange={e => handleDataFieldChange(i, 'display_name', e.target.value)} />
												</div>
											</div>
										</td>

										<td style={{ width: '260px' }}>
											<div className="tbodyText">
												<Form.Select size="lg" disabled={!field.isEditable || SystemDataFields.includes(field.field)} value={field.type} onChange={e => handleDataFieldChange(i, 'type', e.target.value)}>
													{(field.field === 'status') ? (
														<>
															<option value='dropdown'>Dropdown</option>
														</>
													) : (
														DataFieldTypes.map((type: DataFieldType) => {
															return (
																<option value={type}>{capitalizeEachWord(type)}</option>
															)
														})
													)}
												</Form.Select>
											</div>
										</td>


										<td style={{ width: '150px' }}>
											<div style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}}>
											<Form.Check type="switch" color='dark' id={`show-field-switch-${i}`} disabled={!field.isEditable} checked={(field.show_in_app) ? true : false} onChange={e => {
												handleDataFieldChange(i, 'show_in_app', (field.show_in_app === 1 ? 0 : 1))
											}} />
											</div>
										</td>

										<td style={{ width: '150px' }}>
											<div className="tbodyText" style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end'
											}}>
												
												<div className="actions">
													<Button variant="" className='btn-theme-view' disabled={!field.isEditable} onClick={e => {
														handleDataFieldChange(i, 'is_visible', (field.is_visible === 1 ? 0 : 1))
													}}>
														<i className="ico">
															{field.is_visible === 1 ? (
															<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M1.18763 6.50941C1.09079 6.35539 1.04237 6.27837 1.01527 6.15958C0.99491 6.07036 0.99491 5.92964 1.01527 5.84042C1.04237 5.72163 1.09079 5.64462 1.18763 5.49059C1.98789 4.21774 4.36992 1 8 1C11.6301 1 14.0121 4.21774 14.8124 5.49059C14.9092 5.64462 14.9576 5.72163 14.9847 5.84042C15.0051 5.92964 15.0051 6.07036 14.9847 6.15958C14.9576 6.27837 14.9092 6.35538 14.8124 6.50941C14.0121 7.78226 11.6301 11 8 11C4.36992 11 1.98789 7.78226 1.18763 6.50941Z" stroke="#2C292C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
																<path d="M8 8.14286C9.17816 8.14286 10.1332 7.18347 10.1332 6C10.1332 4.81653 9.17816 3.85714 8 3.85714C6.82184 3.85714 5.86675 4.81653 5.86675 6C5.86675 7.18347 6.82184 8.14286 8 8.14286Z" stroke="#2C292C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
															</svg>
															) : (
															<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M7.10583 2.48781C7.39482 2.44508 7.69295 2.42216 8 2.42216C11.6301 2.42216 14.0121 5.62547 14.8124 6.8926C14.9092 7.04597 14.9576 7.12265 14.9847 7.24092C15.0051 7.32975 15.0051 7.46989 14.9847 7.55871C14.9576 7.67698 14.9088 7.75417 14.8113 7.90855C14.5981 8.24602 14.273 8.72028 13.8423 9.23464M4.24827 3.64169C2.71086 4.68461 1.66714 6.13356 1.18833 6.89151C1.09104 7.04552 1.0424 7.12252 1.01528 7.24079C0.994914 7.32961 0.994906 7.46973 1.01526 7.55855C1.04237 7.67682 1.09079 7.7535 1.18764 7.90686C1.9879 9.17399 4.36993 12.3773 8 12.3773C9.46369 12.3773 10.7245 11.8565 11.7605 11.1518M1.60027 1L14.3997 13.7995M6.49157 5.8913C6.10553 6.27734 5.86675 6.81065 5.86675 7.39973C5.86675 8.57788 6.82184 9.53297 8 9.53297C8.58908 9.53297 9.12239 9.2942 9.50843 8.90816" stroke="#8C838C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
															</svg>
															)}
														</i>
													</Button>

													<Button variant="" className='btn-theme-delete' 
														disabled={SystemDataFields.includes(field.field)} 
														onClick={() => setConfirmGuestDelete({field, index: i})}
													>
														<i className="ico">
															<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M10.1 3.8V3.24C10.1 2.45593 10.1 2.06389 9.94741 1.76441C9.81319 1.50099 9.59901 1.28681 9.33559 1.15259C9.03611 1 8.64407 1 7.86 1H6.74C5.95593 1 5.56389 1 5.26441 1.15259C5.00099 1.28681 4.78681 1.50099 4.65259 1.76441C4.5 2.06389 4.5 2.45593 4.5 3.24V3.8M5.9 7.65V11.15M8.7 7.65V11.15M1 3.8H13.6M12.2 3.8V11.64C12.2 12.8161 12.2 13.4042 11.9711 13.8534C11.7698 14.2485 11.4485 14.5698 11.0534 14.7711C10.6042 15 10.0161 15 8.84 15H5.76C4.58389 15 3.99583 15 3.54662 14.7711C3.15148 14.5698 2.83022 14.2485 2.62889 13.8534C2.4 13.4042 2.4 12.8161 2.4 11.64V3.8" stroke="#2C292C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
															</svg>
														</i>
													</Button>
												</div>
											</div>
										</td>
									</tr>
								)
							})}

						</tbody>
					</Table>

					<div className="d-flex justify-content-end align-items-center p-4">
						<div className="headtitle" style={{ width: "130px" }}>
							Add Field

							<Button type='button' onClick={addField}>
								<i className="ico">
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M7 1V13M1 7H13" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</i>
							</Button>
						</div>
					</div>
				</Form>
			</div>


			{(confirmGuestDelete !== null) && (
			<Modal aria-labelledby="contained-modal-title-vcenter" centered show={confirmGuestDelete !== null} onHide={() => setConfirmGuestDelete(null)} className='deleteModal'>
				<Modal.Header closeButton>
					<Modal.Title>Delete Data Field</Modal.Title>
				</Modal.Header>

				<Modal.Body>Are you sure you want to delete "{confirmGuestDelete.field.display_name}"?</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => setConfirmGuestDelete(null)}>
						Cancel
					</Button>

					<Button variant="danger" onClick={() => deleteField(confirmGuestDelete.field, confirmGuestDelete.index)}>
						Yes delete
					</Button>
				</Modal.Footer>
			</Modal>
			)}
		</>
	);
};

export default DataFields;