import { ResponseObject, Team, TeamCreateUserData, TeamInviteUserData, TeamUpdateUserData } from "types";
import apiService from "services/ApiService";
import cacheService from "services/CacheService";


export const apiGetTeam = async (): Promise<ResponseObject<Team[]>> => {
	const response = await apiService.get(`/team`);
	return response;
}

export const apiCreateTeamMember = async (data: TeamCreateUserData): Promise<ResponseObject<Team>> => {
	const response = await apiService.post(`/team-add-member`, data);
	return response;
}

export const apiInviteTeamMember = async (data: TeamInviteUserData): Promise<ResponseObject<Team>> => {
	const response = await apiService.post(`/team-invite-member`, data);
	return response;
}

export const apiUpdateTeamMember = async (id: number, data: TeamUpdateUserData): Promise<ResponseObject<Team>> => {
	const response = await apiService.post(`/team-update-member/${id}`, data);
	return response;
}

export const apiResendInviteTeam = async (id: number): Promise<ResponseObject<Team>> => {
	const response = await apiService.post(`/team-resent-invite/${id}`, {});
	return response;
}

export const apiDeleteTeamMember = async (id: number): Promise<ResponseObject<null>> => {
	const response = await apiService.post(`/team-member-delete/${id}`, {});
	return response;
}

export const apiLeaveTeamEvent = async (eventId: number): Promise<ResponseObject<null>> => {
	const response = await apiService.post(`/team-leave-event/${eventId}`, {});
	return response;
}

export const apiValidateToken = async (token: string | undefined): Promise<ResponseObject<Team>> => {
	const response = await apiService.auth(`/validate-invitation-token`, {
		token
	});
	return response;
}

export const apiAcceptInvitation = async (token: string | undefined, name: string, password: string): Promise<ResponseObject<Team>> => {
	const response = await apiService.auth(`/accept-invitation`, {
		token,
		name,
		password,
	});
	return response;
}