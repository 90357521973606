import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaperFormat, PaperLength, PaperOrientation, PrinterSetup } from 'types';


const initialState: PrinterSetup = {
	setupModalOpen: false,
	printerId: null,
	paperFormat: 58,
	paperLength: 40,
	orientation: 'landscape',
	autoPrint: true,
}


export const printerSlice = createSlice({
	name: 'printer',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		toggleSetupModal(state: PrinterSetup, action: PayloadAction<boolean>) {
			state.setupModalOpen = action.payload;
		},
		setPrinter(state: PrinterSetup, action: PayloadAction<number | null>) {
			state.printerId = action.payload;
		},
		setPaperFormat(state: PrinterSetup, action: PayloadAction<PaperFormat | null>) {
			state.paperFormat = action.payload;
		},
		setPaperLength(state: PrinterSetup, action: PayloadAction<PaperLength | null>) {
			state.paperLength = action.payload;
		},
		setOrientation(state: PrinterSetup, action: PayloadAction<PaperOrientation>) {
			state.orientation = action.payload;
		},
		toggleAutoPrint(state: PrinterSetup, action: PayloadAction<boolean>) {
			state.autoPrint = action.payload;
		},
	},
});


export const {
	toggleSetupModal,
	setPrinter,
	setPaperFormat,
	setPaperLength,
	setOrientation,
	toggleAutoPrint,
} = printerSlice.actions;

export default printerSlice.reducer;
