
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import dayjs from 'dayjs'
import { Card, CardGroup, ListGroup, ProgressBar } from 'react-bootstrap';
import { apiGetGuestLists } from 'api/guestLists';
import { apiGetEventPrinterLabels } from 'api/printers';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setEventPrinterLabels } from 'redux/eventPrinterLabels';
import { setFooterMessage, setHeaderTitle, setLastPage } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { Event, GuestList } from 'types';
import useWindowDimensions from 'hooks/useWindowDimensions';
import EventCardList from 'views/User/components/EventCardList';
import EventCardGrid from 'views/User/components/EventCardGrid';
import { apiGetAllGuestsByEvent } from 'api/guests';
import { apiGetEventDataFields } from 'api/events';
import cacheService from 'services/CacheService';

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const EventHeader = ({event}: {event: Event}) => {
	const {width} = useWindowDimensions();
	const [agendaLightBoxOpen, setAgendaLightBoxOpen] = useState<boolean>(false);
	const [floorPlanLightBoxOpen, setFloorPlanLightBoxOpen] = useState<boolean>(false);

	return (
		<>
		<div style={{
			paddingLeft: (width < 768) ? 10 : 25,
			paddingRight: (width < 768) ? 10 : 25,
			paddingTop: (width < 768) ? 10 : 25,
		}} className='mb-2'>
			<Row>
				<Col xs={8} className='cp_card_event'>
					<h5>{event.name}</h5>
					<p className="card-text mb-2">
						<i className='ps-1'>
							<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.8 5.8H1M8.8 1V3.4M4 1V3.4M3.88 13H8.92C9.92809 13 10.4321 13 10.8172 12.8038C11.1559 12.6312 11.4312 12.3559 11.6038 12.0172C11.8 11.6321 11.8 11.1281 11.8 10.12V5.08C11.8 4.07191 11.8 3.56786 11.6038 3.18282C11.4312 2.84413 11.1559 2.56876 10.8172 2.39619C10.4321 2.2 9.9281 2.2 8.92 2.2H3.88C2.87191 2.2 2.36786 2.2 1.98282 2.39619C1.64413 2.56876 1.36876 2.84413 1.19619 3.18282C1 3.56786 1 4.07191 1 5.08V10.12C1 11.1281 1 11.6321 1.19619 12.0172C1.36876 12.3559 1.64413 12.6312 1.98282 12.8038C2.36786 13 2.87191 13 3.88 13Z" stroke="#CFC8CF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</i>
						{dayjs(event.start_data).format('MMM DD, YYYY')} - {dayjs(`${event.start_data} ${event.time}`).format('h:mm a')}
					</p>
					<p className="card-text">
						<i>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12.8421 10.5523C15.0727 10.9908 16.6316 11.9924 16.6316 13.1579C16.6316 14.7275 13.8039 16 10.3158 16C6.82768 16 4 14.7275 4 13.1579C4 11.9924 5.55886 10.9908 7.78947 10.5523M10.3158 12.8421V7.78947M10.3158 7.78947C11.3622 7.78947 12.2105 6.94117 12.2105 5.89474C12.2105 4.8483 11.3622 4 10.3158 4C9.26936 4 8.42105 4.8483 8.42105 5.89474C8.42105 6.94117 9.26936 7.78947 10.3158 7.78947Z" stroke="#CFC8CF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</i>
						{event.location}
					</p>
				</Col>
				<Col xs={4} className='text-end'>
					<div className='d-flex align-items-center justify-content-end pt-2' style={{ gap: '1rem' }}>
						{(event.agenda_images && event.agenda_images.length) ? (
							<Button type='button' variant='' size='sm' onClick={() => setAgendaLightBoxOpen(true)}>
							{(event.agenda_icon === 1) && (
								<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M6.3,14.6H5.6c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8
											H11"/>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M17.7,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8H9.9c-1,0-1.8-0.8-1.8-1.8v-6.6
											c0-1,0.8-1.8,1.8-1.8H13"/>
									</g>
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M99.2,15.2l-1.1,4h-3.3c-1.2,0-2.2-1-2.2-2.3V7.2c0-1.3,1-2.3,2.2-2.3h10.5c1.2,0,2.2,1,2.2,2.3v9.7
											c0,1.3-1,2.3-2.2,2.3h-2.6V4.8"/>
										<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="102.7" y1="11" x2="92.5" y2="11"/>
										<rect x="92.5" y="11" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.5" height="4.3"/>
									</g>
									<g>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M55,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
											L55,8.5z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M52,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9L50,8.4c-0.5-0.4-1.2-0.2-1.4,0.3L47.9,11
											c-0.2,0.5,0.3,1,1,1L52,12.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M54.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5s-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
											c0.4,0.3,1.1,0,1.3-0.6L54.5,16.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M59.1,14.9c-0.2,0.2-0.4,0.5-0.7,0.7s-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
											c0.4-0.3,0.3-1-0.2-1.4L59.1,14.9z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M59.4,10.2c0.2,0.3,0.3,0.6,0.4,0.9s0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
											c-0.2-0.5-0.9-0.6-1.4-0.3L59.4,10.2z"/>
										<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="56" cy="12.4" r="1.7"/>
									</g>
								</svg>
							)}

							{(event.agenda_icon === 2) && (
								<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-81.7,14.6h-0.7c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6
											c0,1-0.8,1.8-1.8,1.8H-77"/>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-70.3,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8h-8.6c-1,0-1.8-0.8-1.8-1.8v-6.6
											c0-1,0.8-1.8,1.8-1.8h3.1"/>
									</g>
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M11.1,15.6L9.9,20H6.2c-1.4,0-2.5-1.2-2.5-2.6V6.6C3.7,5.2,4.8,4,6.2,4h11.6c1.4,0,2.5,1.2,2.5,2.6v10.7
											c0,1.4-1.1,2.6-2.5,2.6H15V4"/>
										<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="15" y1="10.9" x2="3.7" y2="10.9"/>
										<rect x="3.7" y="10.9" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.9" height="4.7"/>
									</g>
									<g>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-33,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
											L-33,8.5z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-36,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9l-2.6-1.8c-0.5-0.4-1.2-0.2-1.4,0.3l-0.7,2.2
											c-0.2,0.5,0.3,1,1,1L-36,12.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-33.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5s-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
											c0.4,0.3,1.1,0,1.3-0.6L-33.5,16.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-28.9,14.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
											c0.4-0.3,0.3-1-0.2-1.4L-28.9,14.9z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-28.6,10.2c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
											c-0.2-0.5-0.9-0.6-1.4-0.3L-28.6,10.2z"/>
										<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="-32" cy="12.4" r="1.7"/>
									</g>
								</svg>
							)}

							{(event.agenda_icon === 3) && (
								<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-37.7,14.6h-0.7c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6
											c0,1-0.8,1.8-1.8,1.8H-33"/>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-26.3,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8h-8.6c-1,0-1.8-0.8-1.8-1.8v-6.6
											c0-1,0.8-1.8,1.8-1.8h3.1"/>
									</g>
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M55.1,15.6L53.9,20h-3.7c-1.4,0-2.5-1.2-2.5-2.6V6.6c0-1.4,1.1-2.6,2.5-2.6h11.6c1.4,0,2.5,1.2,2.5,2.6v10.7
											c0,1.4-1.1,2.6-2.5,2.6H59V4"/>
										<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="59" y1="10.9" x2="47.7" y2="10.9"/>
										<rect x="47.7" y="10.9" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.9" height="4.7"/>
									</g>
									<g>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M11,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
											L11,8.5z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M8,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9L6.1,8.5C5.5,8.1,4.8,8.3,4.7,8.8L3.9,11
											c-0.2,0.5,0.3,1,1,1L8,12.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M10.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
											c0.4,0.3,1.1,0,1.3-0.6L10.5,16.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M15.1,14.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
											c0.4-0.3,0.3-1-0.2-1.4L15.1,14.9z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M15.4,10.2c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
											c-0.2-0.5-0.9-0.6-1.4-0.3L15.4,10.2z"/>
										<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="12" cy="12.4" r="1.7"/>
									</g>
								</svg>
							)}
							</Button>
						) : null}
						
						{(event.floor_plan_images && event.floor_plan_images.length) ? (
							<Button type='button' variant='' size='sm' onClick={() => setFloorPlanLightBoxOpen(true)}>
							{(event.floor_plan_icon === 1) && (
								<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M6.3,14.6H5.6c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8
											H11"/>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M17.7,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8H9.9c-1,0-1.8-0.8-1.8-1.8v-6.6
											c0-1,0.8-1.8,1.8-1.8H13"/>
									</g>
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M99.2,15.2l-1.1,4h-3.3c-1.2,0-2.2-1-2.2-2.3V7.2c0-1.3,1-2.3,2.2-2.3h10.5c1.2,0,2.2,1,2.2,2.3v9.7
											c0,1.3-1,2.3-2.2,2.3h-2.6V4.8"/>
										<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="102.7" y1="11" x2="92.5" y2="11"/>
										<rect x="92.5" y="11" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.5" height="4.3"/>
									</g>
									<g>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M55,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
											L55,8.5z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M52,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9L50,8.4c-0.5-0.4-1.2-0.2-1.4,0.3L47.9,11
											c-0.2,0.5,0.3,1,1,1L52,12.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M54.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5s-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
											c0.4,0.3,1.1,0,1.3-0.6L54.5,16.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M59.1,14.9c-0.2,0.2-0.4,0.5-0.7,0.7s-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
											c0.4-0.3,0.3-1-0.2-1.4L59.1,14.9z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M59.4,10.2c0.2,0.3,0.3,0.6,0.4,0.9s0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
											c-0.2-0.5-0.9-0.6-1.4-0.3L59.4,10.2z"/>
										<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="56" cy="12.4" r="1.7"/>
									</g>
								</svg>
							)}

							{(event.floor_plan_icon === 2) && (
								<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-81.7,14.6h-0.7c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6
											c0,1-0.8,1.8-1.8,1.8H-77"/>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-70.3,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8h-8.6c-1,0-1.8-0.8-1.8-1.8v-6.6
											c0-1,0.8-1.8,1.8-1.8h3.1"/>
									</g>
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M11.1,15.6L9.9,20H6.2c-1.4,0-2.5-1.2-2.5-2.6V6.6C3.7,5.2,4.8,4,6.2,4h11.6c1.4,0,2.5,1.2,2.5,2.6v10.7
											c0,1.4-1.1,2.6-2.5,2.6H15V4"/>
										<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="15" y1="10.9" x2="3.7" y2="10.9"/>
										<rect x="3.7" y="10.9" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.9" height="4.7"/>
									</g>
									<g>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-33,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
											L-33,8.5z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-36,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9l-2.6-1.8c-0.5-0.4-1.2-0.2-1.4,0.3l-0.7,2.2
											c-0.2,0.5,0.3,1,1,1L-36,12.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-33.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5s-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
											c0.4,0.3,1.1,0,1.3-0.6L-33.5,16.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-28.9,14.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
											c0.4-0.3,0.3-1-0.2-1.4L-28.9,14.9z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-28.6,10.2c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
											c-0.2-0.5-0.9-0.6-1.4-0.3L-28.6,10.2z"/>
										<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="-32" cy="12.4" r="1.7"/>
									</g>
								</svg>
							)}

							{(event.floor_plan_icon === 3) && (
								<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-37.7,14.6h-0.7c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6
											c0,1-0.8,1.8-1.8,1.8H-33"/>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-26.3,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8h-8.6c-1,0-1.8-0.8-1.8-1.8v-6.6
											c0-1,0.8-1.8,1.8-1.8h3.1"/>
									</g>
									<g>
										<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M55.1,15.6L53.9,20h-3.7c-1.4,0-2.5-1.2-2.5-2.6V6.6c0-1.4,1.1-2.6,2.5-2.6h11.6c1.4,0,2.5,1.2,2.5,2.6v10.7
											c0,1.4-1.1,2.6-2.5,2.6H59V4"/>
										<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="59" y1="10.9" x2="47.7" y2="10.9"/>
										<rect x="47.7" y="10.9" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.9" height="4.7"/>
									</g>
									<g>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M11,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
											L11,8.5z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M8,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9L6.1,8.5C5.5,8.1,4.8,8.3,4.7,8.8L3.9,11
											c-0.2,0.5,0.3,1,1,1L8,12.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M10.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
											c0.4,0.3,1.1,0,1.3-0.6L10.5,16.1z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M15.1,14.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
											c0.4-0.3,0.3-1-0.2-1.4L15.1,14.9z"/>
										<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M15.4,10.2c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
											c-0.2-0.5-0.9-0.6-1.4-0.3L15.4,10.2z"/>
										<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="12" cy="12.4" r="1.7"/>
									</g>
								</svg>
							)}
							</Button>
						) : null}
					</div>
				</Col>
			</Row>
		</div>

		{(event.agenda_images && event.agenda_images.length && agendaLightBoxOpen) ? (
			<Lightbox
				open={agendaLightBoxOpen}
				close={() => setAgendaLightBoxOpen(false)}
				plugins={[Zoom]}
				slides={[
				...event.agenda_images.map(i => {
					return {
						src: i,
					}
				})
				]}
			/>
		) : null}

		{(event.floor_plan_images && event.floor_plan_images.length && floorPlanLightBoxOpen) ? (
			<Lightbox
				open={floorPlanLightBoxOpen}
				close={() => setFloorPlanLightBoxOpen(false)}
				plugins={[Zoom]}
				slides={[
				...event.floor_plan_images.map(i => {
					return {
						src: i,
					}
				})
				]}
			/>
		) : null}
		</>
	)
}

const GuestLists = () => {
	const { state }: { state: Event } = useLocation()
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	let { eventId } = useParams();
	const isOnline = useAppSelector(s => s.interfaceManger.isOnline);
	const lastPage = useAppSelector(s => s.interfaceManger.lastPage);
	const [isSyncing, setIsSyncing] = useState<boolean>(true);
	const [syncingPercentage, setSyncingPercentage] = useState<number>(0);
	const [all, setAll] = useState<GuestList | null>(null);
	const [lists, setLists] = useState<GuestList[]>([]);
	const {width} = useWindowDimensions();

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Guest List'))
			dispatch(setFooterMessage(<p>Syncing...</p>))
			dispatch(setLastPage(window.location.pathname))
		})
	}, [])

	useEffect(() => {

		(async () => {
			// load data from cache if back from guests list page
			if (lastPage.indexOf('/guests/') === 0) {
				const response = cacheService.makeOfflineResponse(`@event:${state.id}:guest-lists`)
				
				if (response.success) {
					batch(() => {
						setIsSyncing(false);
						setAll(response.data.all)
						setLists(response.data.lists)
						dispatch(setFooterMessage(<p>Guest List Sync Complete <i className="fa-solid fa-check"></i></p>))
					})
					// return;
				}
			}

			const response = await apiGetGuestLists(state.id)
			setSyncingPercentage(50);
			await syncGuests();

			setIsSyncing(false);
			dispatch(setFooterMessage(<p>Guest List Sync Complete <i className="fa-solid fa-check"></i></p>))

			if (response.success) {
				batch(() => {
					setAll(response.data.all)
					setLists(response.data.lists)
				})
			}
		})();

	}, [state])


	const syncGuests = async () => {
		if (!isOnline) return;

		const response = await apiGetAllGuestsByEvent(state.id)
		if (response.success) {
			localStorage.setItem(`@event:${state.id}:guests`, JSON.stringify(response.data))
		}
		setSyncingPercentage(100);

		// const responsePrintLabels = await apiGetEventPrinterLabels(state.id)
		// if (responsePrintLabels.success) {
		// 	localStorage.setItem(`@event:${state.id}:printer-labels`, JSON.stringify([...responsePrintLabels.data]))
		// }


		const respEventFields = await apiGetEventDataFields(state.id);
		if (respEventFields.success) {
			localStorage.setItem(`@event:${state.id}:event-data-fields`, JSON.stringify([...respEventFields.data]))
		}
	}


	if (isSyncing) {
		return (
			<div className='cp-ceremony'>
				<div className='content-dv'>
					<h1 style={{
						lineHeight: (width < 768) ? '45px' : '80px'
					}}>{state.name}</h1>
					<div className='syncing'>
						<ProgressBar variant='dark' animated min={0} max={100} now={syncingPercentage} />
						<div className='labels'>
							<p>Syncing Guest List</p>
							{/* <p>0%</p> */}
						</div>
					</div>
				</div>
				<Button className='btn-theme' onClick={e => navigate('/')}>Cancel Sync</Button>
			</div>
		)
	}

	return (
		<>
			<div className="cp_app_content_bx page-guest-list">
				<div className={`${(width < 992) ? 'container-fluid' : 'container'}`}>
					<Row>
						<Col>
							{(width < 768) ? (
							<div className="small">
								{(lists.length > 1) ? (
								<div className="cp_table p-2 mb-3">
								<EventHeader event={state} />
								<Card onClick={e => navigate(`/guests/${eventId}`, { state: {list: all, event: state} })} className="mb-3">
									<Card.Header 
									style={{
										backgroundColor: '#F7F6F7',
										color: '#2C292C'
									}}
									>{all?.list_name}</Card.Header>
									<Card.Body>
										<Row>
											<Col className="text-center">
												<div style={{color: '#8C838C'}}>Total</div>
												<div><strong>{all?.total}</strong></div>
											</Col>
											<Col className="text-center">
												<div style={{color: '#8C838C'}}>Confirmed</div>
												<div><strong>{all?.confirmed}</strong></div>
											</Col>
											<Col className="text-center">
												<div style={{color: '#8C838C'}}>Checked</div>
												<div><strong>{all?.checked_in}</strong></div>
											</Col>
										</Row>
										{(all) ? (
											<ProgressBar 
												className='mt-2'
												variant='dark'
												max={100} 
												min={0} 
												isChild={false}
												striped={true}
												now={(all.checked_in * 100) / all.total}
											></ProgressBar>
										) : null}
									</Card.Body>
								</Card>
								</div>
								) : null}

								<div className="cp_table p-2">
								{lists.map((list, i) => {
									return (
										<Card onClick={e => navigate(`/guests/${eventId}/${list.id}`, { state: {list: list, event: state} })} key={i} className="mb-3">
											<Card.Header style={{
												minHeight: 60,
												lineHeight: 'initial',
												display: 'flex',
												justifyContent: 'flex-start',
												alignItems: 'center',
												backgroundColor: '#F7F6F7',
												color: '#2C292C'
											}}>{list.list_name}</Card.Header>
											<Card.Body>
												<Row>
													<Col className="text-center">
														<div style={{color: '#8C838C'}}>Total</div>
														<div><strong>{list.total}</strong></div>
													</Col>
													<Col className="text-center">
														<div style={{color: '#8C838C'}}>Confirmed</div>
														<div><strong>{list.confirmed}</strong></div>
													</Col>
													<Col className="text-center">
														<div style={{color: '#8C838C'}}>Checked</div>
														<div><strong>{list.checked_in}</strong></div>
													</Col>
												</Row>
												<ProgressBar 
													className='mt-2'
													variant='dark'
													max={100} 
													min={0} 
													isChild={false}
													striped={true}
													now={(list.checked_in * 100) / list.total}
												></ProgressBar>
											</Card.Body>
										</Card>
									)
								})}
								</div>
							</div>
							) : (
							<>
							{(lists.length > 1) ? (
							<div className="cp_table ">
								<EventHeader event={state} />
								<table className="table table-borderless">
									<thead>
										<tr>
											<th style={{ backgroundColor: '#fff' }}></th>
											<th style={{ backgroundColor: '#fff', textAlign: 'center' }} align='center'>Total</th>
											<th style={{ backgroundColor: '#fff', textAlign: 'center' }} align='center'>Confirmed</th>
											<th style={{ backgroundColor: '#fff', textAlign: 'center' }} align='center'>Checked</th>
										</tr>
									</thead>
									<tbody>
										<Link to={`/guests/${eventId}`} state={{list: all, event: state}}>
											<tr>
												<td>{all?.list_name}</td>
												<td align='center'>{all?.total}</td>
												<td align='center'>{all?.confirmed}</td>
												<td align='center'>{all?.checked_in}</td>
											</tr>
											{all ? (
											<tr className='progress-row'>
												<td colSpan={4}>
													<ProgressBar 
														variant='dark'
														max={100} 
														min={0} 
														isChild={false} 
														striped={true}
														now={(all.checked_in * 100) / all.total} 
														// label={`${((all.checked_in * 100) / all.total).toFixed(2)}%`}
													></ProgressBar>
												</td>
											</tr>
											) : null}
										</Link>
									</tbody>
								</table>
							</div>
							) : null}
							<div className="cp_table  mt-4">
								<table className="table table-borderless">
									<thead>
										<tr>
											<th style={{ backgroundColor: '#fff' }}>Guest Lists</th>
											<th style={{ backgroundColor: '#fff', textAlign: 'center' }} align='center'>Total</th>
											<th style={{ backgroundColor: '#fff', textAlign: 'center' }} align='center'>Confirmed</th>
											<th style={{ backgroundColor: '#fff', textAlign: 'center' }} align='center'>Checked</th>
										</tr>
									</thead>
									<tbody>
										{lists.map((list, i) => {
											return (
												<>
												<Link to={`/guests/${eventId}/${list.id}`} key={i} state={{list, event: state}}>
												<tr >
													<td>{list.list_name}</td>
													<td align='center'>{list.total}</td>
													<td align='center'>{list.confirmed}</td>
													<td align='center'>{list.checked_in}</td>
												</tr>
												</Link>
												<tr className='progress-row'>
													<td colSpan={4}>
														<ProgressBar 
															variant='dark'
															max={100} 
															min={0} 
															isChild={false}
															striped={true}
															now={(list.checked_in * 100) / list.total}
															// label={`${((list.checked_in * 100) / list.total).toFixed(2)}%`}
														></ProgressBar>
													</td>
												</tr>
												</>
											)
										})}
									</tbody>
								</table>
							</div>
							</>
							)}

						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default GuestLists;