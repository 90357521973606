
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Container from "react-bootstrap/Container";
import { apiGetEvent, apiGetEventCollaborate } from 'api/events';

import { Button, Spinner } from 'react-bootstrap';
import { setEventCardStyle, setHeaderTitle, setLastPage } from 'redux/interfaceManger';
import { Event } from 'types';
import { EventCardStyle, EventStatus } from 'constant';
import useWindowDimensions from 'hooks/useWindowDimensions';
import EventCardList from 'views/User/components/EventCardList';
import EventCardGrid from 'views/User/components/EventCardGrid';
import { batch } from 'react-redux';



const Dashboard = () => {
	const { logout } = useContext(AuthContext);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userData = useAppSelector(state => state.auth.userData);
	const eventCardStyle = useAppSelector(state => state.interfaceManger.eventCardStyle);
	const {height, width} = useWindowDimensions();
	const [loading, setLoading] = useState<boolean>(true);
	const [events, setEvents] = useState<Event[]>([]);
	const [reloadTable, setReloadTable] = useState<number>(0);



	useEffect(() => {
		dispatch(setHeaderTitle('Events'))
		dispatch(setLastPage(window.location.pathname))
	}, [])
	
	useEffect(() => {
		// set eventCardStyle to list for small screen
		if (width <= 768) {
			if (eventCardStyle !== EventCardStyle.LIST) {
				dispatch(setEventCardStyle(EventCardStyle.LIST))
			}
		} else {
			if (eventCardStyle !== EventCardStyle.GRID) {
				dispatch(setEventCardStyle(EventCardStyle.GRID))
			}
		}
	}, [width])

	useEffect(() => {

		(async () => {
			setLoading(true)
			const response = await apiGetEvent([EventStatus.ACTIVE, EventStatus.TESTING])
			const responseCollaborate = await apiGetEventCollaborate([EventStatus.ACTIVE, EventStatus.TESTING])

			// console.log({ response })
			batch(() => {
				setLoading(false)
				let e: Event[] = []
				if (response.success) {
					// setEvents(response.data)
					e = [...response.data];
				}
				if (responseCollaborate.success) {
					e = [...e, ...responseCollaborate.data]
				}

				setEvents(e)
			})
		})()

	}, [reloadTable])

	return (
		<>
			<Container fluid className='pt-4'>
				{(loading) ? <div className='text-center'><Spinner /></div> : (
					<>
						{(events.length > 0) ? (
							<>
								<div className='eventCardsList'>
									{events.map((event, i) => {
										if (eventCardStyle === EventCardStyle.LIST) {
											return <EventCardList key={i} event={event} />
										}

										return <EventCardGrid key={i} event={event} />
									})}
								</div>
							</>
						) : (
							<>
							<p className='text-muted text-center'>No Events Available for Check-in</p>
							<div className='mt-4 text-center'>
								<Button type="button" variant='dark' onClick={() => setReloadTable(prev => prev + 1)}>Refresh</Button>
							</div>
							</>
						)}
						
					</>
				)}
			</Container>
		</>
	);
};

export default Dashboard;