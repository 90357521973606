import React, { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// https://www.npmjs.com/package/@yudiel/react-qr-scanner
import { QrScanner } from '@yudiel/react-qr-scanner';
import { toast } from 'react-toastify';
import { Guest, GuestWithList, ScannerProps } from 'types';
import { setShowScanner } from 'redux/interfaceManger';
import { Button, Col, Form, Image, Offcanvas, Row } from 'react-bootstrap';
import { ScanTypes } from 'constant';
import { apiSearchAndCheckedInGuest, apiSearchAndTrackGuest } from 'api/guests';
import { batch } from 'react-redux';
import useWindowDimensions from 'hooks/useWindowDimensions';

type GuestDetailsPopupProps = {
	guestDetails: GuestWithList | null
	onClose: () => void
	openGuestDetails: (id: number) => void
}

const initialPopupTimeout = 5; // sec
let waitForNextScan: boolean = false;


const GuestDetailsPopup = ({guestDetails, onClose, openGuestDetails}: GuestDetailsPopupProps) => {
	const [timer, setTimer] = useState<number>(initialPopupTimeout);
	const { width } = useWindowDimensions();

	useEffect(() => {
		setTimer(initialPopupTimeout);
		const interval = setInterval(() => {
			setTimer((prev) => {
				const count = prev - 1;
				if (count === 0) {
					clearInterval(interval);
					onClose();
				}
				return count;
			});
		}, 1000);

		return () => clearInterval(interval);
	}, [guestDetails]);

	if (!guestDetails) {
		return <></>
	}

	return (
		<>
		<Offcanvas show={true} className="checkedIn" onHide={onClose} placement="end">
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Guest Checked-in</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div>
					<Row className="media">
						<Col xs={6}>
							<div className="cp_im_bx mb-3" style={{
								width: (width <= 576) ? 130 : 180,
								height: (width <= 576) ? 130 : 180,
								textAlign: 'center',
								backgroundColor: '#fbf8f8'
							}}>
								<Image rounded fluid
									src={(guestDetails.guest_image) ? guestDetails.guest_image : require('assets/images/placeholder-guest.png')}
									alt="guest image"
									style={{
										maxHeight: '100%'
									}}
								/>
							</div>
						</Col>
						
						<Col xs={6} className='media_top'>
							<div className="text-center cp_btn_save_dlt" style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%'
							}}>
								<h1>{timer}</h1>
							</div>
						</Col>
					</Row>

					<Row className="media">
						<Col>
							<div className='media_top'>
								<h5 className="mt-0">
									{(guestDetails.guest_name) ? guestDetails.guest_name : `${guestDetails.first_name || ''} ${guestDetails.last_name || ''}`}
									<span>{(guestDetails.company) ? guestDetails.company : ''}</span>
								</h5>
							</div>
						</Col>
					</Row>

					<div className="media">
						<div className="cp_text_bx  media-body">
							<Form onSubmit={e => e.preventDefault()}>
								<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
									<Form.Label column xs={4}>Status</Form.Label>
									<Col xs={8}>
										<Form.Control
											type={'text'}
											value={guestDetails.status.replace(/_/, ' ').toUpperCase()}
											disabled={true}
										/>
									</Col>
									<hr className='dvdr' />
								</Form.Group>
								
								<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
									<Form.Label column xs={4}>Guest List</Form.Label>
									<Col xs={8}>
										<Form.Control
											type={'text'}
											value={guestDetails.guest_list?.list_name}
											disabled={true}
										/>
									</Col>
									<hr className='dvdr' />
								</Form.Group>

								{guestDetails.notification && (
								<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
									<Form.Label column xs={4}>Notification</Form.Label>
									<Col xs={8}>
										<Form.Control
											type={'text'}
											value={guestDetails.notification}
											disabled={true}
											style={{
												color: '#dc3545'
											}}
										/>
									</Col>
									<hr className='dvdr' />
								</Form.Group>
								)}
							</Form>
						</div>
					</div>

					<div className="text-center mt-5">
						<Button type='button'  variant='outline-dark' onClick={() => {
							openGuestDetails(guestDetails.id)
						}}>Guest Details</Button>
					</div>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
		</>
	)
}

const Scanner = ({eventId, onResult, openGuestDetails}: ScannerProps) => {
	const dispatch = useAppDispatch();
	const scanType = useAppSelector(state => state.auth.scanType);
	const deviceName = useAppSelector(state => state.auth.deviceName);
	const [showGuestDetails, setShowGuestDetails] = useState<boolean>(false);
	const [guestDetails, setGuestDetails] = useState<GuestWithList | null>(null);

	const isMobile = window.innerWidth <= 768;

	useEffect(() => {
		waitForNextScan = false;
	}, []);

	const onDecode = async (result: string) => {
		if (waitForNextScan) {
			return;
		}
		console.log('onDecode', result)

		if (scanType === ScanTypes.FIND) {
			onResult(result)
		}
		else if (scanType === ScanTypes.CHECK_IN) {
			if (eventId) {

				waitForNextScan = true;
				setTimeout(() => {
					waitForNextScan = false;
				}, 5000)

				const response = await apiSearchAndCheckedInGuest(eventId, {
					q: result,
					device_name: deviceName,
				})
	
				if (response.success) {
					batch(() => {
						setGuestDetails(response.data)
						setShowGuestDetails(true)
					})
				}
				else {
					toast.error(response.message)
				}
			}
		}
		else if (scanType === ScanTypes.TRACK) {
			if (eventId) {

				waitForNextScan = true;
				setTimeout(() => {
					waitForNextScan = false;
				}, 5000)

				const response = await apiSearchAndTrackGuest(eventId, {
					q: result,
					device_name: deviceName,
				})
	
				if (response.success) {
					let checkGuestName = 'Guest';
					if (response.data.guest_name) {
						checkGuestName = response.data.guest_name;
					}
					else if (response.data.first_name || response.data.last_name) {
						checkGuestName = `${response.data.first_name || ''} ${response.data.last_name || ''}`;
					}

					toast.success(`${checkGuestName} checked`, {
						toastId: response.data.id
					});
				}
				else {
					toast.error(response.message)
				}
			}
		}
	}

	const onError = (error: any) => {
		console.log(error?.message)
	}

	return (
		<div className='' style={{
			// height: 'calc(100vh - 160px)',
			height: '100%',
			background: '#2C292C'
		}}>
			{!showGuestDetails ? (
			<div style={{
				width: (isMobile) ? window.innerWidth : (window.innerHeight - 120) / 1.4,
				height: (isMobile) ? window.innerWidth : (window.innerHeight - 120) / 1.4,
				// width: 400,
				// height: 400,
				marginLeft: 'auto',
				marginRight: 'auto'
			}}>
				<QrScanner
					onDecode={onDecode}
					onError={onError}
				/>
				
				<div className="d-grid gap-2 mt-4">
					<Button className='text-center' variant='light' onClick={e => dispatch(setShowScanner(false))}>Close Scanner</Button>
					{/* <Button className='text-center' variant='light' onClick={e => onDecode('a.enim@hotmail.edu')}>Test Check</Button> */}
				</div>
			</div>
			) : (
				<GuestDetailsPopup guestDetails={guestDetails} onClose={() => setShowGuestDetails(false)} openGuestDetails={openGuestDetails} />
			)}

		</div>
	);
};

export default memo(Scanner);