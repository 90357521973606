import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toast } from 'react-toastify';
import { batch } from 'react-redux';
import { unsetActiveEvent } from 'redux/eventSlice';
import { setHeaderTitle } from 'redux/interfaceManger';
import { Event, Team, TeamMember, TeamUpdateUserData } from 'types';
import { apiDeleteTeamMember, apiGetTeam, apiResendInviteTeam, apiUpdateTeamMember } from 'api/team';
import { apiGetEvent } from 'api/events';
import { EventStatus, TeamAccess, TeamRole } from 'constant';
import Select from 'react-select'
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import dayjs from 'dayjs';

const MyTeam = () => {
	const dispatch = useAppDispatch();
	const userData = useAppSelector(state => state.auth.userData);
	const [events, setEvents] = useState<Event[]>([]);
	const [team, setTeam] = useState<Team[]>([]);
	const [editIds, setEditIds] = useState<number[]>([]);
	const [deletingMember, setDeletingMember] = useState<Team | null>(null);
	const [resending, setResending] = useState<boolean>(false);

	useEffect(() => {
		batch(() => {
			dispatch(unsetActiveEvent())
			dispatch(setHeaderTitle('Team'))
		})
	}, [])

	useEffect(() => {
		getTeamMembers();
	}, [])

	useEffect(() => {

		(async () => {
			const response = await apiGetEvent([EventStatus.ACTIVE, EventStatus.TESTING])

			if (response.success) {
				setEvents(response.data)
			}
		})()

	}, [])

	const getTeamMembers = async () => {
		const response = await apiGetTeam()

		if (response.success) {
			setTeam(response.data)
		}
	}

	const getAvatarName = (data: TeamMember) => {

		if (data.name && data.name !== '') {
			let s = '';
			data.name.split(' ').map(t => {
				if (s.length < 2) {
					s += t[0]
				}
			})
			return s.toUpperCase();
		}
		else if (data.email) {
			return data.email[0].toUpperCase();
		}
		return '-';
	}

	const changeMemberData = (id: number, key: string, value: any) => {
		 // update member object by specific id index
		 setTeam([...team.map((t, i) => {
			if (t.id === id) {
				// if (t.hasOwnProperty(key)) {
				// 	t[key as keyof Team] = value;
				// }

				if (key === 'role') {
					t.role = value;
					if (value === TeamRole.ASSISTANT) {
						t.access = TeamAccess.APP
					}
				}
				else if (key === 'access') {
					t.access = value;
				}
				else if (key === 'events') {
					t.events = value;
				}
			}
			return t;
		 })])
	}
	
	const updateMember = async (id: number, data: TeamUpdateUserData) => {
		const response = await apiUpdateTeamMember(id, data)

		if (response.success) {
			batch(() => {
				setEditIds([...editIds.filter(i => i !== id)])
				getTeamMembers();
			})
		} else {
			toast.error(response.message)
		}
	}

	const resentInvite = async (id: number) => {
		setResending(true)
		const response = await apiResendInviteTeam(id)

		setResending(false)
		if (response.success) {
			getTeamMembers();
			toast.success(response.message)
		} else {
			toast.error(response.message)
		}
	}

	const deleteMember = async (id: number) => {
		const response = await apiDeleteTeamMember(id)

		if (response.success) {
			batch(() => {
				setDeletingMember(null)
				getTeamMembers();
			})
		} else {
			toast.error(response.message)
		}
	}


	if (!userData) {
		return (<div>Loading...</div>)
	}

	return (
		<div className="cp_tabs_contentbx">
			<div className="">
				<Row>
					<Col>
						<h4 className=''>My Team ({team.length})</h4>
					</Col>
					<Col className='text-end'>
						<Link to={`/team/add`} className='btn btn-dark'>
							<i className="ico">
								<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7 1V13M1 7H13" stroke="#FFFFFF" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</i>
							Add team member
						</Link>
					</Col>
				</Row>
			</div>
			
			<div className="table-responsive">
				<table className='table table-borderless' id='teams-table'>
					<thead>
						<tr>
							<th className='text-muted'></th>
							<th className='text-muted'>Name / Username</th>
							<th className='text-muted'></th>
							<th className='text-muted ps-4'>Role</th>
							<th className='text-muted ps-4'>Access</th>
							<th className='text-muted' style={{width: '150px'}}>Last Logged In</th>
							<th className='text-muted ps-4' style={{ width: '30%' }}>Events</th>
							<th className='text-muted' style={{minWidth: 136}}></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								{userData.image ? (
									<Image 
										src={userData.image} 
										roundedCircle 
										fluid
										width={44}
									/>
								) : (
									<div className='table-avatar'>{getAvatarName({
										id: userData.id,
										name: userData.name,
										email: userData.email,
										image: userData.image,
										last_logged_in: '',
									})}</div>
								)}
							</td>
							<td>{userData.name} <span style={{ color: '#aaa' }}>(You)</span></td>
							<td></td>
							<td>
								<Form.Select disabled>
									<option value='ADMIN'>Admin</option>
								</Form.Select>
							</td>
							<td>
								<Form.Select disabled>
									<option value='ALL'>All Access</option>
								</Form.Select>
							</td>
							<td style={{width: '150px'}}></td>
							<td>
								<Form.Select disabled>
									<option value='ALL'>All Events</option>
								</Form.Select>
							</td>
							<td style={{minWidth: 136}}></td>
						</tr>

						{team.length === 0 && (
							<tr>
								<td colSpan={7} className='text-center text-muted'>You have not added any members yet!</td>
							</tr>
						)}

						{team.map((member, i) => {
							const isEdit = editIds.find(id => id === member.id) ? true : false;
							return (
								<tr key={i}>
									<td>
										{(member.member && member.member.image) ? (
											<Image 
												src={member.member.image} 
												roundedCircle 
												fluid
												width={44}
											/>
										) : (
											<div className='table-avatar'>{getAvatarName(member.member)}</div>
										)}
									</td>
									<td className={` ${member.is_accepted === 0 ? 'text-muted' : ''}`} >{member.member.name || member.member.email}</td>
									<td>
										{member.is_accepted === 0 ? (<Button type='button' variant='link' className='text-dark' disabled={resending} onClick={() => resentInvite(member.id)}>Resent Invitation</Button>) : null}
									</td>
									<td>
										<Form.Select value={member.role} disabled={!isEdit} onChange={e => changeMemberData(member.id, 'role', e.target.value)}>
											<option value={TeamRole.MANAGER}>Manager</option>
											<option value={TeamRole.ASSISTANT}>Assistant</option>
										</Form.Select>
									</td>
									<td>
										<Form.Select value={member.access} disabled={!isEdit} onChange={e => changeMemberData(member.id, 'access', e.target.value)}>
											{member.role !== TeamRole.ASSISTANT ? <option value={TeamAccess.BACKEND}>Event Management & Check-In App</option> : null}
											<option value={TeamAccess.APP}>Check-In App</option>
										</Form.Select>
									</td>

									<td style={{width: '150px'}}>{(member.member.last_logged_in) ? (
										<div className='text-muted small'>
											<div>{dayjs(member.member.last_logged_in).format('MMM DD, YYYY')}</div>
											<div>{dayjs(member.member.last_logged_in).format('h:mm a')}</div>
										</div>
									) : '-'}</td>

									<td>
									<Select
										isMulti={true}
										className="react-select-container"
										classNamePrefix="react-select"
										isDisabled={!isEdit}
										onChange={e => {
											changeMemberData(member.id, 'events', [...e.map(event => {
												return {
													event_id: event.value,
													member_id: member.id
												}
											})])
										}}
										value={[...events.filter(e => {
											return member.events.find(me => me.event_id == e.id)
										}).map(e => {
											return {
												value: e.id,
												label: e.name,
												image: (e.logo) ? e.logo : require('assets/images/placeholder-event.jpg'),
											}
										})]}
										options={[...events.map(e => {
											return {
												value: e.id,
												label: e.name,
												image: (e.logo) ? e.logo : require('assets/images/placeholder-event.jpg'),
											}
										})]}
										formatOptionLabel={event => (
											<div className="event-option">
											  <img src={event.image} alt={event.label} style={{
												width: '20px',
												height: '20px',
												borderRadius: '50%'
											  }} />
											  {` `}
											  <span>{event.label}</span>
											</div>
										)}
									/> 
									</td>

									<td className='text-end' style={{minWidth: 136}}>
										{!isEdit ? (
											<button 
												type="button" 
												className="btn btn-theme-delete"
												onClick={e => setEditIds([...editIds, member.id])}
												style={{
													minWidth: '63px',
													width: 'auto',
													height: '44px',
													border: '1px solid #CFC8CF'
												}}
											>Edit</button>
										) : (
											<button 
												type="button" 
												className="btn btn-dark"
												onClick={() => updateMember(member.id, {
													role: member.role,
													access: member.access,
													events: member.events.map(e => e.event_id)
												})}
												style={{
													minWidth: '63px',
													width: 'auto',
													height: '44px',
													border: '1px solid #CFC8CF'
												}}
											>Save</button>
										)}


										{` `}
										<Button variant="" className='btn-theme-delete' 
											 onClick={() => setDeletingMember(member)}
											style={{
												width: '44px',
												height: '44px',
												border: '1px solid #CFC8CF'
											}}
										>
											<i className="ico">
												<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M10.1 3.8V3.24C10.1 2.45593 10.1 2.06389 9.94741 1.76441C9.81319 1.50099 9.59901 1.28681 9.33559 1.15259C9.03611 1 8.64407 1 7.86 1H6.74C5.95593 1 5.56389 1 5.26441 1.15259C5.00099 1.28681 4.78681 1.50099 4.65259 1.76441C4.5 2.06389 4.5 2.45593 4.5 3.24V3.8M5.9 7.65V11.15M8.7 7.65V11.15M1 3.8H13.6M12.2 3.8V11.64C12.2 12.8161 12.2 13.4042 11.9711 13.8534C11.7698 14.2485 11.4485 14.5698 11.0534 14.7711C10.6042 15 10.0161 15 8.84 15H5.76C4.58389 15 3.99583 15 3.54662 14.7711C3.15148 14.5698 2.83022 14.2485 2.62889 13.8534C2.4 13.4042 2.4 12.8161 2.4 11.64V3.8" stroke="#2C292C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
												</svg>
											</i>
										</Button>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>


			{deletingMember && (
					<Modal show={true} className="deleteEventModal" onHide={() => setDeletingMember(null)} aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>
							Delete Event!
							</Modal.Title>
						</Modal.Header>
		
						<Modal.Body className='text-center'>
							<div>
								<p>
									Are you sure you want to remove <strong>{(deletingMember.member.name != null && deletingMember.member.name !== '') ? deletingMember.member.name : deletingMember.member.email}</strong> from your team?
								</p>
	
								<h4 className="mb-5">
									This action cannot be undone
								</h4>
							</div>
	
							<div className={`d-grid actionBtns`}>
								<Button variant="primary" className={`btn-steps cp_delete_btn`} style={{ width: '100%' }} onClick={() => deleteMember(deletingMember.id)}>
									<span className={`step3 d-flex align-items-center justify-content-center`}>
										Remove Team Member
									</span>
								</Button>
							</div>
						</Modal.Body>
					</Modal>
				)}
		</div>
	)

}

export default MyTeam;