import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { DomainEmails, EmailTemplate, EmailTemplateStatus, EmailTemplateType } from 'types';
import { ButtonGroup, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { EmailTemplateStatuses, EmailTemplateTypes } from 'constant';
import { batch } from 'react-redux';
import { apiCreateTemplate, apiGetEmailSenders, apiUpdateTemplate } from 'api/emailTemplate';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


type TemplateEditPopupProps = {
	eventId: number | string;
	template: EmailTemplate;
	isOpen: boolean;
	setIsOpen: (isOpen: EmailTemplate | null) => void;
	onUpdate: (template: EmailTemplate) => void;
}

const TemplateEditPopup = ({ eventId, template, isOpen, setIsOpen, onUpdate }: TemplateEditPopupProps) => {
	const navigate = useNavigate();
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [type, setType] = useState<EmailTemplateType>(template.type);
	const [status, setStatus] = useState<EmailTemplateStatus>((template.status && [EmailTemplateStatuses.DRAFT, EmailTemplateStatuses.ACTIVE].includes(template.status)) ? template.status : EmailTemplateStatuses.DRAFT);
	const [name, setName] = useState<string>(template.name)
	const [senderName, setSenderName] = useState<string>(template.sender_name)
	const [senderEmail, setSenderEmail] = useState<string>(template.sender_email)
	const [senderReplyEmail, setSenderReplyEmail] = useState<string>(template.sender_reply_emails || '')
	const [subject, setSubject] = useState<string>(template.subject)
	const [senderEmails, setSenderEmails] = useState<DomainEmails[]>([]);

	useEffect(() => {
		(async () => {
			if (eventId) {
				const response = await apiGetEmailSenders(eventId)
				
				if (response.success) {
					setSenderEmails(response.data)
				}
			}
		})();
	}, [])

	useEffect(() => {
		if (senderEmail && senderEmail === 'NEW_DOMAIN') {
			navigate('/domains')
		}
	}, [senderEmail])

	const handleSubmit = async () => {
		if (template && template.id) {
			const data: EmailTemplate = {
				name,
				type,
				status,
				sender_name: senderName,
				sender_email: senderEmail,
				sender_reply_emails: senderReplyEmail,
				subject: subject,
			}
			console.log({data})
			batch(() => {
				setErrMsg('');
				setIsLoading(true);
			})
	
			const response = await apiUpdateTemplate(eventId, template.id, data);
			setIsLoading(false);
			if (response.success) {
				toast.success(response.message)
				onUpdate(response.data)
			} else {
				setErrMsg(response.message)
			}
		}
	}

	return (
		<>
			<Modal show={isOpen} className="createNewEmailTemplateModal" size='lg' onHide={() => setIsOpen(null)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>Edit Template</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{errMsg && <div className="alert alert-danger">{errMsg}</div>}
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Email template name (internal)</Form.Label>
								<Form.Control type="text" placeholder="" value={name} onChange={e => setName(e.target.value)} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Status</Form.Label>
								<Form.Select aria-label="Default select example" value={status} onChange={e => setStatus(e.target.value as EmailTemplateStatus)}>
									<option value={EmailTemplateStatuses.DRAFT}>Draft</option>
									<option value={EmailTemplateStatuses.ACTIVE}>Active</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Sender name</Form.Label>
								<Form.Control type="text" placeholder="" value={senderName} onChange={e => setSenderName(e.target.value)} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Sender email address</Form.Label>
								<Form.Select aria-label="Default select example" value={senderEmail} onChange={e => setSenderEmail(e.target.value)}>
									{senderEmails.map(e => {
										return (
											<option key={e.email} value={e.email}>{e.email} ({(e.is_default === 1) ? 'Default' : 'Your Domain'})</option>
										)
									})}
									<option value={'NEW_DOMAIN'}>ADD NEW EMAIL ADDRESS / DOMAIN</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col></Col>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Reply-to email address</Form.Label>
								<Form.Control type="email" placeholder="" value={senderReplyEmail} onChange={e => setSenderReplyEmail(e.target.value)} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="eventCreateModal">
								<Form.Label>Email subject line</Form.Label>
								<Form.Control type="text" placeholder="" value={subject} onChange={e => setSubject(e.target.value)} />
							</Form.Group>
						</Col>
					</Row>


				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setIsOpen(null)} style={{ width: 'auto', flexGrow: 1, padding: '1rem' }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSubmit} style={{ width: 'auto', flexGrow: 1.8, padding: '1rem' }} disabled={isLoading}>
						<div className='d-flex align-items-center justify-content-between'>
							<span>Update Email Template</span>
							<span className="arrow">
								<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default TemplateEditPopup;