import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';

const Invoices = () => {
	const dispatch = useAppDispatch();



	return (
		<>
			<table className="table table-borderless">
				<thead>
					<tr>
						<th>Invoice ID</th>
						<th>Date Created</th>
						<th>Amount</th>
						<th>Status</th>
						<th className='cp_btn_bx'></th>

					</tr>
				</thead>
				<tbody>
					<tr>
						<td colSpan={5} className='text-center text-muted'>No invoice available!</td>
					</tr>

					{/* <tr>
						<td>129041234 512 A <svg className='ms-3' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10 14V10M10 6H10.01M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z" stroke="#8C838C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
						</td>

						<td align="left">2023-03-23</td>
						<td align="left">$ 450</td>
						<td align="left">Paid</td>

						<td className='cp_btn_bx'>
							<a className="cp_btn_download" href="javascript:;"><span>Download</span> <i><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.5 1.93648C13.5923 3.14681 15 5.40901 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 5.40901 2.4077 3.14681 4.5 1.93648M5.2 8L8 10.8M8 10.8L10.8 8M8 10.8V1" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
							</svg></i>
							</a>
						</td>
					</tr> */}

				</tbody>
			</table>
		</>
	);
};

export default Invoices;