

import React, { Ref, useContext, useEffect, useState, useRef, ReactPropTypes } from 'react';
import { AuthContext } from 'AuthContext';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import Offcanvas from 'react-bootstrap/Offcanvas';
import dayjs from 'dayjs'
import { LongPressPointerHandlers, LongPressResult, useLongPress } from 'hooks/use-long-press';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { apiGetEventPrinterLabels } from 'api/printers';
import { setEventPrinterLabels } from 'redux/eventPrinterLabels';
import { setFooterMessage, setHeaderTitle, toggleAddGuestPopup } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { CheckedInLogs, Event, EventDataField, Guest, GuestList, GuestStatusType } from 'types';
import { apiGetGuestLists } from 'api/guestLists';
import { Alert, Image, Table } from 'react-bootstrap';
import { GuestStatus, TeamRole } from 'constant';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { apiDeleteGuestImage } from 'api/guests';

type GuestDetailsPopupTypes = {
	event: Event
	guestDetails: Guest | null
	setGuestDetails: (guestDetails: Guest | null) => void
	isEditGuestDetails: boolean
	setIsEditGuestDetails: (isEditGuestDetails: boolean) => void
	setConfirmGuestDelete: (confirmGuestDelete: number | null) => void
	lists: GuestList[]
	eventFields: EventDataField[]
	guestCheckInLogs: CheckedInLogs[]
	handleSaveEditGuest: (additionalData?: any) => void
	handlePrint: (guestId: number) => void
	revertCheckInGuest: LongPressResult<LongPressPointerHandlers<Element>, unknown>
	checkInGuest: (guestId: number) => void
	isNewGuest: boolean
}


const LargeScreenDesign = ({
	event,
	guestDetails,
	setGuestDetails,
	isEditGuestDetails,
	setIsEditGuestDetails,
	setConfirmGuestDelete,
	lists,
	eventFields,
	guestCheckInLogs,
	handleSaveEditGuest,
	handlePrint,
	revertCheckInGuest,
	checkInGuest,
	isNewGuest = false,
}: GuestDetailsPopupTypes) => {
	const isOnline = useAppSelector(s => s.interfaceManger.isOnline);
	const [img, setImg] = useState<File | null>(null);
	const imgURL = (img !== null) ? URL.createObjectURL(img) : null;

	if (guestDetails === null) {
		return null;
	}

	let hasEditAccess = false;
	if (event.hasOwnProperty('member_role')) {
		if (event.member_role === TeamRole.MANAGER) {
			hasEditAccess = true;
		}
	} else {
		hasEditAccess = true;
	}

	const handleDeleteGuestImage = async (additionalData?: any) => {
		if (event && guestDetails) {

			const response = await apiDeleteGuestImage(event.id, guestDetails.id)

			if (response.success) {
				setGuestDetails({
					...guestDetails,
					guest_image: null,
				})
			}

		}
	}

	return (
		<>
			<div className="media">
				<div className="media-left col-sm-3">
					<div className="cp_im_bx mb-3">
						<Image rounded fluid
							src={(imgURL) ? imgURL : (guestDetails.guest_image && isOnline) ? guestDetails.guest_image : require('assets/images/placeholder-guest.jpg')}
							alt="guest image"
							style={{
								maxHeight: '100%'
							}}
						/>
						{(isEditGuestDetails) ? (
							<>
								<div className="imgUploadBtn">
									<input
										type="file"
										name="file"
										placeholder="Upload File"
										accept="image/*"
										required
										onChange={e => {
											if (e.target.files !== null) {
												// console.log(e.target.files[0]);
												// TODO: store file in separate state and add in  data when update api
												setImg(e.target.files[0]);
											}
										}}
									/>
									
									<svg width="60" height="60" viewBox="10 5 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g >
											<path d="M28.4 14L30 15.6M22 22L22.5106 20.1279C22.5439 20.0058 22.5605 19.9447 22.5861 19.8878C22.6088 19.8372 22.6367 19.7892 22.6693 19.7444C22.7061 19.6939 22.7508 19.6492 22.8404 19.5596L26.9737 15.4263C27.0529 15.3471 27.0925 15.3075 27.1382 15.2926C27.1784 15.2796 27.2216 15.2796 27.2618 15.2926C27.3075 15.3075 27.3471 15.3471 27.4263 15.4263L28.5737 16.5737C28.6529 16.6529 28.6925 16.6925 28.7074 16.7382C28.7204 16.7784 28.7204 16.8216 28.7074 16.8618C28.6925 16.9075 28.6529 16.9471 28.5737 17.0263L24.4404 21.1596C24.3508 21.2492 24.3061 21.2939 24.2556 21.3307C24.2108 21.3633 24.1628 21.3912 24.1122 21.4139C24.0553 21.4395 23.9942 21.4561 23.8721 21.4894L22 22Z" 
											stroke="#FFFFFF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
										</g>
									</svg>
								</div>
							</>
						) : null}
					</div>

					{(isEditGuestDetails && guestDetails.guest_image) ? (
						<Button type='button' 
							variant='dark' className='mb-3'
							style={{
								backgroundColor: '#dc3545',
								color: '#fff'
							}}
							onClick={handleDeleteGuestImage}
						>Remove Image</Button>
					) : null}

					<Button className="cp_confrm_btn mb-5" >{guestDetails.status.replace(/_/, ' ').toUpperCase()}</Button>
				</div>

				<div className="cp_text_bx  media-body">
					<Form onSubmit={e => e.preventDefault()} className='mb-4'>
						<div className="media_top d-flex justify-content-between">
							<h5 className="mt-0">
								{(guestDetails.guest_name) ? guestDetails.guest_name : `${guestDetails.first_name || ''} ${guestDetails.last_name || ''}`}
								<span>{(guestDetails.company) ? guestDetails.company : ''}</span>
							</h5>
							<div className="cp_btn_save_dlt">
								{isEditGuestDetails ? (
									<>
										{(!isNewGuest) && (
										<Button className="btn-delete" onClick={() => setConfirmGuestDelete(guestDetails.id)}>
											<i>
												<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g filter="url(#filter0_i_819_876)">
														<rect width="40" height="40" rx="8" fill="#D31111" />
														<path d="M22.8002 15.8V15.24C22.8002 14.4559 22.8002 14.0639 22.6476 13.7644C22.5134 13.501 22.2992 13.2868 22.0358 13.1526C21.7363 13 21.3443 13 20.5602 13H19.4402C18.6561 13 18.2641 13 17.9646 13.1526C17.7012 13.2868 17.487 13.501 17.3528 13.7644C17.2002 14.0639 17.2002 14.4559 17.2002 15.24V15.8M18.6002 19.65V23.15M21.4002 19.65V23.15M13.7002 15.8H26.3002M24.9002 15.8V23.64C24.9002 24.8161 24.9002 25.4042 24.6713 25.8534C24.47 26.2485 24.1487 26.5698 23.7536 26.7711C23.3044 27 22.7163 27 21.5402 27H18.4602C17.2841 27 16.696 27 16.2468 26.7711C15.8517 26.5698 15.5304 26.2485 15.3291 25.8534C15.1002 25.4042 15.1002 24.8161 15.1002 23.64V15.8" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
													</g>
													<defs>
														<filter id="filter0_i_819_876" x="0" y="0" width="40" height="41" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
															<feFlood floodOpacity="0" result="BackgroundImageFix" />
															<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
															<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
															<feOffset dy="1" />
															<feGaussianBlur stdDeviation="0.5" />
															<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
															<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0" />
															<feBlend mode="normal" in2="shape" result="effect1_innerShadow_819_876" />
														</filter>
													</defs>
												</svg>
											</i>
										</Button>
										)}

										<Button className={`btn_save`} onClick={() => handleSaveEditGuest({
											guest_image: img,
										})}>Save</Button>
									</>
								) : (
									<>
									{hasEditAccess && <Button className={`btn_edit`} onClick={e => setIsEditGuestDetails(true)}>Edit</Button>}
									</>
								)}
							</div>
						</div>


						<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
							<Form.Label column sm={4}>Guest List</Form.Label>
							<Col sm={8}>
								<Form.Select
									value={guestDetails?.guest_list_id}
									disabled={!isEditGuestDetails}
									onChange={e => setGuestDetails({
										...guestDetails,
										guest_list_id: parseInt(e.target.value)
									})}
								>
									{lists.map((list, i) => {
										return (
											<option key={i} value={list.id?.toString()}>{list.list_name}</option>
										)
									})}
								</Form.Select>
							</Col>
							<hr className='dvdr' />
						</Form.Group>

						{eventFields.map((field, i) => {

							if (field.field === 'status') {
								return (
									<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
										<Form.Label column sm={4}>Status</Form.Label>
										<Col sm={8}>
											<Form.Select
												value={guestDetails.status}
												disabled={!isEditGuestDetails}
												onChange={e => setGuestDetails({
													...guestDetails,
													status: e.target.value as GuestStatusType
												})}
												style={{
													lineHeight: '16px'
												}}
											>
												{([GuestStatus.CHECKED_IN, GuestStatus.CHECKED_OUT].includes(guestDetails.status)) ? (
													<option value={guestDetails.status}>Checked In</option>
												) : (
													<>
													<option value={GuestStatus.UN_CONFIRMED}>Unconfirmed</option>
													<option value={GuestStatus.CONFIRMED}>Confirmed</option>
													<option value={GuestStatus.CANCELLED}>Cancelled</option>
													</>
												)}
											</Form.Select>
										</Col>
										<hr className='dvdr' />
									</Form.Group>
								)
							} else {
								let val = (guestDetails.hasOwnProperty(field.field) && guestDetails[field.field as keyof Guest] !== undefined) ? guestDetails[field.field as keyof Guest]?.toString() : '';
	
								if (val !== undefined) {
									if (field.type === 'number') {
										val = val.replace(/\D/g, '');
									}
								}
	
								if (!isEditGuestDetails && (val === '' || val === undefined || val === null)) {
									return (<></>);
								} else {
	
									return (
										<Form.Group key={i} as={Row} className="mb-0" controlId="formHorizontalEmail">
											<Form.Label column sm={4}>{field.display_name}</Form.Label>
											<Col sm={8}>
												{(field.type === 'dropdown') ? (
													<></>
												) : (
													<Form.Control
														type={(!['date', 'time'].includes(field.type)) ? field.type : 'text'}
														value={val}
														disabled={!isEditGuestDetails}
														onChange={e => setGuestDetails({
															...guestDetails,
															[field.field]: e.target.value
														})}
														inputMode={(field.type === 'number') ? 'numeric' : 'text'}
														style={{
															color: (field.field === 'notification') ? '#dc3545' : '#4D474D'
														}}
													/>
												)}
											</Col>
											<hr className='dvdr' />
										</Form.Group>
									)
								}
							}


						})}

						{isEditGuestDetails ? (
						<div className="media_top text-end mt-2">
							<div className='cp_btn_save_dlt'>
							<Button className={`btn_save`} onClick={() => handleSaveEditGuest({
								guest_image: img,
							})}>Save</Button>
							</div>
						</div>
						) : null}
					</Form>
				</div>
			</div>

			<div className="media mb-3">
				<div className="media-left col-sm-3">
					{event.has_badge_printing === 1 && (
					<Button className={`cp_print_btn ${isEditGuestDetails ? 'hide' : ''}`} onClick={() => handlePrint(guestDetails.id)}>
						<i>
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.8 5V3.56C13.8 2.66392 13.8 2.21587 13.6256 1.87362C13.4722 1.57256 13.2274 1.32779 12.9264 1.17439C12.5841 1 12.1361 1 11.24 1H6.76C5.86392 1 5.41587 1 5.07362 1.17439C4.77256 1.32779 4.52779 1.57256 4.37439 1.87362C4.2 2.21587 4.2 2.66392 4.2 3.56V5M4.2 13.8C3.45602 13.8 3.08403 13.8 2.77883 13.7182C1.95061 13.4963 1.3037 12.8494 1.08178 12.0212C1 11.716 1 11.344 1 10.6V8.84C1 7.49587 1 6.82381 1.26158 6.31042C1.49168 5.85883 1.85883 5.49168 2.31042 5.26158C2.82381 5 3.49587 5 4.84 5H13.16C14.5041 5 15.1762 5 15.6896 5.26158C16.1412 5.49168 16.5083 5.85883 16.7384 6.31042C17 6.82381 17 7.49587 17 8.84V10.6C17 11.344 17 11.716 16.9182 12.0212C16.6963 12.8494 16.0494 13.4963 15.2212 13.7182C14.916 13.8 14.544 13.8 13.8 13.8M11.4 7.8H13.8M6.76 17H11.24C12.1361 17 12.5841 17 12.9264 16.8256C13.2274 16.6722 13.4722 16.4274 13.6256 16.1264C13.8 15.7841 13.8 15.3361 13.8 14.44V13.16C13.8 12.2639 13.8 11.8159 13.6256 11.4736C13.4722 11.1726 13.2274 10.9278 12.9264 10.7744C12.5841 10.6 12.1361 10.6 11.24 10.6H6.76C5.86392 10.6 5.41587 10.6 5.07362 10.7744C4.77256 10.9278 4.52779 11.1726 4.37439 11.4736C4.2 11.8159 4.2 12.2639 4.2 13.16V14.44C4.2 15.3361 4.2 15.7841 4.37439 16.1264C4.52779 16.4274 4.77256 16.6722 5.07362 16.8256C5.41587 17 5.86392 17 6.76 17Z" stroke="#2C292C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</i>
						Print
					</Button>
					)}
				</div>
				<div className="cp_text_bx  media-body">
					{(guestDetails.status === GuestStatus.CHECKED_IN) ? (
						<>
							<Button className={`checkIn_btn`} {...revertCheckInGuest()} disabled={isEditGuestDetails}>
								<div className='checkinPress'>
									<span>Checked-In</span>
									<span className='small'>Press & Hold to Revert</span>
								</div>
							</Button>
							{/* <Button className={`checkIn_btn press mt-3`} onClick={e => checkOutGuest(guestDetails.id)} disabled={isEditGuestDetails}>
								<div className='checkIn'>
									<span>Check-Out</span>
								</div>
							</Button> */}
						</>
					) : (
						<Button className={`checkIn_btn press`} onClick={e => checkInGuest(guestDetails.id)} disabled={isEditGuestDetails}>
							<div className='checkIn'>
								<span>Check-In</span>
							</div>
						</Button>
					)}
				</div>
			</div>
		</>
	)
}

const SmallScreenDesign = ({
	event,
	guestDetails,
	setGuestDetails,
	isEditGuestDetails,
	setIsEditGuestDetails,
	setConfirmGuestDelete,
	lists,
	eventFields,
	guestCheckInLogs,
	handleSaveEditGuest,
	handlePrint,
	revertCheckInGuest,
	checkInGuest,
	isNewGuest = false,
}: GuestDetailsPopupTypes) => {
	const isOnline = useAppSelector(s => s.interfaceManger.isOnline);
	const [img, setImg] = useState<File | null>(null);
	const imgURL = (img !== null) ? URL.createObjectURL(img) : null;
	const { width } = useWindowDimensions();

	if (guestDetails === null) {
		return null;
	}

	let hasEditAccess = false;
	if (event.hasOwnProperty('member_role')) {
		if (event.member_role === TeamRole.MANAGER) {
			hasEditAccess = true;
		}
	} else {
		hasEditAccess = true;
	}

	const handleDeleteGuestImage = async (additionalData?: any) => {
		if (event && guestDetails) {

			const response = await apiDeleteGuestImage(event.id, guestDetails.id)

			if (response.success) {
				setGuestDetails({
					...guestDetails,
					guest_image: null,
				})
			}

		}
	}

	return (
		<>
			<div >
				<Row className="media">
					<Col xs={7}>
						<div className="cp_im_bx mb-3" style={{
							width: (width <= 576) ? 130 : 180,
							height: (width <= 576) ? 130 : 180,
							textAlign: 'center',
							backgroundColor: '#fbf8f8'
						}}>
							<Image rounded fluid
								src={(imgURL) ? imgURL : (guestDetails.guest_image && isOnline) ? guestDetails.guest_image : require('assets/images/placeholder-guest.jpg')}
								alt="guest image"
								style={{
									maxHeight: '100%'
								}}
							/>
							{(isEditGuestDetails) ? (
								<>
									<div className="imgUploadBtn">
										<input
											type="file"
											name="file"
											placeholder="Upload File"
											accept="image/*"
											required
											onChange={e => {
												if (e.target.files !== null) {
													// console.log(e.target.files[0]);
													// TODO: store file in separate state and add in  data when update api
													setImg(e.target.files[0]);
												}
											}}
										/>
										
										<svg width="60" height="60" viewBox="10 5 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
											<g >
												<path d="M28.4 14L30 15.6M22 22L22.5106 20.1279C22.5439 20.0058 22.5605 19.9447 22.5861 19.8878C22.6088 19.8372 22.6367 19.7892 22.6693 19.7444C22.7061 19.6939 22.7508 19.6492 22.8404 19.5596L26.9737 15.4263C27.0529 15.3471 27.0925 15.3075 27.1382 15.2926C27.1784 15.2796 27.2216 15.2796 27.2618 15.2926C27.3075 15.3075 27.3471 15.3471 27.4263 15.4263L28.5737 16.5737C28.6529 16.6529 28.6925 16.6925 28.7074 16.7382C28.7204 16.7784 28.7204 16.8216 28.7074 16.8618C28.6925 16.9075 28.6529 16.9471 28.5737 17.0263L24.4404 21.1596C24.3508 21.2492 24.3061 21.2939 24.2556 21.3307C24.2108 21.3633 24.1628 21.3912 24.1122 21.4139C24.0553 21.4395 23.9942 21.4561 23.8721 21.4894L22 22Z" 
												stroke="#FFFFFF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
											</g>
										</svg>
									</div>
								</>
							) : null}
						</div>

						{(isEditGuestDetails && guestDetails.guest_image) ? (
							<Button type='button' 
								variant='dark' className='mb-3'
								style={{
									backgroundColor: '#dc3545',
									color: '#fff',
									width: (width <= 576) ? 130 : 180,
								}}
								size='sm'
								onClick={handleDeleteGuestImage}
							>Remove Image</Button>
						) : null}
					</Col>
					
					<Col xs={5} className='media_top'>
						<div className="text-end cp_btn_save_dlt">
							{isEditGuestDetails ? (
								<>
									{(!isNewGuest) && (
									<Button className="btn-delete" onClick={() => setConfirmGuestDelete(guestDetails.id)}>
										<i>
											<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g filter="url(#filter0_i_819_876)">
													<rect width="40" height="40" rx="8" fill="#D31111" />
													<path d="M22.8002 15.8V15.24C22.8002 14.4559 22.8002 14.0639 22.6476 13.7644C22.5134 13.501 22.2992 13.2868 22.0358 13.1526C21.7363 13 21.3443 13 20.5602 13H19.4402C18.6561 13 18.2641 13 17.9646 13.1526C17.7012 13.2868 17.487 13.501 17.3528 13.7644C17.2002 14.0639 17.2002 14.4559 17.2002 15.24V15.8M18.6002 19.65V23.15M21.4002 19.65V23.15M13.7002 15.8H26.3002M24.9002 15.8V23.64C24.9002 24.8161 24.9002 25.4042 24.6713 25.8534C24.47 26.2485 24.1487 26.5698 23.7536 26.7711C23.3044 27 22.7163 27 21.5402 27H18.4602C17.2841 27 16.696 27 16.2468 26.7711C15.8517 26.5698 15.5304 26.2485 15.3291 25.8534C15.1002 25.4042 15.1002 24.8161 15.1002 23.64V15.8" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
												</g>
												<defs>
													<filter id="filter0_i_819_876" x="0" y="0" width="40" height="41" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
														<feFlood floodOpacity="0" result="BackgroundImageFix" />
														<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
														<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
														<feOffset dy="1" />
														<feGaussianBlur stdDeviation="0.5" />
														<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
														<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0" />
														<feBlend mode="normal" in2="shape" result="effect1_innerShadow_819_876" />
													</filter>
												</defs>
											</svg>
										</i>
									</Button>
									)}
									<Button className={`btn_save`} onClick={() => handleSaveEditGuest({
										guest_image: img,
									})}>Save</Button>
								</>
							) : (
								<>
								{hasEditAccess && <Button className={`btn_edit`} onClick={e => setIsEditGuestDetails(true)}>Edit</Button>}
								</>
							)}

							<div className='mt-5'>
								{event.has_badge_printing === 1 && (
								<Button className={`btn_edit ${isEditGuestDetails ? 'disabled' : ''}`} onClick={() => handlePrint(guestDetails.id)}>
									<i>
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M13.8 5V3.56C13.8 2.66392 13.8 2.21587 13.6256 1.87362C13.4722 1.57256 13.2274 1.32779 12.9264 1.17439C12.5841 1 12.1361 1 11.24 1H6.76C5.86392 1 5.41587 1 5.07362 1.17439C4.77256 1.32779 4.52779 1.57256 4.37439 1.87362C4.2 2.21587 4.2 2.66392 4.2 3.56V5M4.2 13.8C3.45602 13.8 3.08403 13.8 2.77883 13.7182C1.95061 13.4963 1.3037 12.8494 1.08178 12.0212C1 11.716 1 11.344 1 10.6V8.84C1 7.49587 1 6.82381 1.26158 6.31042C1.49168 5.85883 1.85883 5.49168 2.31042 5.26158C2.82381 5 3.49587 5 4.84 5H13.16C14.5041 5 15.1762 5 15.6896 5.26158C16.1412 5.49168 16.5083 5.85883 16.7384 6.31042C17 6.82381 17 7.49587 17 8.84V10.6C17 11.344 17 11.716 16.9182 12.0212C16.6963 12.8494 16.0494 13.4963 15.2212 13.7182C14.916 13.8 14.544 13.8 13.8 13.8M11.4 7.8H13.8M6.76 17H11.24C12.1361 17 12.5841 17 12.9264 16.8256C13.2274 16.6722 13.4722 16.4274 13.6256 16.1264C13.8 15.7841 13.8 15.3361 13.8 14.44V13.16C13.8 12.2639 13.8 11.8159 13.6256 11.4736C13.4722 11.1726 13.2274 10.9278 12.9264 10.7744C12.5841 10.6 12.1361 10.6 11.24 10.6H6.76C5.86392 10.6 5.41587 10.6 5.07362 10.7744C4.77256 10.9278 4.52779 11.1726 4.37439 11.4736C4.2 11.8159 4.2 12.2639 4.2 13.16V14.44C4.2 15.3361 4.2 15.7841 4.37439 16.1264C4.52779 16.4274 4.77256 16.6722 5.07362 16.8256C5.41587 17 5.86392 17 6.76 17Z" stroke="#2C292C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</i>{` `}
									Print
								</Button>
								)}
							</div>
						</div>
					</Col>
				</Row>
				
				<Row className="media">
					<Col xs={7}>
						<div className='media_top'>
							<h5 className="mt-0">
								{(guestDetails.guest_name) ? guestDetails.guest_name : `${guestDetails.first_name || ''} ${guestDetails.last_name || ''}`}
								<span>{(guestDetails.company) ? guestDetails.company : ''}</span>
							</h5>
						</div>
					</Col>
					
					<Col xs={5} className='media_top'>
						<div className="text-end">
							<Button className="cp_confrm_btn "
								disabled
								style={{
									width: 'auto',
									padding: '6px 12px',
									fontSize: '14px'
								}}
							>{guestDetails.status.replace(/_/, ' ').toUpperCase()}</Button>
						</div>
					</Col>
				</Row>


				<div className="media mt-3 mb-4">
					
					<div className="cp_text_bx  media-body">
						{(guestDetails.status === GuestStatus.CHECKED_IN) ? (
							<>
								<Button className={`checkIn_btn`} {...revertCheckInGuest()} disabled={isEditGuestDetails}>
									<div className='checkinPress'>
										<span>Checked-In</span>
										<span className='small'>Press & Hold to Revert</span>
									</div>
								</Button>
							</>
						) : (
							<Button className={`checkIn_btn press`} onClick={e => checkInGuest(guestDetails.id)} disabled={isEditGuestDetails}>
								<div className='checkIn'>
									<span>Check-In</span>
								</div>
							</Button>
						)}
					</div>
				</div>

				<div className="media">
					<div className="cp_text_bx  media-body">
						<Form onSubmit={e => e.preventDefault()}>

							<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
								<Form.Label column xs={4}>Guest List</Form.Label>
								<Col xs={8}>
									<Form.Select
										value={guestDetails?.guest_list_id}
										disabled={!isEditGuestDetails}
										onChange={e => setGuestDetails({
											...guestDetails,
											guest_list_id: parseInt(e.target.value)
										})}
									>
										{lists.map((list, i) => {
											return (
												<option key={i} value={list.id?.toString()}>{list.list_name}</option>
											)
										})}
									</Form.Select>
								</Col>
								<hr className='dvdr' />
							</Form.Group>

							{eventFields.map((field, i) => {
								if (field.field === 'status') {
									return (
										<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
											<Form.Label column xs={4}>Status</Form.Label>
											<Col xs={8}>
												<Form.Select
													value={guestDetails.status}
													disabled={!isEditGuestDetails}
													onChange={e => setGuestDetails({
														...guestDetails,
														status: e.target.value as GuestStatusType
													})}
													style={{
														lineHeight: '16px'
													}}
												>
													{([GuestStatus.CHECKED_IN, GuestStatus.CHECKED_OUT].includes(guestDetails.status)) ? (
														<option value={guestDetails.status}>Checked In</option>
													) : (
														<>
														<option value={GuestStatus.UN_CONFIRMED}>Unconfirmed</option>
														<option value={GuestStatus.CONFIRMED}>Confirmed</option>
														<option value={GuestStatus.CANCELLED}>Cancelled</option>
														</>
													)}
												</Form.Select>
											</Col>
											<hr className='dvdr' />
										</Form.Group>
									)
								} else {
									let val = (guestDetails.hasOwnProperty(field.field) && guestDetails[field.field as keyof Guest] !== undefined) ? guestDetails[field.field as keyof Guest]?.toString() : '';
	
									if (val !== undefined) {
										if (field.type === 'number') {
											val = val.replace(/\D/g, '');
										}
									}
	
									if (!isEditGuestDetails && (val === '' || val === undefined || val === null)) {
										return (<></>);
									} else {
	
										return (
											<Form.Group key={i} as={Row} className="mb-0" controlId="formHorizontalEmail">
												<Form.Label column xs={4}>{field.display_name}</Form.Label>
												<Col xs={8}>
													{(field.type === 'dropdown') ? (
														<></>
													) : (
														<Form.Control
															type={(!['date', 'time'].includes(field.type)) ? field.type : 'text'}
															value={val}
															disabled={!isEditGuestDetails}
															onChange={e => setGuestDetails({
																...guestDetails,
																[field.field]: e.target.value
															})}
															style={{
																color: (field.field === 'notification') ? '#dc3545' : '#4D474D'
															}}
														/>
													)}
												</Col>
												<hr className='dvdr' />
											</Form.Group>
										)
									}
								}

							})}
						</Form>
					</div>
				</div>

				{isEditGuestDetails ? (
				<div className="cp_main_detail  media_top text-end mt-2">
					<div className='media cp_btn_save_dlt d-flex justify-content-end'>
					<Button className={`btn_save`} onClick={() => handleSaveEditGuest({
						guest_image: img,
					})}>Save</Button>
					</div>
				</div>
				) : null}
			</div>
		</>
	)
}


const GuestDetailsPopup = (props: GuestDetailsPopupTypes) => {
	const dispatch = useAppDispatch();
	const { width } = useWindowDimensions();

	if (props.guestDetails === null) {
		return null;
	}

	return (
		<Offcanvas show={props.guestDetails !== null} className="checkedIn" onHide={() => {
			if (props.isNewGuest) {
				dispatch(toggleAddGuestPopup(false))
			} else {
				props.setGuestDetails(null)
			}
		}} placement="end">
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>{props.isNewGuest ? 'Add Guest' : 'Guest Details'}</Offcanvas.Title>
			</Offcanvas.Header>

			<Offcanvas.Body>
				<div className="cp_main_detail">

					
					{(width <= 768) ? (
						<SmallScreenDesign {...props} />
					) : (
						<LargeScreenDesign {...props} />
					)}


					<div className="cp_user_table">
						<table className="table table-borderless">
							<thead>
								<tr>
									<th>Timeline</th>
									<th>Activity</th>
									<th className='text-end'>Device Name</th>
								</tr>
							</thead>
							<tbody>
								{(props.guestCheckInLogs.length === 0) && (
									<tr><td colSpan={3} className='text-center text-muted'>not checked-in yet.</td></tr>
								)}
								{props.guestCheckInLogs.map((log, i) => {
									return (
										<tr key={i}>
											<td>{(log.time) ? dayjs(log.time).format('MMM DD, YYYY h:mm a') : '-'}</td>
											<td>{log.activity.replace(/_/, ' ').toUpperCase()}</td>
											<td align="right">{(log.device_name) ? log.device_name : '-'}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default GuestDetailsPopup;