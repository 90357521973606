import React, { useContext, useEffect, useState } from 'react';
import { apiGetPackages } from 'api/payment';
import { AvailableCurrencies } from 'constant';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Event, Package } from "types";
import {
	PaymentElement,
	LinkAuthenticationElement,
	CardElement,
	AddressElement
} from '@stripe/react-stripe-js'

import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useAppSelector } from 'redux/hooks';


type CheckoutFormProps = {
	selectedPackage: Package
	currency: string
	packageAmount: string
	onCancel: () => void
	onSubmit: (paymentMethod: any) => void
	setErrMsg: any
}

const CheckoutForm = ({
	selectedPackage,
	currency,
	packageAmount,
	onCancel,
	onSubmit,
	setErrMsg,
}: CheckoutFormProps): JSX.Element => {
	const userData = useAppSelector(state => state.auth.userData);
	const stripe = useStripe();
	const elements = useElements();
	const [isLoading, setIsLoading] = useState(false);
	const [sameAsCompanyBilling, setSameAsCompanyBilling] = useState(true);

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setErrMsg('');
		setIsLoading(true);
		console.log({ elements })

		elements.submit()
			.then(function (result) {
				// handle result.error or result.paymentMethod
				console.log('submit', { result })

				if (result.error) {
					// Show error to your customer (e.g., insufficient funds)
					setErrMsg(result.error.message);
					setIsLoading(false);
					return;
				}

				stripe
					.createPaymentMethod({
						elements,
						params: {
							billing_details: {
								name: userData?.name,
								email: userData?.email,
									address: {
										country: '',
										// postal_code: userData?.company[0]?.zip,
										// state: userData?.company[0]?.state,
										// city: userData?.company[0]?.city,
										// line1: userData?.company[0]?.address,
										// line2: null,
									}
							},
						},
					})
					.then(function (result) {
						// Handle result.error or result.paymentMethod
						console.log('createPaymentMethod', { result })
						setIsLoading(false);
						if (result.error) {
							// Show error to your customer (e.g., insufficient funds)
							setErrMsg(result.error.message);
						}
						if (result.paymentMethod) {
							onSubmit(result.paymentMethod);
						}
					});
			});

		return;
	}


	return (
		<>
		<Row>
			<Col className='text-start'>
				<h5 className='mt-5'><strong>Pay Now!</strong></h5>
				<h5><strong>Instant Activation</strong></h5>
			</Col>
			<Col>
				<img src={require('assets/images/stripe-badge.png')} alt="stripe-badge" style={{ maxWidth: 500 }} />
			</Col>
		</Row>
		<form id="payment-form" onSubmit={handleSubmit}>
			<LinkAuthenticationElement id="link-authentication-element" className='mb-2'
			// Access the email value like so:
			// onChange={(event) => {
			//  setEmail(event.value.email);
			// }}
			//
			// Prefill the email field like so:
			options={{defaultValues: {email: userData?.email || ''}}}
			/>
			<PaymentElement id="payment-element" options={{
				// billingDetails: {
				// 	name: userDetails.company[0]?.name,
				// 	email: userDetails?.email,
				// 	// phone: '',
				// 	address: {
				// 		// country: '',
				// 		postal_code: userDetails?.company[0]?.zip,
				// 		state: userDetails?.company[0]?.state,
				// 		city: userDetails?.company[0]?.city,
				// 		line1: userDetails?.company[0]?.address,
				// 		// line2: null,
				// 	}
				// }
			}} />
			

			{/* <CCardTitle>Billing Address</CCardTitle>
			<CFormCheck id="billingCheckBox" label="Same as company address" checked={sameAsCompanyBilling} value={sameAsCompanyBilling} onChange={(e) => setSameAsCompanyBilling(!sameAsCompanyBilling)} />
			{!sameAsCompanyBilling && (
				<AddressElement id="address-element" options={{
					mode: 'billing'
				}} />
			)} */}
			

			<div className='text-end'>
				<Button variant="outline-secondary" size='lg' className='me-2' onClick={onCancel}>Cancel</Button>
				<Button 
					variant='dark'
					size='lg'
					id="submit" 
					type="submit"
					disabled={isLoading || !stripe || !elements}
				>Pay <small>{packageAmount}</small></Button>
			</div>
		</form>

		</>
	)
}

export default CheckoutForm;