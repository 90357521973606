import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toast } from 'react-toastify';
import { batch } from 'react-redux';
import { unsetActiveEvent } from 'redux/eventSlice';
import { setHeaderTitle } from 'redux/interfaceManger';
import { Domain, DomainEmails, Event, Team, TeamMember, TeamUpdateUserData } from 'types';
import { apiDeleteTeamMember, apiGetTeam, apiResendInviteTeam, apiUpdateTeamMember } from 'api/team';
import { apiGetEvent } from 'api/events';
import { EventStatus, TeamAccess, TeamRole } from 'constant';
import Select from 'react-select'
import { Link, useNavigate } from 'react-router-dom';
import { InputGroup, Spinner } from 'react-bootstrap';
import { apiCreateDomain, apiCreateDomainSender, apiDeleteDomain, apiDeleteDomainSender, apiGetDomainSenders, apiGetDomains } from 'api/domain';


type DomainEmailsProps = {
	domain: Domain
}

const DomainEmailsComponent = ({ domain }: DomainEmailsProps) => {
	const [reloadTable, setReloadTable] = useState<number>(0);
	const [emails, setEmails] = useState<DomainEmails[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [isAdding, setIsAdding] = useState(false);
	const [newName, setNewName] = useState<string>('');
	const [newEmail, setNewEmail] = useState<string>('');
	const [addingErrMsg, setAddingErrMsg] = useState<string>('');
	const [isSaving, setIsSaving] = useState(false);

	const [deletingEmail, setDeletingEmail] = useState<DomainEmails | null>(null);
	const [deletingErrMsg, setDeletingErrMsg] = useState<string>('');
	const [isDeleting, setIsDeleting] = useState(false);

	useEffect(() => {
		(async () => {
			// console.log('=====domain emails=====', domain.domain)
			if (domain) {
				setIsLoading(true)
				const response = await apiGetDomainSenders(domain.id)
				setIsLoading(false)
				if (response.success) {
					setEmails(response.data)
				}
			}
		})();
	}, [domain, reloadTable])


	const handleAddEmail = async () => {
		if (newName.trim() === '' || newEmail.trim() === '') {
			return;
		}

		batch(() => {
			setAddingErrMsg('')
			setIsSaving(true)
		})
		const response = await apiCreateDomainSender(domain.id, newName, newEmail)
		setIsSaving(false)
		if (response.success) {
			batch(() => {
				setIsAdding(false);
				setNewName('');
				setNewEmail('');
				setReloadTable(prev => prev + 1);
			})
		} else {
			setAddingErrMsg(response.message)
		}
	}

	const handleDeleteEmail = async () => {
		console.log(deletingEmail)
		
		if (deletingEmail) {
			batch(() => {
				setDeletingErrMsg('')
				setIsDeleting(true)
			})
			const response = await apiDeleteDomainSender(deletingEmail.id)
			setIsDeleting(false)
			if (response.success) {
				setDeletingEmail(null);
				setReloadTable(prev => prev + 1);
			} else {
				setDeletingErrMsg(response.message)
			}
		}

	}


	return (
		<>
			{addingErrMsg && <div className="alert alert-danger">{addingErrMsg}</div>}
			<table className='table table-borderless'>
				<thead>
					<tr>
						<th colSpan={2}>Emails</th>
					</tr>
				</thead>
				<tbody>
					{(isLoading) ? (
						<div className="pt-5 text-center">
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</div>
					) : (
						<>
						{emails.length === 0 && (
							<tr>
								<td colSpan={2} className='text-center text-muted'>You have not added any emails yet!</td>
							</tr>
						)}

						{emails.map((email) => {
							return (
								<tr key={email.id}>
									<td>
										<InputGroup>
											<Form.Control
												type='text'
												value={email.email.split('@')[0]}
												disabled
											/>
											<InputGroup.Text>@{email.email.split('@')[1]}</InputGroup.Text>
										</InputGroup>
									</td>
									<td>
										<Button type='button' variant='outline-danger' onClick={() => setDeletingEmail(email)}>Delete</Button>
									</td>
								</tr>
							)
						})}

						{isAdding ? (
							<tr>
								<td>
									<Row>
										<Col md={4}>
											<Form.Control
												type='text'
												value={newName}
												onChange={e => setNewName(e.target.value)}
												placeholder='Sender Name'
											/>
										</Col>
										<Col>
											<InputGroup>
												<Form.Control
													type='text'
													value={newEmail}
													onChange={e => setNewEmail(e.target.value)}
													placeholder='Sender Email'
												/>
												<InputGroup.Text>@{domain.domain}</InputGroup.Text>
											</InputGroup>
										</Col>
									</Row>
								</td>
								<td>
									<Button type='button' variant='dark' onClick={handleAddEmail} disabled={isSaving}>Save</Button>
								</td>
							</tr>
						) : (
							<tr>
								<td colSpan={2}>
									<Button type='button' variant='dark' onClick={() => setIsAdding(true)}>Add email</Button>
								</td>
							</tr>
						)}
						</>
					)}

				</tbody>
			</table>


			{deletingEmail && (
				<Modal show={true} className="createNewAccountModal" onHide={() => setDeletingEmail(null)} aria-labelledby="contained-modal-title-vcenter" centered>
					<Modal.Header closeButton>
						<Modal.Title>
							<span>Delete Email</span>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						{deletingErrMsg && <div className="alert alert-danger">{deletingErrMsg}</div>}
						<Form onSubmit={e => e.preventDefault()}>
							<div>
								<p className='m-0'>
									Are you sure you want to delete
								</p>
								<p>[<strong>{deletingEmail.email}</strong>]</p>

								<h4 className="mb-5">
									This cannot be undone
								</h4>
							</div>

							<div className={`actionBtns`}>
								<Button type='button' variant="primary" className={`btn-steps cp_delete_btn`} disabled={isDeleting} onClick={handleDeleteEmail}>
									<span className={`step3 d-flex align-items-center justify-content-center`}>
										Yes delete domain
									</span>
								</Button>
							</div>
						</Form>
					</Modal.Body>
				</Modal>
			)}
		</>
	)
}

export default DomainEmailsComponent;
