import { ResponseObject, EventPrinterResponse, Printer, PrinterLabel } from "types";
import apiService from "services/ApiService";
import cacheService from "services/CacheService";

type PrinterLabelsResponse = {
	labels: PrinterLabel[];
	eventPrinter: {
		event_id: number
		printer_id: number
		page_width: number
		page_height: number
		orientation: string
		template_id: number | null
	}
}

export const apiGetPrinters = async (): Promise<ResponseObject<Printer[]>> => {
	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@printers`);
	}

	const response = await apiService.get(`/printers`);
	localStorage.setItem(`@printers`, JSON.stringify(response.data))
	return response;
}


export const apiGetEventPrinters = async (
	eventId: string | number,
): Promise<ResponseObject<EventPrinterResponse>> => {
	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@event:${eventId}:printers`);
	}

	const response = await apiService.get(`/event-printers/${eventId}`);
	localStorage.setItem(`@event:${eventId}:printers`, JSON.stringify(response.data))
	return response;
}


export const apiGetEventPrinterLabels = async (
	eventId: string | number,
): Promise<ResponseObject<PrinterLabelsResponse>> => {
	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@event:${eventId}:printer-labels`);
	}

	const response = await apiService.get(`/printer-labels/${eventId}`);
	localStorage.setItem(`@event:${eventId}:printer-labels`, JSON.stringify(response.data))
	return response;
}

export const apiUpdateEventPrinterLabels = async (
	eventId: string | number,
	labels: PrinterLabel[],
	canvasWidth: number,
	canvasHeight: number,
	orientation: string,
	badgeTemplateId: number,
): Promise<ResponseObject<PrinterLabel[]>> => {
	return await apiService.post(`/printer-labels/${eventId}`, {
		labels,
		page_width: canvasWidth,
		page_height: canvasHeight,
		orientation: orientation,
		template_id: badgeTemplateId,
	});
}