import { ResponseObject, Event, EventDataField, EventStatusType } from "types";
import apiService from "services/ApiService";
import cacheService from "services/CacheService";

export const apiGetEvent = async (status?: EventStatusType[]): Promise<ResponseObject<Event[]>> => {

	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@events`);
	}

	const response = await apiService.get('/events', {
		status: status ? status.join(',') : null,
	});

	localStorage.setItem(`@events`, JSON.stringify(response.data));

	return response;
}

export const apiGetEventCollaborate = async (status?: EventStatusType[]): Promise<ResponseObject<Event[]>> => {

	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@collaborate-events`);
	}

	const response = await apiService.get('/collaborate-events', {
		status: status ? status.join(',') : null,
	});

	localStorage.setItem(`@collaborate-events`, JSON.stringify(response.data));

	return response;
}

// get all event data fields
export const apiGetEventDataFields = async(eventId: string | number): Promise<ResponseObject<EventDataField[]>> => {
	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@event:${eventId}:event-data-fields`);
	}

	const response = await apiService.get(`/event-data-fields/${eventId}`);
	localStorage.setItem(`@event:${eventId}:event-data-fields`, JSON.stringify(response.data));
	return response;
}

// get all remaining data fields which are not added in event
export const apiGetAllDataFields = async(eventId: string | number): Promise<ResponseObject<string[]>> => {
	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@event:${eventId}:data-fields`);
	}

	const response = await apiService.get(`/data-fields/${eventId}`);
	localStorage.setItem(`@event:${eventId}:data-fields`, JSON.stringify(response.data));
	return response;
}

export const apiCreateEvent = async (data: any): Promise<ResponseObject<Event>> => {
	return await apiService.postMultipart('/create-event', data);
}

export const apiUpdateEvent = async (eventId: string | number, data: any): Promise<ResponseObject<Event>> => {
	return await apiService.postMultipart(`/update-event/${eventId}`, data);
}

export const apiUpdateEventStatus = async (eventId: string | number, status: EventStatusType): Promise<ResponseObject<Event>> => {
	return await apiService.postMultipart(`/update-event-status/${eventId}`, {
		status
	});
}

export const apiUpdateEventFloorPlan = async (
	eventId: string | number, 
	agenda_icon: number,
	agenda_images: string[] | null,
	floor_plan_icon: number,
	floor_plan_images: string[] | null,
): Promise<ResponseObject<Event>> => {
	return await apiService.post(`/update-event-floor-plan/${eventId}`, {
		agenda_icon,
		agenda_images,
		floor_plan_icon,
		floor_plan_images,
	});
}

export const apiDownloadGuestListsSample = async (eventId: number | string): Promise<ResponseObject<string>> => {
	return await apiService.get(`/download-guest-sample/${eventId}`);
}

export const apiUpdateEventDataFields = async (eventId: string | number, dataFields: EventDataField[]): Promise<ResponseObject<EventDataField[]>> => {
	return await apiService.post(`/update-event-fields/${eventId}`, {
		fields: dataFields
	});
}

export const apiDeleteEventDataFields = async (eventId: string | number, fieldId: string | number): Promise<ResponseObject<EventDataField[]>> => {
	return await apiService.delete(`/delete-event-field/${eventId}/${fieldId}`);
}

export const apiDeleteEvent = async (eventId: string | number): Promise<ResponseObject<null>> => {
	return await apiService.delete(`/delete-event/${eventId}`);
}
