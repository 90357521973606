import { ResponseObject, UserBillingAddress, UserDataObject } from "types";
import apiService from "services/ApiService";

type LoginData = {
	email: string
	password: string
}

type RegisterData = LoginData & {
	name: string
	c_password: string
}

type ResetPasswordData = {
	token: string | undefined
	email: string
	password: string
	password_confirmation: string
}

export const apiLogin = async (data: LoginData): Promise<ResponseObject<UserDataObject>> => {
	const response = await apiService.auth('/login', data);

	if (response.success) {
		localStorage.setItem('@userData', JSON.stringify({
			...response.data,
			roleName: response.data.role.name,
		}));

		const token = response.data.token;
		apiService.setToken(token);
	}

	return response;
}

export const apiRegister = async (data: RegisterData): Promise<ResponseObject<UserDataObject>> => {
	const response = await apiService.auth('/register', data);

	if (response.success) {
		localStorage.setItem('@userData', JSON.stringify({
			...response.data,
			roleName: response.data.role.name,
		}));

		const token = response.data.token;
		apiService.setToken(token);
	}

	return response;
}


export const apiForgotPassword = async (email: string) : Promise<ResponseObject<any>> => {
	return await apiService.auth('/forgot-password', {
		email
	});
}

export const apiResetPassword = async (data: ResetPasswordData) : Promise<ResponseObject<any>> => {
	return await apiService.auth('/reset-password', data);
}


export const apiUpdateProfile = async (name: string, email: string): Promise<ResponseObject<UserDataObject>> => {
	const response = await apiService.post('/update-profile', {
		name: name,
		email: email,
	});

	if (response.success) {
		const session = localStorage.getItem('@userData');
		if (session) {
			const sessionData = JSON.parse(session);

			if (sessionData) {
				localStorage.setItem('@userData', JSON.stringify({
					...sessionData,
					name: response.data.name,
					email: response.data.email,
				}));
			}
		}
	}

	return response;
}

export const apiUpdateProfileImage = async (image: File): Promise<ResponseObject<UserDataObject>> => {
	const response = await apiService.postMultipart('/update-profile-image', {
		image,
	});

	if (response.success) {
		const session = localStorage.getItem('@userData');
		if (session) {
			const sessionData = JSON.parse(session);

			if (sessionData) {
				localStorage.setItem('@userData', JSON.stringify({
					...sessionData,
					image: response.data.image,
				}));
			}
		}
	}

	return response;
}

export const apiChangePassword = async (
	oldPassword: string,
	newPassword: string,
	confirmPassword: string
): Promise<ResponseObject<null>> => {
	const response = await apiService.post('/change-password', {
		old_password: oldPassword,
		password: newPassword,
		c_password: confirmPassword,
	});

	return response;
}



export const apiGetBillingAddress = async (): Promise<ResponseObject<UserBillingAddress>> => {
	const response = await apiService.get('/billing-address');

	return response;
}

export const apiUpdateBillingAddress = async (
	company_name: string, 
	attention: string,
	address: string,
	country: string,
): Promise<ResponseObject<UserBillingAddress>> => {
	const response = await apiService.post('/billing-address', {
		company_name,
		attention,
		address,
		country,
	});

	return response;
}