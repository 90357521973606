import React, { useContext, useState } from "react";
import { AuthContext } from 'AuthContext';

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setScannerType } from "redux/authSlice";
import { toggleBulkBadgePrintPopup, toggleSelectDevicePopup } from "redux/interfaceManger";
import { ScanTypes } from "constant";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useParams } from "react-router-dom";




const Sidebar = () => {
	const dispatch = useAppDispatch();
	const userData = useAppSelector(state => state.auth.userData);
	const deviceName = useAppSelector(state => state.auth.deviceName);
	const scanType = useAppSelector(state => state.auth.scanType);
	let { eventId } = useParams();
	const { logout, switchApp } = useContext(AuthContext);
	
	const [show, setShow] = useState(false);

	const {width} = useWindowDimensions();

    const pathname = window.location.pathname;

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	

	



	return (
		
		<>
            <Offcanvas className="sidebar" show={show} onHide={handleClose}>
                {/* <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header> */}
                <Offcanvas.Body>
                    <div className="settings-dv">
                        <h5>Settings</h5>

                        <hr />

                        <div className="entrance">
                            <Row>
                                <Col lg="5">
                                    <h4>Device</h4>
                                </Col>

                                <Col lg="7">
                                    <h4>
                                        <span>{deviceName}</span>
                                        <span className="ico" onClick={() => {
											dispatch(toggleSelectDevicePopup(true))
										}}>
                                            <svg width="30" height="30" viewBox="10 5 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_328_1303)">
                                                    <rect x="16" y="8" width="20" height="20" rx="4" fill="white" shape-rendering="crispEdges"/>
                                                    <path d="M28.4 14L30 15.6M22 22L22.5106 20.1279C22.5439 20.0058 22.5605 19.9447 22.5861 19.8878C22.6088 19.8372 22.6367 19.7892 22.6693 19.7444C22.7061 19.6939 22.7508 19.6492 22.8404 19.5596L26.9737 15.4263C27.0529 15.3471 27.0925 15.3075 27.1382 15.2926C27.1784 15.2796 27.2216 15.2796 27.2618 15.2926C27.3075 15.3075 27.3471 15.3471 27.4263 15.4263L28.5737 16.5737C28.6529 16.6529 28.6925 16.6925 28.7074 16.7382C28.7204 16.7784 28.7204 16.8216 28.7074 16.8618C28.6925 16.9075 28.6529 16.9471 28.5737 17.0263L24.4404 21.1596C24.3508 21.2492 24.3061 21.2939 24.2556 21.3307C24.2108 21.3633 24.1628 21.3912 24.1122 21.4139C24.0553 21.4395 23.9942 21.4561 23.8721 21.4894L22 22Z" stroke="#2C292C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_328_1303" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                        <feOffset dy="8"/>
                                                        <feGaussianBlur stdDeviation="8"/>
                                                        <feComposite in2="hardAlpha" operator="out"/>
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_328_1303"/>
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_328_1303" result="shape"/>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </span>
                                    </h4>
                                </Col>
                            </Row>
                        </div>

                        <hr />

                        <Accordion defaultActiveKey="">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>QR Code Scan</Accordion.Header>
                                <Accordion.Body>
                                    <Form onSubmit={e => e.preventDefault()}>
                                        <Form.Group as={Row} className="mb-1">
                                            <Form.Label htmlFor="scanAndFind-switch" column xs={9}>
                                                Scan & Find
                                            </Form.Label>

                                            <Col xs={3}>
                                                <Form.Check  type="switch" id="scanAndFind-switch" checked={scanType === ScanTypes.FIND} onChange={() => dispatch(setScannerType(ScanTypes.FIND))} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-1">
                                            <Form.Label htmlFor="scanAndCheckIn-switch" column xs={9}>
                                                Scan & Check-in
                                            </Form.Label>

                                            <Col xs={3}>
                                                <Form.Check  type="switch" id="scanAndCheckIn-switch" checked={scanType === ScanTypes.CHECK_IN} onChange={() => dispatch(setScannerType(ScanTypes.CHECK_IN))} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-1">
                                            <Form.Label htmlFor="scanAndTrack-switch" column xs={9}>
                                                Scan & Track
                                            </Form.Label>

                                            <Col xs={3}>
                                                <Form.Check  type="switch" id="scanAndTrack-switch" checked={scanType === ScanTypes.TRACK} onChange={() => dispatch(setScannerType(ScanTypes.TRACK))} />
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>


						{eventId && (
							<>
							<hr />
							<div className="btn btn-theme btn-menu" onClick={() => dispatch(toggleBulkBadgePrintPopup(true))}>
								<span>Bulk Badge Print</span> 
							</div>
							</>
						)}
                    </div>

					{(userData?.role.name === 'admin' && width >= 1200) ? (
					<div className="settings-dv mt-3 p-0 d-grid">
						<Button type="button" 
							variant="" 
							style={{
								background: 'transparent',
								color: '#fff',
								outline: 0,
								border: 0,
								paddingTop: '1rem',
								paddingBottom: '1rem',
							}}
							onClick={switchApp}
						>
							Event Management {` `}
							<i>
								<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
									transform: 'rotate(-45deg)'
								}}>
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</i>
						</Button>
					</div>
					) : null}

                    <div className="loggedIn-dv">
                        <h5>You are logged in as:</h5>
                        <h4>{userData?.name || userData?.email}</h4>

                        <hr />

                        <Button variant="contained" onClick={logout}>
                            <i>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.93652 6.49999C5.14685 4.40769 7.40905 3 10 3C13.866 3 17 6.134 17 9.99998C17 13.866 13.866 17 10 17C7.40905 17 5.14685 15.5923 3.93652 13.5M9.99998 12.8L12.8 9.99998M12.8 9.99998L9.99998 7.19999M12.8 9.99998H3" stroke="#ACA6A6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </i>
                            Logout
                        </Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            

            <button type="button" className="cp_nav_btn"  onClick={handleShow}>
                <i>
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2H1C0.447716 2 0 1.55228 0 1Z" fill="white"/>
                        <path d="M0 9C0 8.44772 0.447715 8 1 8H19C19.5523 8 20 8.44772 20 9C20 9.55228 19.5523 10 19 10H1C0.447716 10 0 9.55228 0 9Z" fill="white"/>
                        <path d="M0 17C0 16.4477 0.447715 16 1 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H1C0.447716 18 0 17.5523 0 17Z" fill="white"/>
                    </svg>
                </i>
            </button>
		</>
	)
};

export default Sidebar;