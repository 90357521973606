import { ResponseObject, GuestListResponse, GuestList, Event, UploadGuestListResponse } from "types";
import apiService from "services/ApiService";
import cacheService from "services/CacheService";


export const apiGetGuestLists = async (eventId: number): Promise<ResponseObject<GuestListResponse>> => {
	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@event:${eventId}:guest-lists`);
	}

	const response = await apiService.get(`/guest-lists/${eventId}`);
	localStorage.setItem(`@event:${eventId}:guest-lists`, JSON.stringify(response.data))
	return response;
}

export const apiCreateGuestLists = async (eventId: number | string, guestLists: any): Promise<ResponseObject<Event>> => {
	return await apiService.post(`/create-guest-list`, {
		event_id: eventId,
		guest_lists: guestLists
	});
}

export const apiUploadGuestLists = async (
	eventId: number | string,
	listId: number | string,
	file: any,
): Promise<ResponseObject<UploadGuestListResponse>> => {
	return await apiService.postMultipart(`/upload-guest-list`, {
		event_id: eventId,
		list_id: listId,
		file: file
	});
}

export const apiPairGuestLists = async (
	eventId: number | string,
	listId: number | string,
	fileName: string,
	mapping: any[],
): Promise<ResponseObject<null>> => {
	return await apiService.post(`/pair-guest-list`, {
		event_id: eventId,
		list_id: listId,
		file_name: fileName,
		mapping: mapping,
	});
}

export const apiDownloadGuestLists = async (eventId: number | string, listId: number | string): Promise<ResponseObject<string>> => {
	return await apiService.get(`/download-guest-list/${eventId}/${listId}`);
}

export const apiDeleteGuestLists = async (listId: number | string): Promise<ResponseObject<null>> => {
	return await apiService.delete(`/delete-guest-list/${listId}`);
}

export const apiEditGuestList = async (
	listId: number | string, 
	list_name: string
): Promise<ResponseObject<null>> => {
	return await apiService.post(`/edit-guest-list/${listId}`, {
		list_name
	});
}