import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { EmailTemplate } from 'types';
import { setHeaderTitle } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { apiGetTemplatePreview, apiUploadTemplate } from 'api/emailTemplate';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { apiGetGuestsByEvent } from 'api/guests';
import TemplatePlaceholderPopup from './TemplatePlaceholderPopup';
import TemplateEditPopup from './TemplateEditPopup';
import { EmailTemplateStatuses } from 'constant';
import TestEmailPopup from './TestEmailPopup';


const EmailTemplatePreview = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { templateId } = useParams();
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [template, setTemplate] = useState<EmailTemplate | null>(null);
	const [previewGuestId, setPreviewGuestId] = useState<string | number | null>(null);
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const iframeRef = useRef<HTMLIFrameElement | null>(null);
	const [placeholderPopupOpen, setPlaceholderPopupOpen] = useState<boolean>(false);
	const [isEditTemplate, setIsEditTemplate] = useState<EmailTemplate | null>(null);
	const [testEmailPopupOpen, setTestEmailPopupOpen] = useState<boolean>(false);
	
	// console.log({ templateId })

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Email Templates'))
		})
	}, [])

	useEffect(() => {

		(async () => {
			if (activeEvent && templateId) {
				batch(() => {
					setErrMsg('')
					setIsLoading(true);
				})
				const response = await apiGetTemplatePreview(activeEvent.id, templateId, previewGuestId)
				setIsLoading(false);
				if (response.success) {
					setTemplate(response.data)
				}
				else {
					setErrMsg(response.message)
				}
			}
		})();

	}, [activeEvent, templateId, previewGuestId])

	const searchGuests = async (inputValue: string) => {
		return new Promise<any[]>(async (resolve) => {
			if (activeEvent) {
				const response = await apiGetGuestsByEvent(activeEvent?.id, {
					page: 1,
					limit: 10,
					orderBy: 'id',
					orderType: 'DESC',
					q: inputValue
				})
	
				if (response.success) {
					resolve([...response.data.items.map(guest => {
						let name = (guest.guest_name) ? guest.guest_name : `${guest.first_name || ''} ${guest.last_name || ''}`;
						return {
							value: guest.id,
							label:  `${name} (${(guest.email) ? guest.email : 'N/A'})`
						}
					})])
				}
			}
			else {
				resolve([])
			}
		});
	}

	const onTemplateUpdate = (updatedTemplate: EmailTemplate) => {
		setIsEditTemplate(null);
		if (template) {
			setTemplate({
				...template,
				status: updatedTemplate.status,
				name: updatedTemplate.name,
				sender_name: updatedTemplate.sender_name,
				sender_email: updatedTemplate.sender_email,
				sender_reply_emails: updatedTemplate.sender_reply_emails,
				subject: updatedTemplate.subject,
			})
		}
	}

	// useEffect(() => {
	// 	if (iframeRef && iframeRef.current) {
	// 		iframeRef.current.contentWindow.document.open();
	// 		iframeRef.current.contentWindow.document.write('<body>Foo</body>');
	// 		iframeRef.current.contentWindow.document.close();
	// 	}
	// }, [iframeRef])

	if (!activeEvent) {
		return <Navigate to="/" />
	}

	return (
		<>
			<div className="page-template-preview">
				<div className="page-card card">
					<div className="card-header bg-white p-3">
						<Row className='mb-2'>
							<Col>
								<Link to={`/email-templates`} className='btn btn-white'><span className='ms-3 me-3'>Back</span></Link>
							</Col>
							<Col className='text-end'>
								<Button variant="dark" className='me-2'
								onClick={() => setTestEmailPopupOpen(true)}
								>
								<span className='ms-3 me-3'>Send Test</span>
								</Button>

								{(template && template.status !== EmailTemplateStatuses.ACTIVE) ? (
									<Button variant="outline-success" 
										onClick={() => setIsEditTemplate(template)}
									>
									<span className='ms-3 me-3'>Activate</span>
									</Button>
								) : null}
							</Col>
						</Row>
						<Row className='align-items-end'>
							<Col>
								<p className='text-muted m-0'>Email template name</p>
								<h5 className='m-0'>{template?.name}</h5>
							</Col>
							<Col>
								<Form.Group className="" controlId="eventCreateModal">
									<Form.Label>View email as</Form.Label>
									<InputGroup className="">
										<div style={{ flex: 1 }}>
											{(previewGuestId === null) ? (
												<AsyncSelect 
													cacheOptions 
													defaultOptions
													value={null}
													loadOptions={searchGuests} 
													onChange={e => {
														setPreviewGuestId(e.value)
													}}
												/>
											) : (
												<AsyncSelect 
													cacheOptions 
													defaultOptions 
													loadOptions={searchGuests} 
													onChange={e => {
														setPreviewGuestId(e.value)
													}}
												/>
											)}
										</div>

										{(previewGuestId !== null) ? (
										<Button variant="outline-secondary" onClick={() => setPreviewGuestId(null)}>
										Cancel
										</Button>
										) : null}
									</InputGroup>
									
								</Form.Group>
							</Col>
							<Col className='text-end'>
								<Button 
									type='button' 
									variant='link' 
									className='text-dark'
									onClick={() => setPlaceholderPopupOpen(true)}
								>View Placeholder</Button>

								{(template && template.status !== EmailTemplateStatuses.ACTIVE) ? (
								<Link to={`/email-templates/${template?.id}/edit-html`} state={template} className='btn btn-link text-dark' >Replace HTML</Link>
								) : null}
							</Col>
						</Row>
					</div>
					<div className="card-body">
						{errMsg && <div className="alert alert-danger">{errMsg}</div>}
						
						{(!template || isLoading) ? (
							<>
							<div className="pt-5 text-center">
								<Spinner animation="border" role="status">
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							</div>
							</>
						) : (
							<>
							<div>
								<iframe 
									ref={iframeRef} 
									title='template-preview-iframe' 
									id='template-preview-iframe' 
									name='template-preview-iframe'
									srcDoc={template.template}
									style={{
										width: '100%',
										height: 600,
									}}
								></iframe>
							</div>
							</>
						)}
					</div>
				</div>


				{(placeholderPopupOpen) && (
					<TemplatePlaceholderPopup eventId={activeEvent.id} isOpen={placeholderPopupOpen} setIsOpen={setPlaceholderPopupOpen} />
				)}

				{(testEmailPopupOpen && template) && (
					<TestEmailPopup template={template} eventId={activeEvent.id} isOpen={testEmailPopupOpen} setIsOpen={setTestEmailPopupOpen} />
				)}

				{(isEditTemplate) && (
					<TemplateEditPopup template={isEditTemplate} eventId={activeEvent.id} isOpen={isEditTemplate !== null} setIsOpen={setIsEditTemplate} onUpdate={onTemplateUpdate} />
				)}
			</div>
		</>
	)
}

export default EmailTemplatePreview;