import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PrinterLabel } from 'types';


type EventPrinterLabel = {
	labels: PrinterLabel[]
}

const initialState: EventPrinterLabel = {
	labels: []
}

export const printerLabelsSlice = createSlice({
	name: 'printerLabels',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setEventPrinterLabels(state: EventPrinterLabel, action: PayloadAction<PrinterLabel[]>) {
			state.labels = [...action.payload];
		},
	},
});


export const {
	setEventPrinterLabels,
} = printerLabelsSlice.actions;

export default printerLabelsSlice.reducer;
