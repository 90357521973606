

import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Event, EventDataField, Guest, GuestList, GuestStatusType, GuestTimelineResponse } from 'types';
import { Image } from 'react-bootstrap';
import { BASE_URL, GuestStatus } from 'constant';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { apiAddGuest, apiEditGuest, apiGetGuestTimeline, apiGuestBadgePrint } from 'api/guests';
import dayjs from 'dayjs';

type GuestDetailsPopupTypes = {
	event: Event
	guestData: Guest
	eventFields: EventDataField[]
	lists: GuestList[]
	handleSaveGuest: (additionalData?: any) => void
	handleClose: () => void
	isNewGuest: boolean
}


const LargeScreenDesign = ({
	event,
	guestData,
	eventFields,
	lists,
	handleSaveGuest,
	isNewGuest = false,
}: GuestDetailsPopupTypes) => {
	const isOnline = useAppSelector(s => s.interfaceManger.isOnline);
	const [isEditGuestDetails, setIsEditGuestDetails] = useState<boolean>(isNewGuest);
	const [guestDetails, setGuestDetails] = useState<Guest>(guestData);
	const [img, setImg] = useState<File | null>(null);
	const imgURL = (img !== null) ? URL.createObjectURL(img) : null;

	console.log({guestData})

	const handleSave = async () => {
		const data: any = {
			...guestDetails,
		}
		if (img) {
			data.guest_image = img;
		}

		if (isNewGuest) {

			if (!guestDetails.hasOwnProperty('guest_list_id') || !guestDetails.guest_list_id || guestDetails.guest_list_id?.toString() === '' || isNaN(guestDetails.guest_list_id)) {
				alert('Please select guest list');
				return;
			}

			if ((!guestDetails.hasOwnProperty('guest_name') || guestDetails.guest_name === '') &&
				(!guestDetails.hasOwnProperty('first_name') || guestDetails.first_name === '')) {
				alert('Please insert Guest name OR First name');
				return;
			}

			setIsEditGuestDetails(false);

			const response = await apiAddGuest(event.id, data)

			if (response.success) {
				handleSaveGuest(response.data)
			}

		} else {
			
	
			setIsEditGuestDetails(false);
	
			const response = await apiEditGuest(event.id, guestDetails.id, data)
	
			if (response.success) {
				handleSaveGuest(response.data)
			}
		}
	}

	const handlePrint = async () => {
		if (event) {
			window.open(`${BASE_URL}/../print-guest/${event.id}/${guestDetails.id}`)
			return;
		}

		// if (event) {
		// 	const response = await apiGuestBadgePrint(event.id, guestDetails.id)

		// 	if (response.success) {
		// 		setTimeout(() => {
		// 			window.open(response.data)
		// 		})
		// 	} else {
		// 		alert(response.message)
		// 	}
		// }
	}

	return (
		<>
			<div className="media">
				<div className="media-left col-sm-3">
					<div className="cp_im_bx mb-3">
						<Image rounded fluid
							src={(imgURL) ? imgURL : (guestDetails.guest_image && isOnline) ? guestDetails.guest_image : require('assets/images/placeholder-guest.jpg')}
							alt="guest image"
							style={{
								maxHeight: '100%'
							}}
						/>
						{(isEditGuestDetails) ? (
							<>
								<div className="imgUploadBtn">
									<input
										type="file"
										name="file"
										placeholder="Upload File"
										accept="image/*"
										required
										onChange={e => {
											if (e.target.files !== null) {
												// console.log(e.target.files[0]);
												// TODO: store file in separate state and add in  data when update api
												setImg(e.target.files[0]);
											}
										}}
									/>
									
									<svg width="60" height="60" viewBox="10 5 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g >
											<path d="M28.4 14L30 15.6M22 22L22.5106 20.1279C22.5439 20.0058 22.5605 19.9447 22.5861 19.8878C22.6088 19.8372 22.6367 19.7892 22.6693 19.7444C22.7061 19.6939 22.7508 19.6492 22.8404 19.5596L26.9737 15.4263C27.0529 15.3471 27.0925 15.3075 27.1382 15.2926C27.1784 15.2796 27.2216 15.2796 27.2618 15.2926C27.3075 15.3075 27.3471 15.3471 27.4263 15.4263L28.5737 16.5737C28.6529 16.6529 28.6925 16.6925 28.7074 16.7382C28.7204 16.7784 28.7204 16.8216 28.7074 16.8618C28.6925 16.9075 28.6529 16.9471 28.5737 17.0263L24.4404 21.1596C24.3508 21.2492 24.3061 21.2939 24.2556 21.3307C24.2108 21.3633 24.1628 21.3912 24.1122 21.4139C24.0553 21.4395 23.9942 21.4561 23.8721 21.4894L22 22Z" 
											stroke="#FFFFFF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
										</g>
									</svg>
								</div>
							</>
						) : null}
					</div>
					<Button className="cp_confrm_btn mb-5" >{guestDetails.status.replace(/_/, ' ').toUpperCase()}</Button>
				</div>

				<div className="cp_text_bx  media-body">
					<Form onSubmit={e => e.preventDefault()} className='mb-4'>
						<div className="media_top d-flex justify-content-between">
							<h5 className="mt-0">
								{(guestDetails.guest_name) ? guestDetails.guest_name : `${guestDetails.first_name || ''} ${guestDetails.last_name || ''}`}
								<span>{(guestDetails.company) ? guestDetails.company : ''}</span>
							</h5>
							<div className="cp_btn_save_dlt">
								{isEditGuestDetails ? (
									<>
										<Button className={`btn_save`} onClick={handleSave}>Save</Button>
									</>
								) : (
									<>
									<Button className={`btn_edit`} onClick={e => setIsEditGuestDetails(true)}>Edit</Button>
									</>
								)}
							</div>
						</div>

						<Form.Group as={Row} className="mb-0" controlId="formHorizontalEmail">
							<Form.Label column sm={4}>Guest List</Form.Label>
							<Col sm={8}>
								<Form.Select
									value={guestDetails?.guest_list_id}
									disabled={!isEditGuestDetails}
									onChange={e => setGuestDetails({
										...guestDetails,
										guest_list_id: parseInt(e.target.value)
									})}
								>
									{lists.map((list, i) => {
										return (
											<option key={i} value={list.id?.toString()}>{list.list_name}</option>
										)
									})}
								</Form.Select>
							</Col>
							<hr className='dvdr' />
						</Form.Group>

						{eventFields.map((field, i) => {

							if (field.field === 'status') {
								return (
									<Form.Group key={field.field} as={Row} className="mb-0" controlId="formHorizontalEmail">
										<Form.Label column sm={4}>Status</Form.Label>
										<Col sm={8}>
											<Form.Select
												value={guestDetails.status}
												disabled={!isEditGuestDetails}
												onChange={e => setGuestDetails({
													...guestDetails,
													status: e.target.value as GuestStatusType
												})}
												style={{
													lineHeight: '16px'
												}}
											>
												{([GuestStatus.CHECKED_IN, GuestStatus.CHECKED_OUT].includes(guestDetails.status)) ? (
													<option value={guestDetails.status}>Checked In</option>
												) : (
													<>
													<option value={GuestStatus.UN_CONFIRMED}>Unconfirmed</option>
													<option value={GuestStatus.CONFIRMED}>Confirmed</option>
													<option value={GuestStatus.CANCELLED}>Cancelled</option>
													</>
												)}
											</Form.Select>
										</Col>
										<hr className='dvdr' />
									</Form.Group>
								)
							} else {
								let val = (guestDetails.hasOwnProperty(field.field) && guestDetails[field.field as keyof Guest] !== undefined) ? guestDetails[field.field as keyof Guest]?.toString() : '';
	
								if (val !== undefined) {
									if (field.type === 'number') {
										val = val.replace(/\D/g, '');
									}
								}
	
								if (!isEditGuestDetails && (val === '' || val === undefined || val === null)) {
									return (<></>);
								} else {
	
									return (
										<Form.Group key={field.field} as={Row} className="mb-0" controlId="formHorizontalEmail">
											<Form.Label column sm={4}>{field.display_name}</Form.Label>
											<Col sm={8}>
												{(field.type === 'dropdown') ? (
													<></>
												) : (
													<Form.Control
														type={(!['date', 'time'].includes(field.type)) ? field.type : 'text'}
														value={val}
														disabled={!isEditGuestDetails}
														onChange={e => setGuestDetails({
															...guestDetails,
															[field.field]: e.target.value
														})}
														inputMode={(field.type === 'number') ? 'numeric' : 'text'}
													/>
												)}
											</Col>
											<hr className='dvdr' />
										</Form.Group>
									)
								}
							}


						})}
					</Form>
				</div>
			</div>

			<div className="media mb-3">
				<div className="media-left col-sm-3">
					{event.has_badge_printing === 1 && (
					<Button className={`cp_print_btn ${isEditGuestDetails ? 'hide' : ''}`} onClick={handlePrint}>
						<i>
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.8 5V3.56C13.8 2.66392 13.8 2.21587 13.6256 1.87362C13.4722 1.57256 13.2274 1.32779 12.9264 1.17439C12.5841 1 12.1361 1 11.24 1H6.76C5.86392 1 5.41587 1 5.07362 1.17439C4.77256 1.32779 4.52779 1.57256 4.37439 1.87362C4.2 2.21587 4.2 2.66392 4.2 3.56V5M4.2 13.8C3.45602 13.8 3.08403 13.8 2.77883 13.7182C1.95061 13.4963 1.3037 12.8494 1.08178 12.0212C1 11.716 1 11.344 1 10.6V8.84C1 7.49587 1 6.82381 1.26158 6.31042C1.49168 5.85883 1.85883 5.49168 2.31042 5.26158C2.82381 5 3.49587 5 4.84 5H13.16C14.5041 5 15.1762 5 15.6896 5.26158C16.1412 5.49168 16.5083 5.85883 16.7384 6.31042C17 6.82381 17 7.49587 17 8.84V10.6C17 11.344 17 11.716 16.9182 12.0212C16.6963 12.8494 16.0494 13.4963 15.2212 13.7182C14.916 13.8 14.544 13.8 13.8 13.8M11.4 7.8H13.8M6.76 17H11.24C12.1361 17 12.5841 17 12.9264 16.8256C13.2274 16.6722 13.4722 16.4274 13.6256 16.1264C13.8 15.7841 13.8 15.3361 13.8 14.44V13.16C13.8 12.2639 13.8 11.8159 13.6256 11.4736C13.4722 11.1726 13.2274 10.9278 12.9264 10.7744C12.5841 10.6 12.1361 10.6 11.24 10.6H6.76C5.86392 10.6 5.41587 10.6 5.07362 10.7744C4.77256 10.9278 4.52779 11.1726 4.37439 11.4736C4.2 11.8159 4.2 12.2639 4.2 13.16V14.44C4.2 15.3361 4.2 15.7841 4.37439 16.1264C4.52779 16.4274 4.77256 16.6722 5.07362 16.8256C5.41587 17 5.86392 17 6.76 17Z" stroke="#2C292C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</i>
						Print
					</Button>
					)}
				</div>
			</div>
		</>
	)
}


const GuestHistory = ({
	event,
	guestData,
}: {
	event: Event
	guestData: Guest
}) => {
	const [guestDetails, setGuestDetails] = useState<Guest>(guestData);
	const [timeline, setTimeline] = useState<GuestTimelineResponse[]>([])

	useEffect(() => {
		(async () => {
			if (event && guestDetails) {
				const response = await apiGetGuestTimeline(event.id, guestDetails.id)
	
				if (response.success) {
					setTimeline([...response.data])
				}
			}
		})();
	}, [guestDetails])


	if (!guestDetails.id) {
		return <></>
	}

	return (
		<div className="cp_user_table">
			<table className="table table-borderless">
				<thead>
					<tr>
						<th>Timeline</th>
						<th>Activity</th>
					</tr>
				</thead>
				<tbody>
					{(timeline.length === 0) && (
						<tr><td colSpan={2} className='text-center text-muted'>no activity available!</td></tr>
					)}
					{timeline.map((log, i) => {
						return (
							<tr key={i}>
								<td>{(log.time) ? dayjs(log.time).format('MMM DD, YYYY h:mm a') : '-'}</td>
								<td>
									{log.activity.replace(/_/g, ' ').toUpperCase()}
									{` `}
									{(log.data && log.data !== null) ? (
										<span className='small text-muted'>- {log.data}</span>
									) : null}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

const AdminGuestDetailsPopup = (props: GuestDetailsPopupTypes) => {

	return (
		<Offcanvas show={true} className="checkedIn" onHide={() => {
			props.handleClose()
		}} placement="end"
		style={{
			zIndex: 99999
		}}
		>
			<Offcanvas.Header closeButton style={{height: 80}}>
				<Offcanvas.Title>{props.isNewGuest ? 'Add Guest' : 'Guest Details'}</Offcanvas.Title>
			</Offcanvas.Header>

			<Offcanvas.Body>
				<div className="cp_main_detail">
					<LargeScreenDesign {...props} />


					{props.isNewGuest ? null : (
						<GuestHistory event={props.event} guestData={props.guestData} />
					)}
				</div>
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default AdminGuestDetailsPopup;