import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ApiParamsBulkBadgePrint, ApiParamsBulkGuestDelete, ApiParamsBulkGuestMoveList, ApiParamsBulkGuestSendEmail, EmailTemplate, EventDataField, Guest, GuestList } from 'types';
import { apiCreateGuestLists, apiDeleteGuestLists, apiGetGuestLists } from 'api/guestLists';
import { setHeaderTitle } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { apiBulkBadgePrint, apiBulkDeleteGuest, apiBulkMoveGuest, apiBulkSendEmailGuest, apiGetGuestByGuestLists, apiGetGuestsByEvent } from 'api/guests';
import { apiGetEventDataFields } from 'api/events';
import { EmailTemplateStatuses, GuestStatus } from 'constant';
import { Badge, ButtonGroup, Dropdown, InputGroup, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdminGuestDetailsPopup from './components/AdminGuestDetails';
import { apiGetTemplates } from 'api/emailTemplate';

let pageUpdated = false;

const GuestListView = () => {
	const dispatch = useAppDispatch();
	const { state }: { state: GuestList | null } = useLocation()
	const { listId } = useParams();
	const activeEvent = useAppSelector(state => state.events.activeEvent);

	const [page, setPage] = useState<number>(1);
	const [limit, setLimit] = useState<number>(20);
	const [orderBy, setOrderBy] = useState<string>('id');
	const [orderType, setOrderType] = useState<string>('DESC');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [searchQueryCheck, setSearchQueryCheck] = useState<string>('');
	const [reloadTable, setReloadTable] = useState<number>(0);
	const [guests, setGuests] = useState<Guest[]>([]);
	const [eventFields, setEventFields] = useState<EventDataField[]>([]);
	const [lists, setLists] = useState<GuestList[]>([]);
	const [templates, setTemplates] = useState<EmailTemplate[]>([]);

	const [viewGuest, setViewGuest] = useState<Guest | null>(null);
	const [addGuestPopup, setAddGuestPopup] = useState<boolean>(false);

	const [allRowSelected, setAllRowSelected] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<number[]>([]);
	const [totalGuest, setTotalGuest] = useState<number | string>(0);

	const [confirmGuestDelete, setConfirmGuestDelete] = useState<boolean>(false);
	const [confirmMoveList, setConfirmMoveList] = useState<boolean>(false);
	const [moveListId, setMoveListId] = useState<string | null>(null);
	const [confirmSendEmail, setConfirmSendEmail] = useState<boolean>(false);
	const [emailTemplateId, setEmailTemplateId] = useState<string | number | undefined | null>(null);
	const [sendingEmails, setSendingEmails] = useState<boolean>(false);
	const [isBulkPrinting, setIsBulkPrinting] = useState<boolean>(false);

	const [visibleCols, setVisibleCols] = useState<string[]>([]);

	const guestTableRef = useRef<HTMLDivElement | null>(null);

	const tableColStyle = {
		width: `${(100 / (eventFields.filter(f => visibleCols.includes(f.field)).length + 1))}%`,
	}

	// console.log({activeEvent, listId})

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Guest List'))
		})
	}, [])

	useEffect(() => {
		setEmailTemplateId(null);
	}, [confirmSendEmail])
	
	const handleScroll = () => {
		if (guestTableRef && guestTableRef.current && !pageUpdated) {
			const scrollY = guestTableRef.current.scrollTop;
			const windowHeight = guestTableRef.current.clientHeight;
			const documentHeight = guestTableRef.current.scrollHeight;
			if (scrollY + windowHeight >= documentHeight - 100) {
				pageUpdated = true;
				setPage(prev => {
					return prev + 1;
				});
			}
			// console.log({
			// 	scrollY,
			// 	windowHeight,
			// 	documentHeight,
			// 	plus: (scrollY + windowHeight),
			// 	condition: (scrollY + windowHeight >= documentHeight - 100),
			// })
		}
	};

	useEffect(() => {
		let refForCleanUp: HTMLElement | null = null;
		if (guestTableRef && guestTableRef.current) {
			guestTableRef.current.addEventListener('scroll', handleScroll);
			refForCleanUp = guestTableRef.current;
		}
		return () => {
			if (refForCleanUp) {
				refForCleanUp.removeEventListener('scroll', handleScroll);
			}
		};
	}, [guestTableRef]);

	// load guest list from api
	useEffect(() => {

		(async () => {
			if (activeEvent) {
				if (listId) {
					const response = await apiGetGuestByGuestLists(activeEvent.id, listId, {
						page,
						limit,
						orderBy,
						orderType,
						q: searchQueryCheck
					})
	
					if (response.success) {
						
						setGuests([...guests, ...response.data.items])
						setTotalGuest(response.data.meta_data.total)

						pageUpdated = false;
					}
				} else {
					const response = await apiGetGuestsByEvent(activeEvent.id, {
						page,
						limit,
						orderBy,
						orderType,
						q: searchQueryCheck
					})
	
					if (response.success) {
						
						setGuests([...guests, ...response.data.items])
						setTotalGuest(response.data.meta_data.total)
						
						pageUpdated = false;
					}
				}
			}
		})()

	}, [activeEvent, listId, page, limit, orderBy, orderType, searchQueryCheck, reloadTable])

	// check search
	useEffect(() => {
		if (searchQuery === '') {
			batch(() => {
				setPage(1);
				setGuests([]);
				setSearchQueryCheck('');
			});
		} else {
			if (searchQuery.length > 1) {
				batch(() => {
					setPage(1);
					setGuests([]);
					setSearchQueryCheck(searchQuery);
				})
			}
		}
	}, [searchQuery])

	// guest lists
	useEffect(() => {

		(async () => {
			if (activeEvent) {
				const response = await apiGetGuestLists(activeEvent.id)
	
				if (response.success) {
					setLists(response.data.lists)
				}
			}
		})();

	}, [activeEvent])

	// get event printer labels
	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const respEventFields = await apiGetEventDataFields(activeEvent.id);

				if (respEventFields.success) {
					setEventFields([...respEventFields.data.filter(f => f.show_in_app === 1)])

					const checkVisibleColSettings = localStorage.getItem(`@visibleCols:guestListView:${activeEvent.id}`)
					if (checkVisibleColSettings) {
						try {
							setVisibleCols([...JSON.parse(checkVisibleColSettings)])
						} catch(e) {
							console.log(e)
						}
					} else {
						setVisibleCols([...respEventFields.data.filter(f => f.show_in_app === 1).filter(f => f.is_visible === 1).map(field => field.field)])
					}
				}
			}
		})();
	}, [activeEvent])

	useEffect(() => {
		console.log(visibleCols)

		if (activeEvent && visibleCols.length) {
			localStorage.setItem(`@visibleCols:guestListView:${activeEvent.id}`, JSON.stringify(visibleCols))
		}
	}, [visibleCols])

	// email templates
	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const response = await apiGetTemplates(activeEvent.id)
	
				if (response.success) {
					setTemplates([...response.data.filter(template => template.status === EmailTemplateStatuses.ACTIVE)])
				}
			}
		})();
	}, [activeEvent])

	useEffect(() => {
		setAllRowSelected(false)
	}, [selectedRows])


	const handleDeleteGuest = async () => {
		if (!allRowSelected && selectedRows.length === 0) {
			return;
		}

		if (activeEvent) {
			const data: ApiParamsBulkGuestDelete = {
				allGuests: allRowSelected ? 1 : 0,
				guestIds: (allRowSelected) ? null : selectedRows,
				listId: listId ? listId : null
			}
	
			const response = await apiBulkDeleteGuest(activeEvent.id, data)
		
			if (response.success) {
				toast.success(response.message)
				batch(() => {
					setConfirmGuestDelete(false);
					setAllRowSelected(false);
					setSelectedRows([]);

					setPage(1);
					setGuests([]);
					setSearchQueryCheck('');
					setReloadTable(prev => prev + 1);
				});
			} else {
				toast.error(response.message)
			}
		}
	}

	const handleMoveGuest = async () => {
		if (!allRowSelected && selectedRows.length === 0) {
			return;
		}

		if (activeEvent) {
			const data: ApiParamsBulkGuestMoveList = {
				moveListId: moveListId,
				allGuests: allRowSelected ? 1 : 0,
				guestIds: (allRowSelected) ? null : selectedRows,
				listId: listId ? listId : null,
			}
	
			const response = await apiBulkMoveGuest(activeEvent.id, data)
		
			if (response.success) {
				toast.success(response.message)
				batch(() => {
					setConfirmMoveList(false);
					setAllRowSelected(false);
					setSelectedRows([]);

					setPage(1);
					setGuests([]);
					setSearchQueryCheck('');
					setReloadTable(prev => prev + 1);
				});
			} else {
				toast.error(response.message)
			}
		}
	}

	const handleSendEmails = async () => {
		if (!allRowSelected && selectedRows.length === 0) {
			return;
		}

		if (activeEvent && emailTemplateId) {
			const data: ApiParamsBulkGuestSendEmail = {
				templateId: emailTemplateId,
				allGuests: allRowSelected ? 1 : 0,
				guestIds: (allRowSelected) ? null : selectedRows,
				listId: listId ? listId : null,
			}
	
			setSendingEmails(true)
			const response = await apiBulkSendEmailGuest(activeEvent.id, data)
		
			if (response.success) {
				toast.success(response.message)
				batch(() => {
					setSendingEmails(false)
					setConfirmSendEmail(false);
					setEmailTemplateId(null)
					setAllRowSelected(false);
					setSelectedRows([]);

					setPage(1);
					setGuests([]);
					setSearchQueryCheck('');
					setReloadTable(prev => prev + 1);
				});
			} else {
				toast.error(response.message)
				setSendingEmails(false)
			}
		}
	}

	const handleBulkBadgePrint = async () => {
		if (!allRowSelected && selectedRows.length === 0) {
			return;
		}

		if (activeEvent) {
			const data: ApiParamsBulkBadgePrint = {
				allGuests: allRowSelected ? 1 : 0,
				guestIds: (allRowSelected) ? null : selectedRows,
				listId: listId ? listId : null,
			}
	
			setIsBulkPrinting(true)
			const response = await apiBulkBadgePrint(activeEvent.id, data)
		
			setIsBulkPrinting(false)
			if (response.success) {
				toast.success(response.message)
				setTimeout(() => {
					window.open(response.data)
				}, 600)

			} else {
				toast.error(response.message)
			}
		}
	}


	if (!activeEvent) {
		return <Navigate to="/" />
	}

	const allColButton = React.forwardRef(({ children, onClick }: any, ref) => (
		<Button type='button' variant='' 
		onClick={(e) => {
		  e.preventDefault();
		  onClick(e);
		}}>
			<svg width="15px" height="15px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M4.99997 0.757359V9.24264M0.757324 5H9.24261" stroke="#555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
			</svg>
		</Button>
	  ));

	return (
		<>
		<div className="page-guest-list-view">
			<div className="page-card card">
				<div className="card-header bg-white p-3">{state ? state.list_name : 'All Guests'}</div>
				<div className="card-body">
					<div className="list-toolbar" style={{ display: 'flex', gap: '1rem' }}>
						<Button type='button' variant='outline-dark' onClick={() => setAddGuestPopup(true)}>
							<svg width="10px" height="10px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
								<path d="M4.99997 0.757359V9.24264M0.757324 5H9.24261" stroke="#555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
							</svg>
							<span>Add Guest</span>
						</Button>

						<InputGroup className="" style={{ maxWidth: '300px'}}>
							<InputGroup.Text id="basic-addon1">
								<svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z" stroke="#2C292C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</InputGroup.Text>
							<Form.Control
								placeholder="Search Guests"
								aria-label="search"
								aria-describedby="search-guests"
								value={searchQuery}
								onChange={e => setSearchQuery(e.target.value)}
							/>
						</InputGroup>

						<Button type='button' variant='dark'
							disabled={!(allRowSelected || selectedRows.length)}
							onClick={() => setConfirmSendEmail(true)}
						>
							Send Email {(allRowSelected) ? `(${totalGuest})` : selectedRows.length ? `(${selectedRows.length})` : ''}
						</Button>
						<Button type='button' variant='dark' 
							disabled={!(allRowSelected || selectedRows.length)}
							onClick={() => setConfirmMoveList(true)}
						>
							Move to {(allRowSelected) ? `(${totalGuest})` : selectedRows.length ? `(${selectedRows.length})` : ''}
						</Button>
						<Button type='button' variant='dark' 
							// disabled={!(allRowSelected || selectedRows.length)}
							disabled={true} // PH003-17 - Put it on hold
						>
							Export {(allRowSelected) ? `(${totalGuest})` : selectedRows.length ? `(${selectedRows.length})` : ''}
						</Button>
						<Button type='button' variant='dark' 
							disabled={!(allRowSelected || selectedRows.length) || isBulkPrinting}
							onClick={handleBulkBadgePrint}
						>
							Generate Badges {(allRowSelected) ? `(${totalGuest})` : selectedRows.length ? `(${selectedRows.length})` : ''}
						</Button>
						<Button type='button' variant='dark' 
							disabled={!(allRowSelected || selectedRows.length)}
							onClick={() => setConfirmGuestDelete(true)}
						>
							Delete {(allRowSelected) ? `(${totalGuest})` : selectedRows.length ? `(${selectedRows.length})` : ''}
						</Button>
					</div>
				</div>
				<div className="" ref={guestTableRef} style={{ height: 'calc(100vh - 231px)', overflow: 'auto' }}>
					<Table responsive bordered hover >
						<thead>
							<tr>
								<th className='text-center ps-4'>
									<div className="cp_checkbox">
										<Form.Group>
											<Form.Check id="check-all" className="p-0 m-0" type="checkbox" name="check-all" checked={allRowSelected} label="" onChange={() => setAllRowSelected(!allRowSelected)}/>
										</Form.Group>
									</div>
								</th>
								{eventFields.filter(f => visibleCols.includes(f.field)).map((field, i) => {
									return (
										<th key={i} style={tableColStyle} 
											className={`${(field.field === 'guest_name' || field.field === 'company') ? 'large-col' : ''} ${(field.field === orderBy) ? ((orderType === 'ASC') ? 'sort-asc' : 'sort-desc') : ''}`}
										>
											<div className="d-flex justify-content-between align-items-center">
												<span>{field.display_name}</span>
												
												<Dropdown as={ButtonGroup}>
													<Dropdown.Toggle variant="" id={`dropdown-col-opts-${field.id}`} />
													<Dropdown.Menu>
														<button type='button' className='dropdown-item'
														onClick={e => {
															console.log('ASC', orderBy, field.field, orderType)
															if (orderBy === field.field && orderType === 'ASC') {
																return
															}
															batch(() => {
																setPage(1);
																setGuests([]);
																setOrderType('ASC');
																setOrderBy(field.field);
															})
														}}
														>
															<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
																<path fill-rule="evenodd" clip-rule="evenodd" d="M17 20.75C17.4142 20.75 17.75 20.4143 17.75 20V6.25005L19.4 8.45005C19.6485 8.78142 20.1186 8.84858 20.45 8.60005C20.7814 8.35152 20.8485 7.88142 20.6 7.55005L17.6 3.55005C17.4063 3.29179 17.0691 3.18645 16.7628 3.28854C16.4566 3.39062 16.25 3.67723 16.25 4.00005V20C16.25 20.4143 16.5858 20.75 17 20.75Z" fill="#1C274C"/>
																<path d="M3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H13C13.4142 8.75 13.75 8.41421 13.75 8C13.75 7.58579 13.4142 7.25 13 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8Z" fill="#1C274C"/>
																<path opacity="0.7" d="M5.25 13C5.25 13.4142 5.58579 13.75 6 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13C13.75 12.5858 13.4142 12.25 13 12.25H6C5.58579 12.25 5.25 12.5858 5.25 13Z" fill="#1C274C"/>
																<path opacity="0.4" d="M7.25 18C7.25 18.4142 7.58579 18.75 8 18.75H13C13.4142 18.75 13.75 18.4142 13.75 18C13.75 17.5858 13.4142 17.25 13 17.25H8C7.58579 17.25 7.25 17.5858 7.25 18Z" fill="#1C274C"/>
															</svg>
															Sort ascending
														</button>

														<button type='button' className='dropdown-item'
														onClick={e => {
															if (orderBy === field.field && orderType === 'DESC') {
																return
															}
															batch(() => {
																setPage(1);
																setGuests([]);
																setOrderType('DESC');
																setOrderBy(field.field);
															})
														}}
														>
															<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
																<path fill-rule="evenodd" clip-rule="evenodd" d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V17.75L19.4 15.55C19.6485 15.2186 20.1186 15.1515 20.45 15.4C20.7814 15.6485 20.8485 16.1186 20.6 16.45L17.6 20.45C17.4063 20.7083 17.0691 20.8136 16.7628 20.7115C16.4566 20.6094 16.25 20.3228 16.25 20V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="#1C274C"/>
																<path d="M3.25 16C3.25 15.5858 3.58579 15.25 4 15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H4C3.58579 16.75 3.25 16.4142 3.25 16Z" fill="#1C274C"/>
																<path opacity="0.7" d="M5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z" fill="#1C274C"/>
																<path opacity="0.4" d="M7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H13C13.4142 5.25 13.75 5.58579 13.75 6C13.75 6.41421 13.4142 6.75 13 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6Z" fill="#1C274C"/>
															</svg>
															Sort descending
														</button>

														<Dropdown.Divider />

														<button type='button' className='dropdown-item'
														onClick={() => {
															setEventFields([...eventFields.map(ef => {
																if (ef.id === field.id) {
																	ef.is_visible = 0
																}
	
																return ef;
															})])
															setVisibleCols([...visibleCols.filter(f => f !== field.field)])
														}}
														>
															<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
																<path d="M3 3L6 3M3 21L6 21M6 21L10 21C11.1046 21 12 20.1046 12 19L12 9M6 21L6 9M6 3L10 3C11.1046 3 12 3.89543 12 5L12 9M6 3L6 9M3 9L6 9M6 9L12 9M3 15L6 15L12 15" stroke="#777777" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
																<path d="M15 9L18 12M18 12L21 15M18 12L21 9M18 12L15 15" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
															</svg>
															Hide column
														</button>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</th>
									)
								})}

								{eventFields.filter(f =>  !visibleCols.includes(f.field)).length ? (
								<th className='' style={tableColStyle}>
									<Dropdown>
										<Dropdown.Toggle as={allColButton} id="dropdown-show-cols"></Dropdown.Toggle>

										<Dropdown.Menu>
											{eventFields.filter(f => !visibleCols.includes(f.field)).map((f, j) => {
												return (
													<button key={f.id} type='button' className='dropdown-item'
													onClick={() => {
														setEventFields([...eventFields.map(ef => {
															if (ef.id === f.id) {
																ef.is_visible = 1
															}

															return ef;
														})])
														setVisibleCols([...visibleCols, f.field])
													}}
													>
														<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
															<path d="M3 3L6 3M3 21L6 21M6 21L10 21C11.1046 21 12 20.1046 12 19L12 9M6 21L6 9M6 3L10 3C11.1046 3 12 3.89543 12 5L12 9M6 3L6 9M3 9L6 9M6 9L12 9M3 15L6 15L12 15" stroke="#777777" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
															<path d="M15 12L18 12M18 12L21 12M18 12L18 15M18 12L18 9" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
														</svg>
														{f.display_name}
													</button>
												)
											})}
										</Dropdown.Menu>
									</Dropdown>
								</th>
								) : null}
							</tr>
						</thead>

						<tbody>
							{(guests.length === 0) && (
								<tr>
									<td className='text-center text-muted' colSpan={(eventFields.filter(f => visibleCols.includes(f.field)).length + 1)}>No guest found...</td>
								</tr>
							)}
							{guests.map((guest, i) => {
								return (
									<tr 
										key={guest.id} 
										className={`${(guest.status === GuestStatus.CHECKED_IN) ? 'active' : ''} ${(allRowSelected || selectedRows.includes(guest.id)) ? 'selected' : ''}`} 
										>

										<td className='text-center ps-4' >
											<div className="cp_checkbox">
												<Form.Group>
													<Form.Check 
														id={`check-${guest.id}`} 
														className="p-0 m-0" 
														type="checkbox" 
														name={`check-${guest.id}`} 
														label="" 
														checked={allRowSelected || selectedRows.includes(guest.id)}
														onChange={() => {
															if (selectedRows.includes(guest.id)) {
																setSelectedRows([...selectedRows.filter(id => id !== guest.id)])
															} else {
																setSelectedRows([...selectedRows, guest.id])
															}
														}}
													/>
												</Form.Group>
											</div>
										</td>
										{eventFields.filter(f => visibleCols.includes(f.field)).map((f, j) => {
											if (f.field === 'status') {
												return (
													<td 
													key={`${guest.id}_${j}`}
													style={{
														...tableColStyle,
														maxWidth: '200px'
													}}
													onClick={() => setViewGuest({...guest})}
													>
													<Badge bg='secondary' pill={true}>
														{(guest.status === GuestStatus.CONFIRMED) ? 'Confirmed' : 
															(guest.status === GuestStatus.CANCELLED) ? 'Cancelled' : 
																(guest.status === GuestStatus.CHECKED_IN) ? 'Checked In' : 
																	(guest.status === GuestStatus.CHECKED_OUT) ? 'Checked Out' : 'Unconfirmed'
														}
														</Badge>
													</td>
												)
											} else {
												return (
													<td 
													key={`${guest.id}_${j}`} 
													style={tableColStyle} 
													className={(f.field === 'guest_name' || f.field === 'company') ? 'large-col' : ''}
													onClick={() => setViewGuest({...guest})}
													>
														{(guest.hasOwnProperty(f.field) && guest[f.field as keyof Guest]) ? guest[f.field as keyof Guest] : '-'}
													</td>
												)
											}
										})}

										{eventFields.filter(f => !visibleCols.includes(f.field)).length ? (
										<td style={tableColStyle}></td>
										) : null}
									</tr>
								)
							})}
							

						</tbody>
					</Table>
				</div>
			</div>


			{(confirmGuestDelete) && (
			<Modal aria-labelledby="contained-modal-title-vcenter" centered show={confirmGuestDelete} onHide={() => setConfirmGuestDelete(false)} className=''>
				<Modal.Header closeButton>
					<Modal.Title>Delete Guest</Modal.Title>
				</Modal.Header>

				<Modal.Body className='text-center'>
					<p>
					Are you sure you want to delete {(allRowSelected) ? `${totalGuest}` : selectedRows.length ? `${selectedRows.length}` : ''} guest?
					</p>

					<p className="mb-2 text-danger"><strong>This cannot be undone</strong></p>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setConfirmGuestDelete(false)} style={{ flexGrow: 1 }}>
						Cancel
					</Button>

					<Button variant="danger" onClick={handleDeleteGuest} style={{ flexGrow: 1 }}>
						Yes, delete
					</Button>
				</Modal.Footer>
			</Modal>
			)}


			{(confirmMoveList) && (
			<Modal aria-labelledby="contained-modal-title-vcenter" centered show={confirmMoveList} onHide={() => setConfirmMoveList(false)} className=''>
				<Modal.Header closeButton>
					<Modal.Title>Move To Guest List</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form.Group className="mb-2" controlId="formHorizontalEmail">
						<Form.Label >Choose Guest List</Form.Label>
						<Form.Select
							value={moveListId ? moveListId : undefined}
							onChange={e => setMoveListId(e.target.value)}
						>
							<option value=''>-select-</option>
							{lists.map((list, i) => {
								if (listId && list.id?.toString() === listId.toString()) {
									return null;
								}
								return (
									<option key={i} value={list.id?.toString()}>{list.list_name}</option>
								)
							})}
						</Form.Select>
					</Form.Group>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setConfirmMoveList(false)} style={{ width: 'auto', flexGrow: 1 }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleMoveGuest} style={{ width: 'auto', flexGrow: 1.5 }}>
						<span>Move {(allRowSelected) ? `(${totalGuest})` : selectedRows.length ? `(${selectedRows.length})` : ''} Guest</span>
					</Button>
				</Modal.Footer>
			</Modal>
			)}
			
			{(confirmSendEmail) && (
			<Modal aria-labelledby="contained-modal-title-vcenter" centered show={confirmSendEmail} onHide={() => setConfirmSendEmail(false)} className=''>
				<Modal.Header closeButton>
					<Modal.Title>Send Email</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form.Label className='mb-4'>Choose Email Template</Form.Label>
					{(templates.length === 0) ? (
						<>
						<p className='text-muted'>No template available!</p>
						</>
					) : null}
					{templates.map(template => {
						return (
							<div key={template.id} className='mb-2'>
								<div className="cp_checkbox">
									<Form.Group>
										<Form.Check 
											className="p-0 m-0" 
											type="radio"
											id={`selected-template-${template.id}`}
											name={`selected-template`}
											label={template.name}
											onChange={() => setEmailTemplateId(template.id)}
										/>
									</Form.Group>
								</div>
								<Link to={`/email-templates/${template.id}/preview`} className='text-dark ps-4 small' style={{ display: 'block' }}>View</Link>
							</div>
						)
					})}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setConfirmSendEmail(false)} style={{ width: 'auto', flexGrow: 1 }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSendEmails} style={{ width: 'auto', flexGrow: 1.5 }} disabled={(templates.length === 0 || emailTemplateId === null || sendingEmails)}>
						{(sendingEmails) ? (
							<span>Sending...</span>
						) : (
							<span>Send Email to {(allRowSelected) ? `(${totalGuest})` : selectedRows.length ? `(${selectedRows.length})` : ''} Guest</span>
						)}
					</Button>
				</Modal.Footer>
			</Modal>
			)}



			{(viewGuest !== null) ? (
				<AdminGuestDetailsPopup 
					event={activeEvent}
					guestData={viewGuest}
					eventFields={eventFields}
					lists={lists}
					handleSaveGuest={(data) => {
						setGuests([...guests.map(g => {
							if (g.id === data.id) {
								return {...g, ...data}
							}
							return g
						})])
					}}
					handleClose={() => setViewGuest(null)}
					isNewGuest={false}
				/>
			) : null}


			{(addGuestPopup) ? (
				<AdminGuestDetailsPopup 
					event={activeEvent}
					guestData={{
						id: null,
						status: GuestStatus.CONFIRMED,
						guest_list_id: (listId) ? listId : lists[0].id,
					} as any}
					eventFields={eventFields}
					lists={lists}
					handleSaveGuest={(data) => {
						setGuests([{...data}, ...guests])
					}}
					handleClose={() => setAddGuestPopup(false)}
					isNewGuest={true}
				/>
			) : null}
		</div>
		</>
	)
}

export default GuestListView;