import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import AdminRoutes from './AdminRoutes';
import UserRoutes from './UserRoutes';
import useWindowDimensions from 'hooks/useWindowDimensions';

const PrivateRoutes = (): JSX.Element => {
	const loggedIn = useAppSelector(state => state.auth.loggedIn);
	const userData = useAppSelector(state => state.auth.userData);
	const dispatch = useAppDispatch();
	const {width} = useWindowDimensions();

	if (!loggedIn || !userData) {
		return <Navigate to="/login" />
	}

	return (userData.roleName === 'admin' && width >= 1200) ? <AdminRoutes /> : <UserRoutes />;
	
}

export default React.memo(PrivateRoutes);