import React, { useContext, useState } from "react";
import { useNavigate, Link, Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import Button from 'react-bootstrap/Button';

import Footer from 'views/User/components/Footer'
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { EventCardStyle } from "constant";
import { setEventCardStyle } from "redux/interfaceManger";
import SelectDevicePopup from "views/User/components/SelectDevicePopup";
import BulkBadgePrintPopup from "views/User/components/BulkBadgePrintPopup";




const UserLayout = () => {
	const dispatch = useAppDispatch();
	const headerTitle = useAppSelector(state => state.interfaceManger.headerTitle);
	const eventCardStyle = useAppSelector(state => state.interfaceManger.eventCardStyle);
	const selectDevicePopup = useAppSelector(state => state.interfaceManger.selectDevicePopup);
	const bulkBadgePrintPopup = useAppSelector(state => state.interfaceManger.bulkBadgePrintPopup);
	const isOnline = useAppSelector(s => s.interfaceManger.isOnline);

    const pathname = window.location.pathname;
	const navigate = useNavigate();


	// console.log({headerTitle})
	return (
		
		<>
			

			<header>
				<div className="container-fluid">
					{
						(['/', '/dashboard'].includes(pathname)) ? 
						(<div className="header-logo">
							<Link to={`/`}>
								<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5.34714 29.9901C5.15065 29.9908 4.95671 29.9456 4.78075 29.8581C4.60478 29.7707 4.45164 29.6434 4.33348 29.4864C4.21533 29.3294 4.13543 29.147 4.10014 28.9538C4.06484 28.7605 4.07512 28.5616 4.13016 28.373L5.84319 22.4204H15.5525C18.2577 22.4204 21.0918 20.2444 21.8689 17.5723L25.209 6.10031H26.0291C26.3951 6.08236 26.7601 6.15155 27.0941 6.30218C27.4282 6.4528 27.7217 6.68057 27.9505 6.96675C28.176 7.29753 28.3205 7.67677 28.3722 8.07377C28.4239 8.47077 28.3814 8.87437 28.2481 9.25189L24.5377 22.0169C24.0085 23.8358 22.1037 25.3239 20.2882 25.3239H13.4328C13.0685 25.3254 12.7145 25.4442 12.4231 25.6629C12.1318 25.8815 11.9188 26.1883 11.8156 26.5376L11.0749 29.0873C10.9966 29.3504 10.8357 29.5813 10.6159 29.7457C10.396 29.9101 10.1291 29.9993 9.85458 30L5.34714 29.9901Z" fill="#ACA6A6"/>
									<path d="M10.1787 10.3961L8.80629 9.07993L7.67529 10.2572L10.1952 12.6779L15.6915 7.23131L14.5439 6.07056L10.1787 10.3961Z" fill="#ACA6A6"/>
									<path d="M22.1668 0.868623C21.9393 0.584306 21.6479 0.357666 21.3163 0.207118C20.9848 0.0565691 20.6224 -0.0136419 20.2586 0.00218902H8.10538C6.29315 0.00218902 4.38501 1.48042 3.85589 3.30919L0.145434 16.0643C0.0121546 16.4418 -0.0303519 16.8454 0.0213557 17.2424C0.0730632 17.6394 0.217532 18.0187 0.443064 18.3494C0.672229 18.6361 0.966261 18.8641 1.3009 19.0148C1.63555 19.1654 2.00121 19.2343 2.36774 19.2159H14.5177C16.3332 19.2159 18.2413 17.7343 18.7705 15.9089L20.0933 11.5039H16.3993L15.3014 15.1813H4.11053L7.45391 3.63989H18.6415L17.4179 7.97868H21.1151L22.4776 3.15376C22.6106 2.77549 22.6518 2.37102 22.5977 1.97371C22.5437 1.5764 22.396 1.19763 22.1668 0.868623Z" fill="white"/>
								</svg>
							</Link>
						</div>) :
						(<Button type="button" variant="header-back"
							onClick={() => {
								navigate(-1)
							}}
						>
							<svg width="20" height="20" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</Button>)
					}
					
					<div className="row align-items-center">
						<div className="col-md-12 col-8 offset-2 offset-md-0">
							<p>{headerTitle}</p>
						</div>
					</div>
					{
						(['/', '/dashboard'].includes(pathname)) ?
						<div className="toggle-event-style">
							<Button type="button" variant="event-toggle-btn" 
								className={`me-1 ${(eventCardStyle === EventCardStyle.LIST) ? 'is-active' : ''}`}
								onClick={() => {
									dispatch(setEventCardStyle(EventCardStyle.LIST))
								}}
							>
								<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 14.3333H21M1 7.66667L21 7.66667M1 15.6667L1 6.33333C1 4.46649 1 3.53307 1.36331 2.82003C1.68289 2.19282 2.19282 1.68289 2.82003 1.36331C3.53307 0.999999 4.46649 0.999999 6.33333 0.999999H15.6667C17.5335 0.999999 18.4669 0.999999 19.18 1.36331C19.8072 1.68289 20.3171 2.19282 20.6367 2.82003C21 3.53307 21 4.46649 21 6.33333V15.6667C21 17.5335 21 18.4669 20.6367 19.18C20.3171 19.8072 19.8072 20.3171 19.18 20.6367C18.4669 21 17.5335 21 15.6667 21H6.33333C4.46649 21 3.53307 21 2.82003 20.6367C2.19282 20.3171 1.68289 19.8072 1.36331 19.18C1 18.4669 1 17.5335 1 15.6667Z" 
									stroke={(eventCardStyle === EventCardStyle.LIST) ? '#4D474D' : '#EDE7ED'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</Button>
							<Button type="button" variant="event-toggle-btn" 
								className={`${(eventCardStyle === EventCardStyle.GRID) ? 'is-active' : ''}`}
								onClick={() => {
									dispatch(setEventCardStyle(EventCardStyle.GRID))
								}}
							>
								<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 11H21M11 1V21M6.33333 1H15.6667C17.5335 1 18.4669 1 19.18 1.36331C19.8072 1.68289 20.3171 2.19282 20.6367 2.82003C21 3.53307 21 4.46649 21 6.33333V15.6667C21 17.5335 21 18.4669 20.6367 19.18C20.3171 19.8072 19.8072 20.3171 19.18 20.6367C18.4669 21 17.5335 21 15.6667 21H6.33333C4.46649 21 3.53307 21 2.82003 20.6367C2.19282 20.3171 1.68289 19.8072 1.36331 19.18C1 18.4669 1 17.5335 1 15.6667V6.33333C1 4.46649 1 3.53307 1.36331 2.82003C1.68289 2.19282 2.19282 1.68289 2.82003 1.36331C3.53307 1 4.46649 1 6.33333 1Z" 
									stroke={(eventCardStyle === EventCardStyle.GRID) ? '#4D474D' : '#EDE7ED'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</Button>
						</div> : (
							<>
							{!isOnline ? (
								<div className="badge offline-badge">
									<span className="dot"></span>
									<span className="label">Offline</span>
								</div>
							) : null}
							</>
						)
					}
				</div>
			</header>

			<section className="cp_main_app_bx">

				<div className="cp_events_bx">

					<Outlet />
				</div>
			</section>

			<Footer />

			{selectDevicePopup && ( <SelectDevicePopup /> )}

			{bulkBadgePrintPopup && ( <BulkBadgePrintPopup /> )}

			<ToastContainer 
				position="top-right"
				autoClose={5000}
			/>
		</>
	)
};

export default React.memo(UserLayout);