import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { EmailTemplate, EmailTemplateStatus, EmailTemplateType } from 'types';
import { ButtonGroup, Form, InputGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { batch } from 'react-redux';
import { apiCreateTemplate, apiSendTestEmails, apiUpdateTemplate } from 'api/emailTemplate';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { apiGetGuestsByEvent } from 'api/guests';
import { MultiValue } from 'react-select';
import { apiGetTeam } from 'api/team';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/hooks';

type TestEmailPopupProps = {
	eventId: number | string;
	template: EmailTemplate;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

type SelectOptions = {
	value: string,
	label: string,
	__isNew__?: boolean
}

const TestEmailPopup = ({ eventId, template, isOpen, setIsOpen }: TestEmailPopupProps) => {
	const userData = useAppSelector(state => state.auth.userData);
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [previewGuestId, setPreviewGuestId] = useState<string | number | null>(null);
	const [defaultEmails, setDefaultEmails] = useState<SelectOptions[]>([])
	const [selectedEmails, setSelectedEmails] = useState<MultiValue<SelectOptions>>([]);

	useEffect(() => {
		(async () => {
			const response = await apiGetTeam()

			if (response.success) {
				let opts: SelectOptions[] = [];

				if (userData) {
					opts.push({
						value: userData.email,
						label: `${userData.name} (${userData.email})`
					})
				}

				response.data.forEach(member => {
					if (member.is_accepted === 1 && member.member.email && validateEmail(member.member.email)) {
						opts.push({
							value: member.member.email,
							label: (member.member.name) ? `${member.member.name} (${member.member.email})` : member.member.email
						})
					}
				})

				if (opts.length) {
					setDefaultEmails([...opts]);
				}
			}
		})()
	}, [])

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const searchGuests = async (inputValue: string) => {
		return new Promise<any[]>(async (resolve) => {
			if (eventId) {
				const response = await apiGetGuestsByEvent(eventId, {
					page: 1,
					limit: 10,
					orderBy: 'id',
					orderType: 'DESC',
					q: inputValue
				})
	
				if (response.success) {
					resolve([...response.data.items.map(guest => {
						let name = (guest.guest_name) ? guest.guest_name : `${guest.first_name || ''} ${guest.last_name || ''}`;
						return {
							value: guest.id,
							label:  `${name} (${(guest.email) ? guest.email : 'N/A'})`
						}
					})])
				}
			}
			else {
				resolve([])
			}
		});
	}

	const handleSubmit = async () => {

		if (previewGuestId === null || selectedEmails.length === 0) {
			return;
		}

		const data = {
			eventId,
			templateId: template.id,
			guestId: previewGuestId,
			emails: selectedEmails.map(e => e.value),
		}

		console.log(data)

		if (template && template.id) {
			setIsLoading(true);
			const response = await apiSendTestEmails(eventId, template.id, previewGuestId, selectedEmails.map(e => e.value))
			setIsLoading(false);
			if (response.success) {
				toast.success(response.message)
				setIsOpen(false)
			} else {
				toast.error(response.message)
			}
		}
	}
	
	return (
		<>
			<Modal show={isOpen} className="createNewEmailTemplateModal" size='lg' onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>Send Test Email</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{errMsg && <div className="alert alert-danger">{errMsg}</div>}
					
					<Form.Group className="mb-5" controlId="selectEmails">
						<Form.Label>Send test email to:</Form.Label>
						<CreatableSelect 
							isClearable={true}
							isMulti={true}
							options={defaultEmails} 
							onChange={e => {
								console.log(e)
								setSelectedEmails(e)
							}}
						/>
					</Form.Group>

					<Row>
						<Col md={8}>
							<Form.Group className="" controlId="selectGuest">
								<Form.Label>Select Guest:</Form.Label>
								<p className='text-muted small'>You can preview and send this guest's personalized email to the address(es) entered above. The guest will not receive the test email.</p>
								<InputGroup className="">
									<div style={{ flex: 1 }}>
										{(previewGuestId === null) ? (
											<AsyncSelect 
												cacheOptions 
												defaultOptions
												value={null}
												loadOptions={searchGuests} 
												onChange={e => {
													setPreviewGuestId(e.value)
												}}
											/>
										) : (
											<AsyncSelect 
												cacheOptions 
												defaultOptions 
												loadOptions={searchGuests} 
												onChange={e => {
													setPreviewGuestId(e.value)
												}}
											/>
										)}
									</div>

									{(previewGuestId !== null) ? (
									<Button variant="outline-secondary" onClick={() => setPreviewGuestId(null)}>
									Cancel
									</Button>
									) : null}
								</InputGroup>
								
							</Form.Group>
						</Col>
					</Row>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setIsOpen(false)} style={{ width: 'auto', flexGrow: 1, padding: '1rem' }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSubmit} style={{ width: 'auto', flexGrow: 1.8, padding: '1rem' }} disabled={isLoading}>
						<div className='d-flex align-items-center justify-content-between'>
							<span>Send Test Email(s)</span>
							<span className="arrow">
								<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default TestEmailPopup;