import { useLocation } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import Sidebar from 'views/User/components/Sidebar';
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setShowScanner, toggleAddGuestPopup } from "redux/interfaceManger";



const Footer = () => {
	let location = useLocation();
	const dispatch = useAppDispatch();
	const footerMessage = useAppSelector(state => state.interfaceManger.footerMessage);


	return (

		<>
			<footer>
				<div className="container-fluid">
					<Row className="align-items-center">
						<Col xs="3">
							<Sidebar />
						</Col>
						<Col xs="6" className="text-center">
						{(location.pathname.indexOf('/guest/') === 0 || location.pathname.indexOf('/guests/') === 0) && (
							<>
								{footerMessage}
							</>
						)}
						</Col>
						<Col xs="3" className="text-end">
							{(location.pathname.indexOf('/guests/') === 0) && (
								<button type='button' className="cp_nav_btn" onClick={e => dispatch(setShowScanner(true))}>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V6M6 19H5.8C4.11984 19 3.27976 19 2.63803 18.673C2.07354 18.3854 1.6146 17.9265 1.32698 17.362C1 16.7202 1 15.8802 1 14.2V14M19 6V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H14M19 14V14.2C19 15.8802 19 16.7202 18.673 17.362C18.3854 17.9265 17.9265 18.3854 17.362 18.673C16.7202 19 15.8802 19 14.2 19H14M1 10H1.01M5.5 10H5.51M14.5 10H14.51M10 10H10.01M19 10H19.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</button>
							)}
						</Col>

					</Row>
				</div>
			</footer>
		</>
	)
};


export default Footer;