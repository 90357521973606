import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { toggleSelectDevicePopup } from 'redux/interfaceManger';
import { setDeviceName } from 'redux/authSlice';
import { batch } from 'react-redux';


const SelectDevicePopup = () => {
	const dispatch = useAppDispatch();
	const selectDevicePopup = useAppSelector(state => state.interfaceManger.selectDevicePopup);
	const deviceName = useAppSelector(state => state.auth.deviceName);
	const [device, setDevice] = useState<string>(deviceName);
	
	const onHide = () => {
		dispatch(toggleSelectDevicePopup(false));
	}

	const saveDevice = () => {
		localStorage.setItem('@deviceName', device);
	
		batch(() => {
			dispatch(setDeviceName(device));
			dispatch(toggleSelectDevicePopup(false));
		})
	}

	
	return (
		<>
			<Modal show={selectDevicePopup} className="printModal" onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Set Device Name</Modal.Title>
				</Modal.Header>
				<Modal.Body className='bg-white'>
					<Form.Group className="mb-3" controlId="eventCreateModal">
						<Form.Control type="text" value={device} onChange={e => setDevice(e.target.value)} />
					</Form.Group>

					<div className='text-end'>
						<Button type='button' variant='dark' onClick={saveDevice}>Save</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default SelectDevicePopup;