import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { ScanType, UserDataObject } from 'types';
import { ScanTypes } from 'constant';

// Define a type for the slice state
type AuthState = {
	loggedIn: boolean;
	userData: UserDataObject | null;
	scanType: ScanType;
	deviceName: string;
}


// Define the initial state using that type
const initialState: AuthState = {
	loggedIn: false,
	userData: null,
	scanType: ScanTypes.FIND,
	deviceName: 'Not set',
};

export const authSlice = createSlice({
	name: 'auth',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setLoggedIn(state: AuthState, action: PayloadAction<boolean>) {
			state.loggedIn = action.payload;
		},
		setUserData(state: AuthState, action: PayloadAction<UserDataObject>) {
			state.userData = action.payload;
		},
		resetUserData(state: AuthState) {
			state.userData = null;
		},
		setScannerType(state: AuthState, action: PayloadAction<ScanType>) {
			state.scanType = action.payload;
		},
		setDeviceName(state: AuthState, action: PayloadAction<string>) {
			state.deviceName = action.payload;
		},
	},
});

export const { 
	setLoggedIn, 
	setUserData, 
	resetUserData,
	setScannerType,
	setDeviceName,
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default authSlice.reducer;
