
import React, { useEffect, useState, useRef } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { apiAddGuest, apiDeleteGuest, apiEditGuest, apiGetGuestByGuestLists, apiGetGuestDetails, apiGetGuestsByEvent, apiGuestBadgePrint, apiGuestCheckedIn, apiGuestCheckedOut, apiGuestRevertCheckedIn } from 'api/guests';
import { apiGetEventDataFields } from 'api/events';
import Scanner from 'views/User/components/Scanner';
import dayjs from 'dayjs';
import { useLongPress } from 'hooks/use-long-press';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setFooterMessage, setHeaderTitle, toggleAddGuestPopup, setShowScanner, setLastPage } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { CheckedInLogs, Event, EventDataField, Guest, GuestList } from 'types';
import { apiGetGuestLists } from 'api/guestLists';
import { Table } from 'react-bootstrap';
import { BASE_URL, EventStatus, GuestStatus, MaxCheckInTestMode, ScanTypes, TeamRole } from 'constant';
import GuestDetailsPopup from 'views/User/components/GuestDetails';
import cacheService from 'services/CacheService';
import useWindowDimensions from 'hooks/useWindowDimensions';


let pageUpdated = false;

type LocationProps = {
	list: GuestList | null
	event: Event
}

const AllGuests = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const deviceName = useAppSelector(state => state.auth.deviceName);
	const { state }: { state: LocationProps } = useLocation()
	const { list, event } = state;
	let { eventId, listId } = useParams();
	const {width} = useWindowDimensions();
	const [page, setPage] = useState<number>(1);
	const [limit, setLimit] = useState<number>(20);
	const [orderBy, setOrderBy] = useState<string>('id');
	const [orderType, setOrderType] = useState<string>('DESC');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [searchQueryCheck, setSearchQueryCheck] = useState<string>('');
	const [eventFields, setEventFields] = useState<EventDataField[]>([]);
	const [guests, setGuests] = useState<Guest[]>([]);
	const [lists, setLists] = useState<GuestList[]>([]);

	const [guestDetails, setGuestDetails] = useState<Guest | null>(null);
	const [guestCheckInLogs, setGuestCheckInLogs] = useState<CheckedInLogs[]>([])
	const [confirmGuestDelete, setConfirmGuestDelete] = useState<number | null>(null);

	const [isEditGuestDetails, setIsEditGuestDetails] = useState(false);

	const [maxCheckInLimitReached, setMaxCheckInLimitReached] = useState<boolean>(false);

	const addGuestPopup = useAppSelector(state => state.interfaceManger.addGuestPopup);
	const [addGuestData, setAddGuestData] = useState<any>({
		id: null,
		status: GuestStatus.CONFIRMED,
		guest_list_id: listId,
	});
	const [addGuestErrorMsg, setAddGuestErrorMsg] = useState<string>('');

	const showScanner = useAppSelector(state => state.interfaceManger.showScanner);
	const scanType = useAppSelector(state => state.auth.scanType);
	const [isSearchFromScan, setIsSearchFromScan] = useState<boolean>(false);

	const cardPrintRef = useRef<HTMLDivElement | null>(null);
	const guestTableRef = useRef<HTMLDivElement | null>(null);
	
	const tableColStyle = {
		width: `${(100 / (eventFields.filter(f => f.is_visible === 1).length))}%`,
	}

	const isMobile = window.innerWidth <= 768;

	// const handlePrint = useReactToPrint({
	// 	content: () => cardPrintRef.current,
	// 	bodyClass: 'print-card'
	// });

	useEffect(() => {
		batch(() => {
			dispatch(setFooterMessage(''))
			dispatch(setLastPage(window.location.pathname))

			if (list && list.list_name) {
				dispatch(setHeaderTitle(`${list.list_name}`))
			} else {
				dispatch(setHeaderTitle(`All`))
			}
		})
	}, [])

	const handleScroll = () => {
		if (guestTableRef && guestTableRef.current && !pageUpdated) {
			const scrollY = guestTableRef.current.scrollTop;
			const windowHeight = guestTableRef.current.clientHeight;
			const documentHeight = guestTableRef.current.scrollHeight;
			if (scrollY + windowHeight >= documentHeight - 100) {
				pageUpdated = true;
				setPage(prev => {
					return prev + 1;
				});
			}
			// console.log({
			// 	scrollY,
			// 	windowHeight,
			// 	documentHeight,
			// 	plus: (scrollY + windowHeight),
			// 	condition: (scrollY + windowHeight >= documentHeight - 100),
			// })
		}
	};

	useEffect(() => {
		let refForCleanUp: HTMLElement | null = null;
		if (guestTableRef && guestTableRef.current) {
			guestTableRef.current.addEventListener('scroll', handleScroll);
			refForCleanUp = guestTableRef.current;
		}
		return () => {
			if (refForCleanUp) {
				refForCleanUp.removeEventListener('scroll', handleScroll);
			}
		};
	}, [guestTableRef]);

	useEffect(() => {

		(async () => {
			if (eventId) {
				const response = await apiGetGuestLists(parseInt(eventId))
	
				if (response.success) {
					setLists(response.data.lists)
				}
			}
		})();

	}, [eventId])

	// load guest list from api
	useEffect(() => {

		(async () => {
			if (eventId) {
				if (listId) {
					const response = await apiGetGuestByGuestLists(eventId, listId, {
						page,
						limit,
						orderBy,
						orderType,
						q: searchQueryCheck
					})
	
					if (response.success) {
						// open guest details if find perfect match for guest in qr scan
						if (isSearchFromScan && response.data.items.length === 1) {
							getGuestDetails(response.data.items[0].id);
						}
						
						batch(() => {
							setGuests([...guests, ...response.data.items])
							setIsSearchFromScan(false);
						})

						pageUpdated = false;
					}
				} else {
					const response = await apiGetGuestsByEvent(eventId, {
						page,
						limit,
						orderBy,
						orderType,
						q: searchQueryCheck
					})
	
					if (response.success) {
						// open guest details if find perfect match for guest in qr scan
						if (isSearchFromScan && response.data.items.length === 1) {
							getGuestDetails(response.data.items[0].id);
						}

						batch(() => {
							setGuests([...guests, ...response.data.items])
							setIsSearchFromScan(false);
						})

						pageUpdated = false;
					}
				}
			}
		})()

	}, [eventId, listId, page, limit, orderBy, orderType, searchQueryCheck])

	// get event printer labels
	useEffect(() => {
		(async () => {
			if (eventId) {
				// const response = await apiGetEventPrinterLabels(eventId)

				// if (response.success) {
				// 	dispatch(setEventPrinterLabels([...response.data]))
				// }


				const respEventFields = await apiGetEventDataFields(eventId);

				if (respEventFields.success) {
					setEventFields([...respEventFields.data.filter(f => f.show_in_app === 1)])
				}
			}
		})();
	}, [eventId])

	// load guest details
	useEffect(() => {
		if (guestDetails === null) {
			setIsEditGuestDetails(false);
			setConfirmGuestDelete(null);
		}
	}, [guestDetails])

	// check search
	useEffect(() => {
		if (searchQuery === '') {
			batch(() => {
				setPage(1);
				setGuests([]);
				setSearchQueryCheck('');
			});
		} else {
			if (searchQuery.length > 1) {
				batch(() => {
					setPage(1);
					setGuests([]);
					setSearchQueryCheck(searchQuery);
				})
			}
		}
	}, [searchQuery])


	const getGuestDetails = async (guestId: number, updateInList: boolean = false) => {
		console.log('load guest check-in logs', guestId)
		if (eventId) {

			const cacheGuest = cacheService.getGuestDetails(eventId, guestId);

			if (cacheGuest) {
				console.log('FOUND GUEST IN CACHE', cacheGuest)
				setGuestDetails(cacheGuest)
			}

			const response = await apiGetGuestDetails(eventId, guestId)
	
			if (response.success && response.data.details) {
				setGuestDetails(response.data.details)
				setGuestCheckInLogs(response.data.checked_ins)

				if (updateInList) {
					updateGuestInList(response.data.details)
				}
			}
		}
	}


	const updateGuestInList = (guest: Guest) => {
		if (guest) {
			const index = guests.findIndex(g => g.id === guest.id);
			if (index > -1) {
				const newGuests = [...guests];
				newGuests[index] = guest;
				setGuests(newGuests);
			}
		}
	}

	const handleDeleteGuest = async () => {
		if (eventId && confirmGuestDelete !== null) {
			const response = await apiDeleteGuest(eventId, confirmGuestDelete)

			if (response.success) {

				batch(() => {
					// delete guest from list
					// pageUpdated = true; // use true here so it won't run handleScroll
					setGuests([...guests.filter(g => g.id !== confirmGuestDelete)]);

					setGuestDetails(null);
					setConfirmGuestDelete(null);
				})
			} else {
				alert(response.message)
			}
		}
	}

	// save edit guest details api
	const handleSaveEditGuest = async (additionalData?: any) => {
		if (eventId && guestDetails) {
			console.log(guestDetails, additionalData)

			const response = await apiEditGuest(eventId, guestDetails.id, {...guestDetails, ...additionalData})

			if (response.success) {
				batch(() => {
					getGuestDetails(guestDetails.id, true)
					setIsEditGuestDetails(false);
				})
			}

		}
	}

	// save add guest details api
	const handleSaveAddGuest = async (additionalData?: any) => {
		if (eventId && addGuestData && Object.keys(addGuestData).length > 0) {
			
			console.log('addGuestData', addGuestData, additionalData)
			
			if (!addGuestData.hasOwnProperty('guest_list_id') || addGuestData.guest_list_id === '' || isNaN(addGuestData.guest_list_id)) {
				alert('Please select guest list');
				return;
			}

			if ((!addGuestData.hasOwnProperty('guest_name') || addGuestData.guest_name === '') &&
				(!addGuestData.hasOwnProperty('first_name') || addGuestData.first_name === '')) {
				alert('Please insert Guest name OR First name');
				return;
			}


			const response = await apiAddGuest(eventId, {...addGuestData, ...additionalData})

			if (response.success) {
				batch(() => {
					// checkInGuest(response.data.id)
					dispatch(toggleAddGuestPopup(false))
					setAddGuestData({
						id: null,
						status: GuestStatus.CONFIRMED,
						guest_list_id: listId,
					})
					setGuests([{
						...response.data,
						// status: 'checked_in', // because already execute checking api so list should also have same status
					}, ...guests]);

					setTimeout(() => {
						getGuestDetails(response.data.id)
					}, 200)
				})
			}

		}
	}

	const checkInGuest = async (guestId: number) => {
		console.log('checkInGuest', guestId)
		if (eventId) {

			if (event.status === EventStatus.TESTING) {
				if (list && list.checked_in >= MaxCheckInTestMode) {
					setMaxCheckInLimitReached(true);
					return;
				}
			}

			// HACK - Update status locally 
			if (guestDetails) {
				guestDetails.status = GuestStatus.CHECKED_IN;
				setGuestDetails({...guestDetails});
				cacheService.setGuestDetails(eventId, guestId, guestDetails);
			}
			// HACK - Update status locally 

			const response = await apiGuestCheckedIn(eventId, guestId, deviceName)

			if (response.success) {
				getGuestDetails(guestId, true)
				dispatch(setFooterMessage(<p>Last {response.data.activity?.replace(/_/, ' ')}: {response.data.guest} at {(response.data.time) ? dayjs(response.data.time).format('h:mm a') : ''} </p>))
			}
		}
	}

	const checkOutGuest = async (guestId: number) => {
		console.log('checkOutGuest', guestId)
		if (eventId) {
			const response = await apiGuestCheckedOut(eventId, guestId, deviceName)

			if (response.success) {
				getGuestDetails(guestId, true)
				dispatch(setFooterMessage(<p>Last {response.data?.activity?.replace(/_/, ' ')}: {response.data?.guest} at {(response.data.time) ? dayjs(response.data.time).format('h:mm a') : ''} </p>))
			}
		}
	}

	const revertCheckInGuest = useLongPress(async () => {
		if (!guestDetails) {
			return;
		}

		const guestId = guestDetails.id;
		console.log('revertCheckInGuest', guestId)
		if (eventId) {
			const response = await apiGuestRevertCheckedIn(eventId, guestId, deviceName)

			if (response.success) {
				// HACK - Update status locally 
				if (guestDetails) {
					guestDetails.status = GuestStatus.CHECKED_IN;
					setGuestDetails({...guestDetails});
					cacheService.setGuestDetails(eventId, guestId, guestDetails);
				}
				// HACK - Update status locally 
				getGuestDetails(guestId, true)
				dispatch(setFooterMessage(<p>Last {response.data?.activity?.replace(/_/, ' ')}: {response.data?.guest} at {(response.data.time) ? dayjs(response.data.time).format('h:mm a') : ''} </p>))
			}
		}
	})
	
	const onScannerResult = (data: any) => {
		console.log('onScannerResult', {data})

		// close scanner and search guest
		if (scanType === ScanTypes.FIND) {
			if (typeof data === 'string') {
				batch(() => {
					setSearchQuery(data);
					setIsSearchFromScan(true);
				})
			}
			dispatch(setShowScanner(false));
		}

	}

	const handlePrint = async (guestId: number) => {
		// console.log('handlePrint', guestId)
		if (width >= 1200 && eventId) {
			window.open(`${BASE_URL}/../print-guest/${eventId}/${guestId}`)
			return;
		}
		if (eventId) {

			const response = await apiGuestBadgePrint(eventId, guestId)

			console.log(response);
			if (response.success) {
				setTimeout(() => {
					console.log('aaa', response.data)
					window.open(response.data)

					// console.log('win', win)
					// if (win){
					// 	setTimeout(function() {
					// 		console.log('print', win)
					// 		win.focus();
					// 		win.print();
					// 	}, 2000); // Delay printing to ensure correct rendering
					// }

					/* const iframe = (document.getElementById('badge-iframe') as HTMLIFrameElement);

					if (iframe) {
						// iframe.src = `https://checkinpro.local:3000/badges/${response.data}`; // local
						iframe.src = `https://app.checkinpax.com/badges/${response.data}`; // live
						
						iframe.onload = () => {
							const pdfFrame = (window as any)['frames']['pdf'];
							pdfFrame.focus();
							pdfFrame.print();
						}
					} */

				})
			} else {
				alert(response.message)
			}
		}
	}


	if (showScanner) {
		return (
			<Scanner 
				eventId={eventId} 
				onResult={onScannerResult} 
				openGuestDetails={(id: number) => {
					dispatch(setShowScanner(false))
					getGuestDetails(id)
				}} 
			/>
		)
	}

	let hasAddGuestAccess = false;
	if (event.hasOwnProperty('member_role')) {
		if (event.member_role === TeamRole.MANAGER) {
			hasAddGuestAccess = true;
		}
	} else {
		hasAddGuestAccess = true;
	}

	return (
		<>
			<div className="cp_app_guestview_bx">
				<div className="container-fluid">
					<div className="cp_search">
						<div className="search" style={{ 
							width: (hasAddGuestAccess ? '96%' : '100%'), 
							marginRight: (hasAddGuestAccess ? '10px' : '0') 
						}}>
							<Form onSubmit={e => e.preventDefault()}>
								<i>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z" stroke="#2C292C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</i>
								<Form.Control
									id="inlineFormInput"
									placeholder="Search Guests"
									value={searchQuery}
									onChange={e => setSearchQuery(e.target.value)}
								/>
								{(searchQuery !== '') ? (
									<Button type='button' className='text-center btn-close' variant='light' onClick={e => setSearchQuery('')}></Button>
								) : null}
							</Form>
						</div>
						{hasAddGuestAccess && (
						<button type="button" className="cp_qr_btn" onClick={() => {
							batch(() => {
								setAddGuestData({
									id: null,
									status: GuestStatus.CONFIRMED,
									guest_list_id: listId ? listId : (lists.length) ? lists[0].id : undefined,
								})
								dispatch(toggleAddGuestPopup(true))
							})
						}}>
							<i>
								<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 24C3.33579 21.5226 6.50702 20 10 20C13.493 20 16.6642 21.5226 19 24M14.5 11.5C14.5 13.9853 12.4853 16 10 16C7.51472 16 5.5 13.9853 5.5 11.5C5.5 9.01472 7.51472 7 10 7C12.4853 7 14.5 9.01472 14.5 11.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M17 0.5C17 0.223858 17.2239 0 17.5 0C17.7761 0 18 0.223858 18 0.5V7.5C18 7.77614 17.7761 8 17.5 8C17.2239 8 17 7.77614 17 7.5V0.5Z" fill="white" />
									<path d="M21 3.5C21.2761 3.5 21.5 3.72386 21.5 4C21.5 4.27614 21.2761 4.5 21 4.5L14 4.5C13.7239 4.5 13.5 4.27614 13.5 4C13.5 3.72386 13.7239 3.5 14 3.5L21 3.5Z" fill="white" />
								</svg>
							</i>
						</button>
						)}
					</div>

					<div className="allguest_table" ref={guestTableRef}>
						<Table responsive borderless>
							<thead>
								<tr>
									<th className='text-center'>
										<i className='icoBefore'>
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path opacity="1" d="M5.95 10L8.65 12.7L14.05 7.3M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#ACA6A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											</svg>
										</i>
									</th>
									{eventFields.filter(f => f.is_visible === 1).map((field, i) => {
										return (
											<th key={i} style={tableColStyle} 
												className={`${(field.field === 'guest_name' || field.field === 'company') ? 'large-col' : ''} ${(field.field === orderBy) ? ((orderType === 'ASC') ? 'sort-asc' : 'sort-desc') : ''}`}
												onClick={e => {
													if (orderBy === field.field) {
														batch(() => {
															setPage(1);
															setGuests([]);
															setOrderType(orderType === 'ASC' ? 'DESC' : 'ASC');
														})
													} else {
														batch(() => {
															setPage(1);
															setGuests([]);
															setOrderBy(field.field);
															setOrderType('ASC');
														})
													}
												}}
											>
												{field.display_name}
											</th>
										)
									})}
									{/* <th style={tableColStyle}
										className={`${('status' === orderBy) ? ((orderType === 'ASC') ? 'sort-asc' : 'sort-desc') : ''}`}
										onClick={e => {
											if (orderBy === 'status') {
												batch(() => {
													setPage(1);
													setGuests([]);
													setOrderType(orderType === 'ASC' ? 'DESC' : 'ASC');
												})
											} else {
												batch(() => {
													setPage(1);
													setGuests([]);
													setOrderBy('status');
													setOrderType('ASC');
												})
											}
										}}
									>
										Status
									</th> */}
								</tr>
							</thead>

							<tbody>
								{(guests.length === 0) && (
									<tr>
										<td className='text-center text-muted' colSpan={(eventFields.filter(f => f.is_visible === 1).length + 1)}>No guest found...</td>
									</tr>
								)}
								{guests.map((guest, i) => {
									return (
										<tr key={guest.id} className={`${(guest.status === GuestStatus.CHECKED_IN) ? 'active' : ''} `} onClick={e => getGuestDetails(guest.id)}>
											<td className='text-center' >
												<i>
													<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path 
															opacity={(guest.status === GuestStatus.CHECKED_IN) ? 1 : "0.2"} 
															d="M5.95 10L8.65 12.7L14.05 7.3M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" 
															stroke="#ACA6A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
														/>
													</svg>
												</i>
											</td>
											{eventFields.filter(f => f.is_visible === 1).map((f, j) => {
												if (f.field === 'status') {
													return (
														<td 
														key={`${guest.id}_${j}`}
														style={{
															...tableColStyle,
															maxWidth: '200px'
														}}>
														<Button className="cp_confrm_btn" onClick={e => getGuestDetails(guest.id)}>
															{(guest.status === GuestStatus.CONFIRMED) ? 'Confirmed' : 
																(guest.status === GuestStatus.CANCELLED) ? 'Cancelled' : 
																	(guest.status === GuestStatus.CHECKED_IN) ? 'Checked In' : 
																		(guest.status === GuestStatus.CHECKED_OUT) ? 'Checked Out' : 'Unconfirmed'
															}
														</Button>
														</td>
													)
												} else {
													return (
														<td key={`${guest.id}_${j}`} style={{
																...tableColStyle,
																color: (f.field === 'notification') ? '#dc3545' : ((guest.status === GuestStatus.CHECKED_IN) ? '#aaaaaa' : '#2C292C')
															}} 
															className={(f.field === 'guest_name' || f.field === 'company') ? 'large-col' : ''}>
															{(guest.hasOwnProperty(f.field) && guest[f.field as keyof Guest]) ? guest[f.field as keyof Guest] : '-'}
														</td>
													)
												}
											})}
											
											{/* <td style={{
												...tableColStyle,
												maxWidth: '200px'
											}}>
											<Button className="cp_confrm_btn" onClick={e => getGuestDetails(guest.id)}>
												{(guest.status === GuestStatus.CONFIRMED) ? 'Confirmed' : 
													(guest.status === GuestStatus.CHECKED_IN) ? 'Checked In' : 
														(guest.status === GuestStatus.CHECKED_OUT) ? 'Checked Out' : 'Unconfirmed'
												}
											</Button>
											</td> */}
										</tr>
									)
								})}
								

							</tbody>
						</Table>

						{(guestDetails !== null) && (
							<GuestDetailsPopup
								event={event}
								guestDetails={guestDetails}
								setGuestDetails={setGuestDetails}
								isEditGuestDetails={isEditGuestDetails}
								setIsEditGuestDetails={setIsEditGuestDetails}
								setConfirmGuestDelete={setConfirmGuestDelete}
								lists={lists}
								eventFields={eventFields}
								guestCheckInLogs={guestCheckInLogs}
								handleSaveEditGuest={handleSaveEditGuest}
								handlePrint={handlePrint}
								revertCheckInGuest={revertCheckInGuest}
								checkInGuest={checkInGuest}
								isNewGuest={false}
							/>
						)}

					</div>
				</div>
			</div>

			{(confirmGuestDelete !== null) && (
			<Modal aria-labelledby="contained-modal-title-vcenter" centered show={confirmGuestDelete !== null} onHide={() => setConfirmGuestDelete(null)} className='deleteModal'>
				<Modal.Header closeButton>
					<Modal.Title>Delete Guest</Modal.Title>
				</Modal.Header>

				<Modal.Body>Are you sure you want to delete this guest?</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => setConfirmGuestDelete(null)}>
						Cancel
					</Button>

					<Button variant="danger" onClick={handleDeleteGuest}>
						Yes, delete guest
					</Button>
				</Modal.Footer>
			</Modal>
			)}
			
			{(maxCheckInLimitReached) && (
			<Modal aria-labelledby="contained-modal-title-vcenter" centered show={maxCheckInLimitReached} onHide={() => setMaxCheckInLimitReached(false)} className='deleteModal'>
				<Modal.Header closeButton>
					<Modal.Title>Guest Limit Reached</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p className='m-0'>You have checked-in {MaxCheckInTestMode} guests in Test Mode. </p>
					<p className='m-0'>Activate your event to unlock unlimited check-ins.</p>
				</Modal.Body>

				<Modal.Footer style={{ justifyContent: 'center' }}>
					<Button variant="secondary" onClick={() => setMaxCheckInLimitReached(false)}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
			)}

			{(addGuestPopup) && (
				<GuestDetailsPopup
					event={event}
					guestDetails={addGuestData}
					setGuestDetails={setAddGuestData}
					isEditGuestDetails={true}
					setIsEditGuestDetails={setIsEditGuestDetails}
					setConfirmGuestDelete={setConfirmGuestDelete}
					lists={lists}
					eventFields={eventFields}
					guestCheckInLogs={[]}
					handleSaveEditGuest={handleSaveAddGuest}
					handlePrint={handlePrint}
					revertCheckInGuest={revertCheckInGuest}
					checkInGuest={checkInGuest}
					isNewGuest={true}
				/>
			)}
		</>
	);
};

export default AllGuests;