import { ResponseObject, CheckInResponse, GuestDetailsResponse, GuestResponse, Guest, GuestWithList, ApiParamsBulkGuestDelete, ApiParamsBulkGuestMoveList, ApiParamsBulkGuestSendEmail, ApiParamsBulkGuestReSendEmail, GuestTimelineResponse, ApiParamsBulkBadgePrint } from "types"
import apiService from "services/ApiService";
import cacheService from "services/CacheService";



type QueryParams = {
	page: number,
	limit: number,
	orderBy: string,
	orderType: string,
	q?: string
}


export const apiGetGuestByGuestLists = async (
	eventId: string | number,
	listId: string | number,
	{
		page = 1,
		limit = 10,
		orderBy = 'id',
		orderType = 'DESC',
		q
	}: QueryParams
): Promise<ResponseObject<GuestResponse>> => {

	if (!cacheService.checkIsOnline()) {
		let items: Guest[] = cacheService.getEventGuests(`@event:${eventId}:guests`);

		if (items.length) {
			items = items.filter((g: Guest) => g.guest_list_id == listId);
			
			if (q && q !== '') {
				items = cacheService.searchGuest(items, q);
			}

			const total = items.length;
			const total_pages = Math.floor(total / limit);
			const sliceStart = ((page - 1) * limit);
			const sliceEnd = (sliceStart + limit);

			console.log('final', {
				items: items.slice(sliceStart, sliceEnd),
				meta_data: {
					limit,
					page,
					total,
					total_pages,
				}
			})
			return {
				success: true,
				message: 'success',
				data: {
					items: items.slice(sliceStart, sliceEnd),
					meta_data: {
						limit,
						page,
						total,
						total_pages,
					}
				}
			}

		}
		
		return {
			success: false,
			message: 'error',
			data: {
				items: [],
				meta_data: {
					limit,
					page,
					total: 0,
					total_pages: 0,
				}
			}
		}
	}

	return await apiService.get(`/guests/${eventId}/${listId}`, {
		page,
		limit,
		orderBy,
		orderType,
		q
	});
}

export const apiGetGuestsByEvent = async (
	eventId: string | number, {
		page = 1,
		limit = 10,
		orderBy = 'id',
		orderType = 'DESC',
		q
	}: QueryParams): Promise<ResponseObject<GuestResponse>> => {

		if (!cacheService.checkIsOnline()) {
			let items: Guest[] = cacheService.getEventGuests(`@event:${eventId}:guests`);
	
			if (items.length) {
				if (q && q !== '') {
					items = cacheService.searchGuest(items, q);
				}
	
				const total = items.length;
				const total_pages = Math.floor(total / limit);
				const sliceStart = ((page - 1) * limit);
				const sliceEnd = (sliceStart + limit);
	
				console.log('final', {
					items: items.slice(sliceStart, sliceEnd),
					meta_data: {
						limit,
						page,
						total,
						total_pages,
					}
				})
				return {
					success: true,
					message: 'success',
					data: {
						items: items.slice(sliceStart, sliceEnd),
						meta_data: {
							limit,
							page,
							total,
							total_pages,
						}
					}
				}
	
			}
			
			return {
				success: false,
				message: 'error',
				data: {
					items: [],
					meta_data: {
						limit,
						page,
						total: 0,
						total_pages: 0,
					}
				}
			}
		}

	return await apiService.get(`/guests/${eventId}`, {
		page,
		limit,
		orderBy,
		orderType,
		q
	});
}

export const apiSearchAndCheckedInGuest = async (
	eventId: string | number, {
		q,
		device_name
	}: {
		q: string
		device_name: string
	}): Promise<ResponseObject<GuestWithList>> => {

	return await apiService.post(`/checked-in-by-search/${eventId}`, {
		q,
		device_name
	});
}

export const apiSearchAndTrackGuest = async (
	eventId: string | number, {
		q,
		device_name
	}: {
		q: string
		device_name: string
	}): Promise<ResponseObject<GuestWithList>> => {

	return await apiService.post(`/track-guest-by-search/${eventId}`, {
		q,
		device_name
	});
}

export const apiGetAllGuestsByEvent = async (eventId: string | number): Promise<ResponseObject<Guest[]>> => {
	return await apiService.get(`/guests-sync/${eventId}`);
}

export const apiGetGuestDetails = async (
	eventId: string | number,
	guestId: string | number,
): Promise<ResponseObject<GuestDetailsResponse>> => {

	if (!cacheService.checkIsOnline()) {
		const guest = cacheService.getGuestDetails(eventId, guestId);

		if (guest) {
			return {
				success: true,
				message: 'success',
				data: {
					details: guest,
					checked_ins: []
				}
			}
		}
		
		return {
			success: false,
			message: 'error',
			data: {
				details: null,
				checked_ins: []
			}
		}
	}

	return await apiService.get(`/guest-details/${eventId}/${guestId}`);
}

export const apiGetGuestTimeline = async (
	eventId: string | number,
	guestId: string | number,
): Promise<ResponseObject<GuestTimelineResponse[]>> => {

	return await apiService.get(`/guest-timeline/${eventId}/${guestId}`);
}

export const apiAddGuest = async (
	eventId: string | number,
	addData: Guest
): Promise<ResponseObject<Guest>> => {
	return await apiService.postMultipart(`/add-guest/${eventId}`, {
		...addData
	});
}

export const apiEditGuest = async (
	eventId: string | number,
	guestId: string | number,
	editData: Guest
): Promise<ResponseObject<Guest>> => {

	// if offline
	// update new guest data in storage

	return await apiService.postMultipart(`/edit-guest/${eventId}/${guestId}`, {
		...editData
	});
}

export const apiDeleteGuest =async (
	eventId: string | number,
	guestId: string | number,
): Promise<ResponseObject<null>> => {

	// if offline
	// delete guest data in storage

	return await apiService.delete(`/delete-guest/${eventId}/${guestId}`);
}

export const apiDeleteGuestImage =async (
	eventId: string | number,
	guestId: string | number,
): Promise<ResponseObject<null>> => {

	// if offline
	// delete guest data in storage

	return await apiService.delete(`/delete-guest-image/${eventId}/${guestId}`);
}



export const apiGuestCheckedIn = async (
	eventId: string | number,
	guestId: string | number,
	deviceName: string,
): Promise<ResponseObject<CheckInResponse>> => {

	// if offline
	// add new check-in log in storage

	return await apiService.post(`/checked-in/${eventId}/${guestId}`, {
		device_name: deviceName
	});
}


export const apiGuestCheckedOut = async (
	eventId: string | number,
	guestId: string | number,
	deviceName: string,
): Promise<ResponseObject<CheckInResponse>> => {
	return await apiService.post(`/checked-out/${eventId}/${guestId}`, {
		device_name: deviceName
	});
}

export const apiGuestRevertCheckedIn = async (
	eventId: string | number,
	guestId: string | number,
	deviceName: string,
): Promise<ResponseObject<CheckInResponse>> => {
	return await apiService.post(`/revert-checked-in/${eventId}/${guestId}`, {
		device_name: deviceName
	});
}


export const apiGuestBadgePrint = async (
	eventId: string | number,
	guestId: string | number,
): Promise<ResponseObject<string>> => {

	return await apiService.post(`/print-badge/${eventId}/${guestId}`, {});
}


export const apiBulkDeleteGuest = async (eventId: string | number, data: ApiParamsBulkGuestDelete): Promise<ResponseObject<null>> => {

	return await apiService.post(`/bulk-delete-guest/${eventId}`, data);
}

export const apiBulkMoveGuest = async (eventId: string | number, data: ApiParamsBulkGuestMoveList): Promise<ResponseObject<null>> => {

	return await apiService.post(`/bulk-move-guest/${eventId}`, data);
}

export const apiBulkSendEmailGuest = async (eventId: string | number, data: ApiParamsBulkGuestSendEmail): Promise<ResponseObject<null>> => {

	return await apiService.post(`/bulk-send-emails/${eventId}`, data);
}

export const apiBulkReSendEmailGuest = async (eventId: string | number, data: ApiParamsBulkGuestReSendEmail): Promise<ResponseObject<null>> => {

	return await apiService.post(`/bulk-resend-emails/${eventId}`, data);
}

export const apiBulkBadgePrint = async (eventId: string | number, data: ApiParamsBulkBadgePrint): Promise<ResponseObject<string>> => {

	return await apiService.post(`/bulk-print-badge/${eventId}`, data);
}