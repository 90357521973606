import { apiValidateToken } from 'api/team';
import { AuthContext } from 'AuthContext';
import { useContext, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { batch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Team } from 'types';

const AcceptInvitationError = () => {
	const loggedIn = useAppSelector(state => state.auth.loggedIn);
	const { logout} = useContext(AuthContext);

	return (
		<div className='text-center pt-5'>
			
			<h3>Verification failed</h3>
			<p className='text-danger mt-4'>The verification code does not match the account you are logged into.</p>
			<p>Please log out and click on the button in the email again to accept the team member invitation.</p>

			<Button variant='dark' className='mt-5' onClick={logout}>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3.93652 6.49999C5.14685 4.40769 7.40905 3 10 3C13.866 3 17 6.134 17 9.99998C17 13.866 13.866 17 10 17C7.40905 17 5.14685 15.5923 3.93652 13.5M9.99998 12.8L12.8 9.99998M12.8 9.99998L9.99998 7.19999M12.8 9.99998H3" stroke="#ACA6A6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>

				<span className="label ms-1">Logout</span>
			</Button>
		</div>
	);
};

export default AcceptInvitationError;