
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'redux/hooks';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import dayjs from 'dayjs';
import Card from 'react-bootstrap/Card';
import { Event } from 'types';
import useWindowDimensions from 'hooks/useWindowDimensions';


type EventCardProps = {
	event: Event
	clickable?: boolean
}

const EventCardList = ({event, clickable = true}: EventCardProps) => {
	const isOnline = useAppSelector(s => s.interfaceManger.isOnline);
	const navigate = useNavigate();
	const {height, width} = useWindowDimensions();
	

	return (
		<Card key={event.id} onClick={() => {
			if (clickable) {
				navigate(`/guest/${event.id}`, { state: event })
			}
		}} className='event-card-list'>
			<Card.Img variant="top" src={(event.logo && isOnline) ? event.logo : require('assets/images/placeholder-event.jpg')} />
			<Card.Body>
				<Row>
					<Col lg={7}>
						<Card.Title>{event.name}</Card.Title>
					</Col>
					<Col lg={5}>
						<Row>
							<Col sm={6}>
								<Card.Text className={`ms-1 ${(width <= 576) ? 'mb-1' : ''}`}>
									<i>
										<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.8 5.8H1M8.8 1V3.4M4 1V3.4M3.88 13H8.92C9.92809 13 10.4321 13 10.8172 12.8038C11.1559 12.6312 11.4312 12.3559 11.6038 12.0172C11.8 11.6321 11.8 11.1281 11.8 10.12V5.08C11.8 4.07191 11.8 3.56786 11.6038 3.18282C11.4312 2.84413 11.1559 2.56876 10.8172 2.39619C10.4321 2.2 9.9281 2.2 8.92 2.2H3.88C2.87191 2.2 2.36786 2.2 1.98282 2.39619C1.64413 2.56876 1.36876 2.84413 1.19619 3.18282C1 3.56786 1 4.07191 1 5.08V10.12C1 11.1281 1 11.6321 1.19619 12.0172C1.36876 12.3559 1.64413 12.6312 1.98282 12.8038C2.36786 13 2.87191 13 3.88 13Z" stroke="#CFC8CF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</i>
									{dayjs(event.start_data).format('MMM DD, YYYY')} - {dayjs(`${event.start_data} ${event.time}`).format('h:mm a')}
								</Card.Text>
							</Col>
							<Col sm={6}>
								<Card.Text>
									<i>
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12.8421 10.5523C15.0727 10.9908 16.6316 11.9924 16.6316 13.1579C16.6316 14.7275 13.8039 16 10.3158 16C6.82768 16 4 14.7275 4 13.1579C4 11.9924 5.55886 10.9908 7.78947 10.5523M10.3158 12.8421V7.78947M10.3158 7.78947C11.3622 7.78947 12.2105 6.94117 12.2105 5.89474C12.2105 4.8483 11.3622 4 10.3158 4C9.26936 4 8.42105 4.8483 8.42105 5.89474C8.42105 6.94117 9.26936 7.78947 10.3158 7.78947Z" stroke="#CFC8CF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</i>
									{event.location}
								</Card.Text>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card.Body>

			{(clickable === true) && (
			<Link to={`/guest/${event.id}`} state={event} className="arrow_btn">
				<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</Link>
			)}
		</Card>
	)
}

export default EventCardList;