import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { apiDownloadGuestLists, apiGetGuestLists } from 'api/guestLists';
import { GuestList } from 'types';
import { unsetActiveEvent } from 'redux/eventSlice';
import { batch } from 'react-redux';
import { setHeaderTitle } from 'redux/interfaceManger';

const DownloadGuests = () => {
	const dispatch = useAppDispatch();
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const [searchParams, setSearchParams] = useSearchParams();
	const [lists, setLists] = useState<GuestList[]>([]);
	const [selectedId, setSelectedId] = useState<string | number>('ALL');

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Download Report'))
		})
	}, [])

	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const response = await apiGetGuestLists(activeEvent.id)

				if (response.success) {
					setLists(response.data.lists)

					const listVal = searchParams.get('list');
					
					if (listVal) {
						setSelectedId(listVal)
					}
				}
			}
		})()
	}, [activeEvent])


	const downloadList = async () => {
		if (activeEvent && selectedId && selectedId !== '') {
			console.log({selectedId})
			const response = await apiDownloadGuestLists(activeEvent.id, selectedId);

			if (response.success) {
				window.open(response.data, '_blank');
			}
		}
	}


	if (!activeEvent) {
		return <Navigate to="/" />
	}

	return (
		<>
			<div className="cp_table cp_wdth" style={{ height: '100%' }}>
				<div className="eventDv">
					<Row>
						{/* <Col lg="6">
							<div className="listDropdown">
								<label className='event-select'>Event</label>
								<Form.Select size="lg" disabled>
									<option>{activeEvent.name}</option>
								</Form.Select>
							</div>
						</Col>

						<Col lg="1"></Col> */}

						<Col lg="12">
							<div className="listDropdown">
								<label className='event-select'>Guest Lists</label>
								<Form.Select size="lg" value={selectedId} onChange={e => setSelectedId(e.target.value)}>
									<option value='ALL'>All Guests</option>
									{lists.map((list, index) => {
										return <option key={index} value={(list.id) ? list.id : ''}>{list.list_name}</option>
									})}
								</Form.Select>
							</div>
						</Col>
					</Row>
				</div>

				<div className="eventBody"></div>

				<div className="eventFooter">
					<div className="actionBtns mb-3 mt-2">
						<Button variant="" className="btn btn-theme-white" onClick={() => dispatch(unsetActiveEvent())} >Cancel</Button>
						<a href='javascript:void(0)' className="btn btn-theme-primary" onClick={downloadList}>Download Report</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default DownloadGuests;