import React, { useContext, useEffect, useState } from 'react';
import { apiGetPackages } from 'api/payment';
import { AvailableCurrencies } from 'constant';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Event, Package } from "types";
import CardForm from './StripePayment/CardForm';
import { apiUpdateEventFloorPlan } from 'api/events';
import { toast } from 'react-toastify';
import { setActiveEvent } from 'redux/eventSlice';
import { useAppDispatch } from 'redux/hooks';



type FloorPlanProps = {
	event: Event
	onSuccess: () => void
	onCancel: () => void
}

const FloorPlan = ({
	event,
	onSuccess,
	onCancel,
}: FloorPlanProps): JSX.Element => {
	const dispatch = useAppDispatch();

	const [agendaIcon, setAgendaIcon] = useState<number>(1);
	const [agendaImages, setAgendaImages] = useState<string[]>(['']);

	const [floorPlanIcon, setFloorPlanIcon] = useState<number>(2);
	const [floorPlanImages, setFloorPlanImages] = useState<string[]>(['']);

	useEffect(() => {
		if (event) {
			setAgendaIcon(event.agenda_icon)
			if (event.agenda_images?.length) {
				setAgendaImages([...event.agenda_images])
			}

			setFloorPlanIcon(event.floor_plan_icon)
			if (event.floor_plan_images?.length) {
				setFloorPlanImages([...event.floor_plan_images])
			}
		}
	}, [event]);

	const handleDecrementAgenda = (index: number) => {
		if (agendaImages.length <= 1) {
			return;
		}
		setAgendaImages([...agendaImages.filter((g, i) => i !== index)])
	}

	const handleIncrementAgenda = () => {
		setAgendaImages([...agendaImages, '']);
	}

	const handleDecrementFloorPlan = (index: number) => {
		if (floorPlanImages.length <= 1) {
			return;
		}
		setFloorPlanImages([...floorPlanImages.filter((g, i) => i !== index)])
	}

	const handleIncrementFloorPlan = () => {
		setFloorPlanImages([...floorPlanImages, '']);
	}

	const submit = async () => {
		const agenda_images = agendaImages.filter(i => i.trim().length > 0)
		const floor_plan_images = floorPlanImages.filter(i => i.trim().length > 0)

		const response = await apiUpdateEventFloorPlan(
			event.id,
			agendaIcon,
			agenda_images.length ? agenda_images : null,
			floorPlanIcon,
			floor_plan_images.length ? floor_plan_images : null,
		);

		if (response.success) {
			toast.success(response.message)
			dispatch(setActiveEvent({
				...event, 
				agenda_icon: response.data.agenda_icon,
				agenda_images: response.data.agenda_images,
				floor_plan_icon: response.data.floor_plan_icon,
				floor_plan_images: response.data.floor_plan_images,
			}))
			onSuccess()
		} else {
			toast.error(response.message)
		}
	}

	return (
		<>
		<Modal show={true} className="createNewAccountModal" onHide={onCancel} aria-labelledby="contained-modal-title-vcenter" centered style={{ zIndex: 99999, top: 0 }}>
			<Modal.Header closeButton>
				<Modal.Title>
				Add Agenda / Floorplan
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className='bg-light'>
				<Form.Label>Select Icon</Form.Label>
				<div className='d-flex mb-3' style={{ gap: '1rem' }}>
					<Button type='button' variant='' size='sm' className={`floor-icon ${agendaIcon === 1 ? 'btn-white' : ''}`} onClick={() => setAgendaIcon(1)}>
						<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M6.3,14.6H5.6c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8
									H11"/>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M17.7,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8H9.9c-1,0-1.8-0.8-1.8-1.8v-6.6
									c0-1,0.8-1.8,1.8-1.8H13"/>
							</g>
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M99.2,15.2l-1.1,4h-3.3c-1.2,0-2.2-1-2.2-2.3V7.2c0-1.3,1-2.3,2.2-2.3h10.5c1.2,0,2.2,1,2.2,2.3v9.7
									c0,1.3-1,2.3-2.2,2.3h-2.6V4.8"/>
								<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="102.7" y1="11" x2="92.5" y2="11"/>
								<rect x="92.5" y="11" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.5" height="4.3"/>
							</g>
							<g>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M55,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
									L55,8.5z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M52,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9L50,8.4c-0.5-0.4-1.2-0.2-1.4,0.3L47.9,11
									c-0.2,0.5,0.3,1,1,1L52,12.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M54.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5s-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
									c0.4,0.3,1.1,0,1.3-0.6L54.5,16.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M59.1,14.9c-0.2,0.2-0.4,0.5-0.7,0.7s-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
									c0.4-0.3,0.3-1-0.2-1.4L59.1,14.9z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M59.4,10.2c0.2,0.3,0.3,0.6,0.4,0.9s0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
									c-0.2-0.5-0.9-0.6-1.4-0.3L59.4,10.2z"/>
								<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="56" cy="12.4" r="1.7"/>
							</g>
						</svg>
					</Button>

					<Button type='button' variant='' size='sm' className={`floor-icon ${agendaIcon === 2 ? 'btn-white' : ''}`} onClick={() => setAgendaIcon(2)}>
						<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-81.7,14.6h-0.7c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6
									c0,1-0.8,1.8-1.8,1.8H-77"/>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-70.3,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8h-8.6c-1,0-1.8-0.8-1.8-1.8v-6.6
									c0-1,0.8-1.8,1.8-1.8h3.1"/>
							</g>
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M11.1,15.6L9.9,20H6.2c-1.4,0-2.5-1.2-2.5-2.6V6.6C3.7,5.2,4.8,4,6.2,4h11.6c1.4,0,2.5,1.2,2.5,2.6v10.7
									c0,1.4-1.1,2.6-2.5,2.6H15V4"/>
								<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="15" y1="10.9" x2="3.7" y2="10.9"/>
								<rect x="3.7" y="10.9" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.9" height="4.7"/>
							</g>
							<g>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-33,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
									L-33,8.5z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-36,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9l-2.6-1.8c-0.5-0.4-1.2-0.2-1.4,0.3l-0.7,2.2
									c-0.2,0.5,0.3,1,1,1L-36,12.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-33.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5s-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
									c0.4,0.3,1.1,0,1.3-0.6L-33.5,16.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-28.9,14.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
									c0.4-0.3,0.3-1-0.2-1.4L-28.9,14.9z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-28.6,10.2c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
									c-0.2-0.5-0.9-0.6-1.4-0.3L-28.6,10.2z"/>
								<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="-32" cy="12.4" r="1.7"/>
							</g>
						</svg>
					</Button>
					
					<Button type='button' variant='' size='sm' className={`floor-icon ${agendaIcon === 3 ? 'btn-white' : ''}`} onClick={() => setAgendaIcon(3)}>
						<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-37.7,14.6h-0.7c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6
									c0,1-0.8,1.8-1.8,1.8H-33"/>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-26.3,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8h-8.6c-1,0-1.8-0.8-1.8-1.8v-6.6
									c0-1,0.8-1.8,1.8-1.8h3.1"/>
							</g>
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M55.1,15.6L53.9,20h-3.7c-1.4,0-2.5-1.2-2.5-2.6V6.6c0-1.4,1.1-2.6,2.5-2.6h11.6c1.4,0,2.5,1.2,2.5,2.6v10.7
									c0,1.4-1.1,2.6-2.5,2.6H59V4"/>
								<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="59" y1="10.9" x2="47.7" y2="10.9"/>
								<rect x="47.7" y="10.9" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.9" height="4.7"/>
							</g>
							<g>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M11,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
									L11,8.5z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M8,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9L6.1,8.5C5.5,8.1,4.8,8.3,4.7,8.8L3.9,11
									c-0.2,0.5,0.3,1,1,1L8,12.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M10.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
									c0.4,0.3,1.1,0,1.3-0.6L10.5,16.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M15.1,14.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
									c0.4-0.3,0.3-1-0.2-1.4L15.1,14.9z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M15.4,10.2c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
									c-0.2-0.5-0.9-0.6-1.4-0.3L15.4,10.2z"/>
								<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="12" cy="12.4" r="1.7"/>
							</g>
						</svg>
					</Button>
				</div>


				<Form.Label>Enter URL</Form.Label>
				{agendaImages.map((url, index) => {
					return (
						<div className="inBox vip" key={index}>
							<Form.Control type="text" placeholder="" value={url} autoFocus={true} onChange={e => {
								setAgendaImages([...agendaImages.map((g, i) => {
									if (i === index) {
										return e.target.value
									}
									return g;
								})])
							}} />
							<div className="btnGroup">
								<Button className="" onClick={() => handleDecrementAgenda(index)} disabled={index >= (agendaImages.length - 1)}>
									<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M0.757324 1H9.24261" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</Button>

								<Button className="" onClick={handleIncrementAgenda} disabled={index < (agendaImages.length - 1)}>
									<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.99997 0.757359V9.24264M0.757324 5H9.24261" stroke="#8C838C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</Button>
							</div>
						</div>
					)
				})}
				<p className={`note`}>Supported file format: .png .jpg</p>
				
				
				<hr className='mt-5 mb-5' />


				<Form.Label>Select Icon</Form.Label>
				<div className='d-flex mb-3' style={{ gap: '1rem' }}>
					<Button type='button' variant='' size='sm' className={`floor-icon ${floorPlanIcon === 1 ? 'btn-white' : ''}`} onClick={() => setFloorPlanIcon(1)}>
						<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M6.3,14.6H5.6c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8
									H11"/>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M17.7,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8H9.9c-1,0-1.8-0.8-1.8-1.8v-6.6
									c0-1,0.8-1.8,1.8-1.8H13"/>
							</g>
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M99.2,15.2l-1.1,4h-3.3c-1.2,0-2.2-1-2.2-2.3V7.2c0-1.3,1-2.3,2.2-2.3h10.5c1.2,0,2.2,1,2.2,2.3v9.7
									c0,1.3-1,2.3-2.2,2.3h-2.6V4.8"/>
								<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="102.7" y1="11" x2="92.5" y2="11"/>
								<rect x="92.5" y="11" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.5" height="4.3"/>
							</g>
							<g>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M55,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
									L55,8.5z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M52,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9L50,8.4c-0.5-0.4-1.2-0.2-1.4,0.3L47.9,11
									c-0.2,0.5,0.3,1,1,1L52,12.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M54.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5s-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
									c0.4,0.3,1.1,0,1.3-0.6L54.5,16.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M59.1,14.9c-0.2,0.2-0.4,0.5-0.7,0.7s-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
									c0.4-0.3,0.3-1-0.2-1.4L59.1,14.9z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M59.4,10.2c0.2,0.3,0.3,0.6,0.4,0.9s0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
									c-0.2-0.5-0.9-0.6-1.4-0.3L59.4,10.2z"/>
								<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="56" cy="12.4" r="1.7"/>
							</g>
						</svg>
					</Button>

					<Button type='button' variant='' size='sm' className={`floor-icon ${floorPlanIcon === 2 ? 'btn-white' : ''}`} onClick={() => setFloorPlanIcon(2)}>
						<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-81.7,14.6h-0.7c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6
									c0,1-0.8,1.8-1.8,1.8H-77"/>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-70.3,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8h-8.6c-1,0-1.8-0.8-1.8-1.8v-6.6
									c0-1,0.8-1.8,1.8-1.8h3.1"/>
							</g>
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M11.1,15.6L9.9,20H6.2c-1.4,0-2.5-1.2-2.5-2.6V6.6C3.7,5.2,4.8,4,6.2,4h11.6c1.4,0,2.5,1.2,2.5,2.6v10.7
									c0,1.4-1.1,2.6-2.5,2.6H15V4"/>
								<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="15" y1="10.9" x2="3.7" y2="10.9"/>
								<rect x="3.7" y="10.9" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.9" height="4.7"/>
							</g>
							<g>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-33,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
									L-33,8.5z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-36,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9l-2.6-1.8c-0.5-0.4-1.2-0.2-1.4,0.3l-0.7,2.2
									c-0.2,0.5,0.3,1,1,1L-36,12.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-33.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5s-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
									c0.4,0.3,1.1,0,1.3-0.6L-33.5,16.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-28.9,14.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
									c0.4-0.3,0.3-1-0.2-1.4L-28.9,14.9z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M-28.6,10.2c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
									c-0.2-0.5-0.9-0.6-1.4-0.3L-28.6,10.2z"/>
								<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="-32" cy="12.4" r="1.7"/>
							</g>
						</svg>
					</Button>
					
					<Button type='button' variant='' size='sm' className={`floor-icon ${floorPlanIcon === 3 ? 'btn-white' : ''}`} onClick={() => setFloorPlanIcon(3)}>
						<svg version="1.1" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 24 24">
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-37.7,14.6h-0.7c-1,0-1.8-0.8-1.8-1.8V6.1c0-1,0.8-1.8,1.8-1.8h8.6c1,0,1.8,0.8,1.8,1.8v6.6
									c0,1-0.8,1.8-1.8,1.8H-33"/>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M-26.3,9.4h0.7c1,0,1.8,0.8,1.8,1.8v6.6c0,1-0.8,1.8-1.8,1.8h-8.6c-1,0-1.8-0.8-1.8-1.8v-6.6
									c0-1,0.8-1.8,1.8-1.8h3.1"/>
							</g>
							<g>
								<path className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} d="M55.1,15.6L53.9,20h-3.7c-1.4,0-2.5-1.2-2.5-2.6V6.6c0-1.4,1.1-2.6,2.5-2.6h11.6c1.4,0,2.5,1.2,2.5,2.6v10.7
									c0,1.4-1.1,2.6-2.5,2.6H59V4"/>
								<line className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} x1="59" y1="10.9" x2="47.7" y2="10.9"/>
								<rect x="47.7" y="10.9" className="st0" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeLinecap='round' strokeMiterlimit={10} width="3.9" height="4.7"/>
							</g>
							<g>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M11,8.5c0.3-0.1,0.6-0.1,1-0.1c0.3,0,0.7,0,1,0.1l0.9-3c0.2-0.6-0.2-1.2-0.7-1.2h-2.4c-0.5,0-0.9,0.6-0.7,1.2
									L11,8.5z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M8,12.1c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.4-0.9L6.1,8.5C5.5,8.1,4.8,8.3,4.7,8.8L3.9,11
									c-0.2,0.5,0.3,1,1,1L8,12.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M10.5,16.1c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.4-0.7-0.7l-2.5,1.9c-0.5,0.4-0.6,1.1-0.2,1.4l1.9,1.4
									c0.4,0.3,1.1,0,1.3-0.6L10.5,16.1z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M15.1,14.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5l1,2.9c0.2,0.6,0.9,0.9,1.3,0.6l1.9-1.4
									c0.4-0.3,0.3-1-0.2-1.4L15.1,14.9z"/>
								<path className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} d="M15.4,10.2c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l3.1-0.1c0.6,0,1.1-0.5,1-1l-0.7-2.2
									c-0.2-0.5-0.9-0.6-1.4-0.3L15.4,10.2z"/>
								<circle className="st1" fill='none' stroke='#5F6368' strokeWidth={1.5} strokeMiterlimit={10} cx="12" cy="12.4" r="1.7"/>
							</g>
						</svg>
					</Button>
				</div>

				<Form.Label>Enter URL</Form.Label>
				{floorPlanImages.map((url, index) => {
					return (
						<div className="inBox vip" key={index}>
							<Form.Control type="text" placeholder="" value={url} autoFocus={true} onChange={e => {
								setFloorPlanImages([...floorPlanImages.map((g, i) => {
									if (i === index) {
										return e.target.value
									}
									return g;
								})])
							}} />
							<div className="btnGroup">
								<Button className="" onClick={() => handleDecrementFloorPlan(index)} disabled={index >= (floorPlanImages.length - 1)}>
									<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M0.757324 1H9.24261" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</Button>

								<Button className="" onClick={handleIncrementFloorPlan} disabled={index < (floorPlanImages.length - 1)}>
									<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M4.99997 0.757359V9.24264M0.757324 5H9.24261" stroke="#8C838C" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</Button>
							</div>
						</div>
					)
				})}
				<p className={`note`}>Supported file format: .png .jpg</p>



				<div className='mt-5 d-flex' style={{ gap: '15px' }}>
					<Button variant="outline-dark" onClick={onCancel} style={{ width: 'auto', flexGrow: 0.2, padding: '0.8rem' }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={submit} style={{ width: 'auto', flexGrow: 0.8, padding: '0.8rem' }}>
						<div className='d-flex align-items-center justify-content-between'>
							<span>Update</span>
							<span className="arrow">
								<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
						</div>
					</Button>
				</div>
			</Modal.Body>
		</Modal>
		</>
	)
}

export default FloorPlan;